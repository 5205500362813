import { Button, Column, DataGrid, HeaderFilter } from 'devextreme-react/data-grid';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNovaOs, useNovaOs } from '../../../../redux/slices/consignadoComodato/novaOsSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import DataSource from 'devextreme/data/data_source';
import { alert } from 'devextreme/ui/dialog';
import { ApiRemoverItemService } from './../usercases/apiRemoverItem.service';
import { ModalNumeroDeSerie } from './modals/ModalNumeroDeSerie';

export const ProdutoLista = ({ pedido, className, setSearching, setMessageLoadin, comodatoId, dtEntrega, dtRecolha }) => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const { values } = useSelector(selectNovaOs);
  const { removeItem, changeValue } = useNovaOs();
  const [selectedItem, setSelectedItem] = useState(null);
  const modal = React.useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const onEditSerie = useCallback(async data => {
    setSelectedItem(data);
    modal.current.open();
  }, []);
  const isSerieEditIconVisible = useCallback(
    data => {
      return [3].includes(((pedido || {}).tipoPedido || {}).id) && (data.produto.series || []).length;
    },
    [pedido],
  );
  const onRemove = useCallback(
    async data => {
      if (values.id !== -1) {
        try {
          setSearching(true);
          setMessageLoadin('Removendo item...');
          const body = { itemId: data.id, pedidoId: pedido.pedidoId, id: values.osId };
          const os = await new ApiRemoverItemService().execute(body);
          setSearching(false);
          dispatch(changeValue({ fieldName: 'pedidos', value: os.pedidos }));
          return;
        } catch (error) {
          setSearching(false);
          alert(error.message, 'Erro');
          return;
        }
      }
      dispatch(removeItem({ pedidoId: pedido.pedidoId, numeroItem: data.numeroItem }));
    },
    [dispatch, removeItem, values.id, values.osId, pedido.pedidoId, setSearching, setMessageLoadin, changeValue],
  );

  const dataSource = new DataSource({
    load: () => {
      return pedido.itens;
    },
  });
  const parseIdToName = id => {
    switch (id) {
      case 1:
        return 'VENDA';
      case 2:
        return 'CONSIGNADO';
      case 3:
        return 'COMODATO';
      case 4:
        return "RETORNO DE CONSIGNADO";
      case 6:
        return 'VENDA EM CONSIGNAÇÃO';
      case 7:
        return 'VENDA EM BONIFICAÇÃO';
      case 8:
        return 'LOCAÇÃO';
      default:
        return '';
    }
  };
  return (
    <div className={`${className} list-group shadow`}>
      <h2 className='ml-3 mt-1'>{parseIdToName(pedido.tipoPedido.id)}</h2>
      {windowDimensions.width < 993 ? (
        <ul style={{ backgroundColor: 'white', width: '100%' }}>
          {(_.sortBy(pedido.itens, x => x.numeroItem) || []).map(item => (
            <li className="list-group-item" key={item.codigo} style={{ listStyleType: 'none' }}>
              <div class="media align-items-lg-center flex-column flex-lg-row p-3">
                <div class="media-body order-2 order-lg-1">
                  <h5 class="mt-0 font-weight-bold mb-2">
                    {item.numeroItem}
                    {' ) '}
                    {item.produto.descricao}
                  </h5>
                  <p class="font-italic text-muted mb-0 small">
                    {item.produto.codigo} | {item.quantidade.toFixed(item.produto.estoque.unidadeMedidaVenda.casasDecimais || 0)}{' '}
                    {item.produto.estoque.unidadeMedidaVenda.sigla} X {(item.valorProduto || item.valorAtivo || 0).toFixed(2)}
                  </p>

                  <div class="d-flex align-items-center justify-content-between mt-1">
                    <h6 class="font-weight-bold my-2">
                      {[3, 8].includes(pedido.tipoPedido.id) && <> R$: {item.valorAtivo.toFixed(2)}</>}
                      {![3, 8].includes(pedido.tipoPedido.id) && <>R$ {item.totalProdutos.toFixed(2)}</>}
                    </h6>
                  </div>
                  <div class="d-flex align-items-right justify-content-between mt-1">
                    <button className="btn btn-default btn-small" disabled={(values.status || {}).id !== 1} onClick={() => onRemove(item)}>
                      <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                    </button>
                    {isSerieEditIconVisible(item) && (
                      <button
                        className="btn btn-default btn-small"
                        hint="Número de Série"
                        disabled={(values.status || {}).id !== 1}
                        onClick={() => onEditSerie(item)}
                        icon="edit"
                      >
                        <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
          <DataGrid dataSource={ dataSource
          /* para tentar filtrar somente os itens que tiveram devolução, ainda não está funcional, cai sempre no dataSource, apenas a ideia
           * pedido.tipoPedido.Id != 4 ? dataSource : pedido.itens.filter(i => i.valorTotalRetornado == 0 ? false : true)
           */
          } keyExpr="codigo" key={pedido.id} id={`dataGrid${pedido.id}`} allowColumnReordering={true}>
          <Column dataField="numeroItem" caption="Item" width={50} sortOrder='asc'></Column>
          <Column dataField="produto.codigo" caption="Código">
            <HeaderFilter groupInterval={10000} />
          </Column>
          <Column dataField="produto.descricao" caption="Descrição do Produto" minWidth={250}></Column>
          <Column dataField="produto.estoque.saldoEstoque" caption="Estoque" minWidth={50}></Column>
          <Column dataField="produto.estoque.pendenteFaturamento" caption="Pendente" minWidth={50}></Column>
          <Column dataField="valorAtivo" format="R$ #,##0.00" caption="Valor do Ativo" visible={[3, 8].includes(pedido.tipoPedido.id)}></Column>
          <Column dataField="valorProduto" format="R$ #,##0.00" caption="Valor do Produto" visible={![3, 8].includes(pedido.tipoPedido.id)}></Column>
          <Column dataField={pedido.tipoPedido.id === 4 ? "quantidadeRetornada" : "quantidade"}  format="#,##0.000" caption="Quantidade" width={100}></Column>
          <Column
              dataField={pedido.tipoPedido.id === 4 ? "valorTotalRetornado" : "totalProdutos"}
            format="R$ #,##0.00"
            minWidth={50}
            caption="Total dos Produtos"
            visible={![3, 8].includes(pedido.tipoPedido.id)}
          ></Column>
          <Column dataField="totalAtivos" format="R$ #,##0.00" minWidth={50} caption="Total de ativos" visible={[3, 8].includes(pedido.tipoPedido.id)}></Column>
          <Column type="buttons" width={60} caption={`Ações`} visible={pedido.tipoPedido.id !== 6 && pedido.tipoPedido.id !== 4}>
              <Button hint="Remover item" disabled={(values.status || {}).id !== 1} visible={pedido.tipoPedido.id !== 6 && pedido.tipoPedido.id !== 4} onClick={e => onRemove(e.row.data)} icon="remove" />
            <Button visible={(e) => isSerieEditIconVisible(e.row.data)} hint="Numero de Série" disabled={(values.status || {}).id !== 1} onClick={e => onEditSerie(e.row.data)} icon="edit" />
          </Column>
        </DataGrid>
      )}
      {[3, 8].includes(((pedido || {}).tipoPedido || {}).id || 0) && (
        <ModalNumeroDeSerie
          ref={modal}
          item={selectedItem}
          dtEntrega={dtEntrega}
          dtRecolha={dtRecolha}
          comodatoId={comodatoId}
          tipoPedidoId={pedido.tipoPedido.id}
        />
      )}
    </div>
  );
};
