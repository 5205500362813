import 'devextreme/dist/css/dx.light.css';
import 'jspdf-autotable';

import { loadMessages, locale } from 'devextreme/localization';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectNovaOs, useNovaOs } from '../../../../redux/slices/consignadoComodato/novaOsSlice';

import { NumberBox } from 'devextreme-react';
import ptMessages from 'devextreme/localization/messages/pt.json';
import { alert } from 'devextreme/ui/dialog';
import { selectConsignadoComodatoSharedData } from '../../../../redux/slices/consignadoComodato/SharedData.slice';
import { useNovaOsItem } from '../../../../redux/slices/consignadoComodato/novaOsItemSlice';
import { BuscarProduto } from '../components/busca-produto/buscarProduto';
import { DropBoxTipoPedido } from '../components/drop-box-tipo-pedido/drop-box-tipo-pedido';
import { ApiAdicionarItemService } from '../usercases/apiAdicionarItem.service';
import { ProdutoLista } from './produto-lista';
import styles from './produtos.module.scss';
import { ProdutoService } from '../../../../services/produtos/Produto.service';

export const Produtos = memo(({ setMessageLoadin, setSearching }) => {
  loadMessages(ptMessages);
  locale(navigator.language);
  const { adicionarItem, changeValue } = useNovaOs();
  const actions = useNovaOsItem();
  const { values: valuesOs } = useSelector(selectNovaOs);
  const acessos_17810_50 = useSelector(selectConsignadoComodatoSharedData).acessos_17810_50;
  const { values, errors } = useSelector(state => state.novaOsItem);
  const dispatch = useDispatch();
  const refQuantidade = useRef();
  const refPrice = useRef();
  const refProduto = useRef();
  useEffect(() => {
    dispatch(actions.initialize());
  }, [dispatch, actions]);

  const handleAddItem = useCallback(async () => {
    if ((valuesOs.status || {}).id !== 1) {
      alert('Esse pedido está faturado, não é possível adicionar itens.', 'Ops!');
      return;
    }
    const item = { ...values };
    let itemsToAdd = [];
    let itemVasilhame = null;
    //Adiciona itens da composição do produto
    //se for um pedido de Comodato(3) ou de Locação(8),verifica se o que esta adicionando pode ser um kit Ativo Imobilizado
    //se for, então adiciona cada produto da composição do kit, e não o kit em si
    //9 = Ativo Imobilizado
    //20 = Kit ativo Imobilizado
    //mesmo comportamento que tem no projeto Services ConcomodatoService.cs > AdicionarItemPedido
    if ([3,8].includes(item.tipoPedido.id) && item.produto && item.produto.composicao && (item.produto.tipo.id === 9 && item.produto.subTipo.id === 20)){
      item.produto.composicao.forEach(composicaoItem => {
        const itemComposicao = {
          ...item,
          produto: composicaoItem.produto,
          quantidade: (item.quantidade * composicaoItem.quantidade),
          valorProduto: 0,
          valorAtivo: Math.round((composicaoItem.valorFinal / composicaoItem.quantidade)* 100) / 100,
          precoTotal: Math.round(((item.quantidade * composicaoItem.quantidade) * (composicaoItem.valorFinal / composicaoItem.quantidade))* 100) / 100,
        };
        itemsToAdd.push(itemComposicao);
      });
    }
    else 
    {
      itemsToAdd.push(item);
    }
    
    // Se o produto tiver vasilhame, busca o produto relacionado
    if ((item.produto || {}).prEsProdCodVasilhame) {
      const resultVasilhame = await ProdutoService.getInstance().buscarProdutoId(item.produto.prEsProdCodVasilhame, 9);
      const prodVasilhame = (resultVasilhame || {}).length > 0 ? resultVasilhame[0] : null;

      // Se encontrou o produto, adiciona o item
      if (prodVasilhame) {
          itemVasilhame = {...item,
            produto: prodVasilhame,
            tipoPedido: {id: 3, nome: 'Comodato'},
            valorAtivo: (prodVasilhame.precoVenda || 0),
            valorProduto: 0,
            quantidade: item.quantidade,
            precoTotal: item.quantidade * (prodVasilhame.precoVenda || 0)
          };
          itemsToAdd.push(itemVasilhame);
      }
    }

    if (valuesOs.id === -1 || !valuesOs.id) {
      dispatch(actions.initialize());
      // dispatch(adicionarItem({ item }));

      // Se o produto tiver vasilhame, adiciona o produto relacionado
      // if (itemVasilhame)
      // {
      //   dispatch(adicionarItem({ item: itemVasilhame }));
      // }

      //la em cima o vasilhame ja foi adicionado no array itemsToAdd, seguindo sua logica
      itemsToAdd.forEach(itemToAdd => {
        dispatch(adicionarItem({ item: itemToAdd }));
      });

    } else {
      try {
        setSearching(true);
        setMessageLoadin('Adicionando item...');
        let data = await new ApiAdicionarItemService().execute({
          osId: valuesOs.osId,
          tipoPedidoId: item.tipoPedido.id,
          produtoId: item.produto.id,
          quantidade: item.quantidade,
          preco: item.valorAtivo || item.valorProduto
        });

        // Se o produto tiver vasilhame, adiciona o produto relacionado
        if (itemVasilhame) {
          data = await new ApiAdicionarItemService().execute({
            osId: valuesOs.osId,
            tipoPedidoId: itemVasilhame.tipoPedido.id,
            produtoId: itemVasilhame.produto.id,
            quantidade: itemVasilhame.quantidade,
            preco: itemVasilhame.valorAtivo
          });
        }

        dispatch(changeValue({ fieldName: 'pedidos', value: data.pedidos }));
        dispatch(changeValue({ fieldName: 'totalGeral', value: data.totalGeral }));
        dispatch(changeValue({ fieldName: 'totalAtivos', value: data.totalAtivos }));
        dispatch(changeValue({ fieldName: 'totalProdutos', value: data.totalProdutos }));
        dispatch(actions.initialize());
        setSearching(false);
      } catch (e) {
        setSearching(false);
        alert(e.message, 'ERRO!').then(() => {
          refProduto.current.focus();
        });
      }
    }
    refProduto.current.focus();
  }, [dispatch, actions, values, adicionarItem, valuesOs, changeValue, setSearching, setMessageLoadin]);

  const formatQuantidade = produto => {
    if (!produto || !produto.estoque || !produto.estoque.unidadeMedidaVenda) {
      return `#,##0 UN`;
    }
    if (produto.estoque.unidadeMedidaVenda.casasDecimais) {
      return `#,##0.${'0'.repeat(produto.estoque.unidadeMedidaVenda.casasDecimais)} ${(produto.estoque.unidadeMedidaVenda.sigla || 'UN').trim().toUpperCase()}`;
    }
    return `#,##0 ${(produto.estoque.unidadeMedidaVenda.sigla || 'UN').trim().toUpperCase()}`;
  };

  return (
    <Row className={`${styles.mainRow}`}>
      <Col lg={2} className="form-group m-0 p-0">
        <DropBoxTipoPedido
          onNextFocus={() => {
            setTimeout(() => {
              refProduto.current.focus();
            }, 100);
          }}
        />
      </Col>
      <Col lg={5} className="form-group m-0 p-0">
        <BuscarProduto
          ref={refProduto}
          tipoPedidoId={((values || {}).tipoPedido || {}).id}
          onNextFocus={() => {
            refQuantidade.current.instance.focus();
            let inputEl = refQuantidade.current._element.querySelector('.dx-texteditor-input');
            inputEl.selectionStart = 0;
            inputEl.selectionEnd = 1;
          }}
          title="Produto"
        />
      </Col>
      <Col lg={1} className="form-group m-0 p-0">
        <Form.Label className="text-primary" htmlFor="valorAtivo">
          Valor<span className="text-danger">*</span>
        </Form.Label>
        <NumberBox
          name="valorAtivo"
          id="valorAtivo"
          ref={refPrice}
          readOnly={!acessos_17810_50 || (values.produto && values.produto.composicao && values.produto.composicao.length > 0)}
          className={`${styles.currency} currency`}
          onKeyDown={e => {
            if (
              e.event.originalEvent.code !== 'Enter' &&
              e.event.originalEvent.code !== 'NumpadEnter' &&
              e.event.originalEvent.key !== 'NumpadEnter' &&
              e.event.originalEvent.key !== 'Enter'
            ) {
              return;
            }
            refQuantidade.current.instance.focus();
          }}
          format="R$ #,##0.00"
          defaultValue={0.0}
          height={40}
          valueChangeEvent="keyup"
          onValueChange={e => {
            dispatch(actions.changeValue({ fieldName: 'valorAtivo', value: e }));
            dispatch(actions.changeBlur({ fieldName: 'valorAtivo' }));
          }}
          value={(values || {}).valorAtivo || 0}
        ></NumberBox>
      </Col>
      <Col lg={1} className="form-group m-0 p-0">
        <Form.Label className="text-primary" htmlFor="itemQuantidade">
          Quantidade<span className="text-danger">*</span>
        </Form.Label>
        <NumberBox
          ref={refQuantidade}
          className={`${styles.currency} currency`}
          name="itemQuantidade"
          id="itemQuantidade"
          onKeyDown={e => {
            if (
              e.event.originalEvent.code !== 'Enter' &&
              e.event.originalEvent.code !== 'NumpadEnter' &&
              e.event.originalEvent.key !== 'NumpadEnter' &&
              e.event.originalEvent.key !== 'Enter'
            ) {
              return;
            }
            if (errors.isValid) {
              handleAddItem();
            } else {
              refPrice.current.instance.focus();
            }
          }}
          format={formatQuantidade((values || {}).produto)}
          height={40}
          valueChangeEvent="keyup"
          onValueChange={e => {
            dispatch(actions.changeValue({ fieldName: 'quantidade', value: e }));
            dispatch(
              actions.changeValue({
                fieldName: 'precoTotal',
                value: Math.round(e * values.valorAtivo * 100) / 100,
              }),
            );
          }}
          onFocusOut={() => dispatch(actions.changeBlur({ fieldName: 'quantidade' }))}
          defaultValue={0}
          value={(values || {}).quantidade || 0}
        ></NumberBox>
      </Col>
      <Col lg={2} className="m-0 p-0">
        <Form.Label className="text-primary" htmlFor="itemPrecoTotal">
          Preço Total<span className="text-danger">*</span>
        </Form.Label>
        <NumberBox
          id="itemPrecoTotal"
          name="itemPrecoTotal"
          readOnly={true}
          format={`R$ #,##0.00`}
          height={40}
          defaultValue={0}
          value={(values || {}).precoTotal || 0}
        ></NumberBox>
      </Col>
      <Col lg={1} className="mt-2 ml-0 mr-0 p-0">
        <Button disabled={!errors.isValid} variant="primary" title="Adicionar" className={`mt-4 float-right ${styles.buttonAdd}`} onClick={handleAddItem}>
          <i className="dx-icon dx-icon-add"></i>
        </Button>
      </Col>
      {valuesOs && valuesOs.pedidos && (
        <div className="pl-0 ml-0 mt-3 w-100">
          {valuesOs.pedidos.map(
            (item, idx) =>
              ([1,2,3,4,6,7,8].includes((item.tipoPedido || {}).id)) && (
                <ProdutoLista
                  key={idx}
                  comodatoId={(valuesOs || {}).id}
                  dtEntrega={(valuesOs || {}).dataEntregaRetirada}
                  dtRecolha={(valuesOs || {}).dataRecolhaDevolucao}
                  pedido={item}
                  className="ml-0 pl-0 mr-2 alert-light"
                  setMessageLoadin={setMessageLoadin}
                  setSearching={setSearching}
                />
              ),
          )}
        </div>
      )}
    </Row>
  );
});


