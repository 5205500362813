import React, { useCallback } from 'react';

import { alert } from 'devextreme/ui/dialog';
import { useHistory } from 'react-router';
import { SessionManager } from '../../../../shared/utils/sessionManager';

import styles from './../SolicitacaoReserva.module.scss';
import { Button, Dropdown } from 'react-bootstrap';
import { selectConsignadoComodatoSharedData, useConsignadoComodatoSharedData } from '../../../../redux/slices/consignadoComodato/SharedData.slice';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

export function HeaderList({ setSearching, setSearchingMessage, headerFilter, createNewRecord, recordOpened,dataMaxima, podeIniciar  }) {
  const history = useHistory();
  const { turnoAtual, parametro542 } = useSelector(selectConsignadoComodatoSharedData);
  const { updateState } = useConsignadoComodatoSharedData();
  const dispatch = useDispatch();

  const onNovaReserva = useCallback(
    e => {
      e.preventDefault();
      if (typeof createNewRecord === 'function')
      {
        createNewRecord();
      }
    },
    [ dispatch],
  );

  return (
    <div className={`d-flex row ${styles.header}`}>
      <div className="col col-12">
        <h2 className="float-left ml-3 mt-3">SOLICITAÇÃO DE RESERVA DE MERCADORIA</h2>
   
        {!recordOpened && podeIniciar && <button style={{ width: '200px' }} className={`btn btn-primary float-right h-100`} onClick={onNovaReserva}>
          <span>NOVA RESERVA</span>&nbsp;&nbsp;
          <i className="dx-icon-plus"></i>
        </button>}
      </div>
    </div>
  );
}
