import { UrlLinxCoreService } from '../core/urlLinxCore.service';
import { getDadosLogin } from '../../shared/utils/Utils';
import { AxiosErrorService } from '../core/axiosError.service';

export class ApiVincularVendedorComissionamento {
  static instance = null;
  static getInstance() {
    if (!ApiVincularVendedorComissionamento.instance) {
      ApiVincularVendedorComissionamento.instance = new ApiVincularVendedorComissionamento();
    }
    return ApiVincularVendedorComissionamento.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  execute = async (clienteId, vendedorId) => {
    try {
      const url = `/cliente/vincular-vendedor-comissionamento`;
      
      await this.urlLinxCore.post(url, { clienteId, vendedorId });
    } catch (er) {
      console.log('er', er);
      await new AxiosErrorService().execute(er);
    }
  };
}
