import { alert } from 'devextreme/ui/dialog';
import React, { useEffect, useState, useCallback } from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { formatStringToCurrency } from '../../../../../shared/utils/Utils';
import { ApiRecuperarOsService } from '../../usercases/ApiRecuperarOs.service';
import { FaturamentoLoteModal } from '../../../consignado-comodato-nova-os/faturamento/faturamentoLoteModal';
import { FaturarPedidoService } from '../../../consignado-comodato-nova-os/faturamento/usercases/faturarPedido.service';
import styles from '../Component.module.scss';

// Função para formatar a data
function formatDate(date) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString('pt-BR', options);
}

// Função auxiliar para calcular o valor líquido do pedido
const calcularValorLiquidoPedido = (pedido, comodato) => {
  if (pedido.tipoPedido.id === 1) { // Venda
    return Math.round((pedido.totalProdutos + comodato.valorFrete + pedido.acrescimo - pedido.desconto) * 100) / 100;
  } else if (pedido.tipoPedido.id === 6) { // Venda Consignada
    const freteVendaConsignada = comodato.valorFrete;
    return Math.round((pedido.totalProdutos - pedido.desconto + pedido.acrescimo + freteVendaConsignada) * 100) / 100;
  } else if (pedido.tipoPedido.id === 8) { // Locação
    return Math.round((pedido.totalAtivos || 0) + (pedido.totalProdutos || 0) - pedido.desconto + pedido.acrescimo + comodato.valorFrete) * 100 / 100;
  }
  return 0;
};

const badgeStyle = { position: 'absolute', fontSize: '8px', color: 'white', top: '-20px', left: '20px' };

// Função auxiliar para calcular o total de pagamentos
const calcularTotalPagamentos = pedido => {
  return Math.round(pedido.pagamentos.reduce((acumulador, pagamento) => acumulador + pagamento.valorPagamento, 0) * 100) / 100;
};

export function OsDetailGrid({ data }) {
  const [comodato, setComodato] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalStatus, setModalStatus] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [exibirFaturarLote, setExibirFaturarLote] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    new ApiRecuperarOsService().execute(data.data.id)
      .then((os) => {
        if (!os) {
          alert('Não foi possível recuperar o Pedido.', 'Atenção!');
          setIsLoading(false);
          return;
        }
        setComodato(os);
        verificarFaturamentoLote(os.pedidos);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        alert(err.message, 'Erro');
      });
  }, [data.data]);

  // Função para verificar se deve exibir o botão de "Faturar em Lote"
  const verificarFaturamentoLote = (pedidos) => {
    const existePedidoParaFaturar = pedidos.some(pedido => {
      return pedido.situacao === 'PENDENTE' && !pedido.faturado;
    });
    setExibirFaturarLote(existePedidoParaFaturar);
  };

  const handleFaturarLote = useCallback(async () => {
    const pedidosParaFaturar = comodato.pedidos.filter(pedido => pedido.situacao === 'PENDENTE');
    const pedidosJaFaturados = comodato.pedidos.filter(pedido => pedido.faturado);

    if (pedidosParaFaturar.length === 0) {
      alert('Nenhum pedido válido para faturamento.', 'Atenção');
      return;
    }

    setShowModal(true);
    setIsProcessing(true);
    setModalStatus([]);

    // Adiciona pedidos já faturados no modal com a mensagem
    pedidosJaFaturados.forEach(pedido => {
      setModalStatus(prevStatus => [
        ...prevStatus,
        {
          pedidoId: pedido.pedidoId,
          status: `Já faturado`,
          tipoPedido: pedido.tipoPedido || { nome: 'N/A' },
          nfe: pedido.nfe || { numeroNfe: '---' }
        }
      ]);
    });

    for (const pedido of pedidosParaFaturar) {
      if (pedido.faturado) {
        continue; // Ignora pedidos já faturados
      }

      try {
        // Validação de métodos de pagamento
        if (pedido.tipoPedido.id === 1 || pedido.tipoPedido.id === 6 || pedido.tipoPedido.id === 8) {
          if (pedido.pagamentos.length === 0) {
            setModalStatus(prevStatus => [
              ...prevStatus,
              {
                pedidoId: pedido.pedidoId,
                status: `Erro: O pedido não possui forma de pagamento cadastrada. Por favor, revise os pagamentos antes de faturá-lo.`,
                tipoPedido: pedido.tipoPedido || { nome: 'N/A' },
                nfe: { numeroNfe: '---' }
              },
            ]);
            continue;
          }

          const valorLiquidoPedido = calcularValorLiquidoPedido(pedido, comodato);
          const totalPagamentos = calcularTotalPagamentos(pedido);

          if (valorLiquidoPedido !== totalPagamentos) {
            setModalStatus(prevStatus => [
              ...prevStatus,
              {
                pedidoId: pedido.pedidoId,
                status: `Erro: O valor total dos pagamentos é diferente do valor total do pedido. Por favor, revise os pagamentos.`,
                tipoPedido: pedido.tipoPedido || { nome: 'N/A' },
                nfe: { numeroNfe: '---' }
              },
            ]);
            continue;
          }
        }

        const response = await new FaturarPedidoService().execute(pedido.pedidoId, true);
        const pedidoFaturado = response.data.pedidos.find(p => p.pedidoId === pedido.pedidoId);

        if (pedidoFaturado) {
          setModalStatus(prevStatus => [
            ...prevStatus,
            {
              pedidoId: pedido.pedidoId,
              status: 'Sucesso',
              tipoPedido: pedidoFaturado.tipoPedido || { nome: 'N/A' },
              nfe: pedidoFaturado.nfe || { numeroNfe: '---' }
            }
          ]);
        }
      } catch (error) {
        setModalStatus(prevStatus => [
          ...prevStatus,
          {
            pedidoId: pedido.pedidoId,
            status: `Erro: ${error.message}`,
            tipoPedido: pedido.tipoPedido || { nome: 'N/A' },
            nfe: { numeroNfe: '---' }
          }
        ]);
      }
    }

    setIsProcessing(false);
  }, [comodato]);

  return (
    <>
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <h4>Detalhes do Pedido</h4>
            </Col>
            <Col className="text-right">
              {exibirFaturarLote && (
                <Button variant="primary" onClick={handleFaturarLote} disabled={isProcessing}>
                  {isProcessing ? 'Processando...' : 'Faturar em Lote'}
                </Button>
              )}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            <div className="text-center">Carregando dados...</div>
          ) : (
            (comodato.pedidos || []).map((pedido) => (
              <Row key={pedido.id}>
                <Col lg={2}>
                  <h5>{pedido.tipoPedido.nome}</h5>
                  <p>Nota Fiscal: <small>{pedido.numeroNF || '---'}</small> <br />
                  Data de Emissão: <small>{(pedido.nfe || {}).dataEmissao ? new Date(pedido.nfe.dataEmissao).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }) : '---'}</small></p>
                </Col>
                <Col lg={10}>
                  <table className={`${styles.table} table`}>
                    <thead>
                      <tr>
                        <th style={{ width: '140px' }}>Código</th>
                        <th>Produto</th>
                        <th style={{ width: '120px' }}>Quantidade</th>
                        <th style={{ width: '120px' }}>Preço Unitário</th>
                        <th style={{ width: '120px' }}>Preço Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pedido.itens.map((item) => (
                        <tr key={item.produto.codigo}>
                          <td>{item.produto.codigo}</td>
                          <td>{item.produto.descricao}</td>
                          <td className="text-right">{item.quantidade}</td>
                          <td className="text-right">{formatStringToCurrency(item.valorProduto || item.valorAtivo)}</td>
                          <td className="text-right">{formatStringToCurrency(item.totalProdutos || item.totalAtivos)}</td>
                        </tr>
                      ))}
                      {pedido.tipoPedido.id === 1 && comodato.desconto ? (
                        <tr>
                          <td colSpan="4" className="text-right">- Desconto</td>
                          <td className="text-right">{formatStringToCurrency(comodato.desconto * -1)}</td>
                        </tr>
                      ) : null}
                      {pedido.tipoPedido.id === 1 && comodato.acrescimo ? (
                        <tr>
                          <td colSpan="4" className="text-right">+ Acréscimo</td>
                          <td className="text-right">{formatStringToCurrency(comodato.acrescimo)}</td>
                        </tr>
                      ) : null}
                      {pedido.tipoPedido.id === 1 && comodato.valorFrete ? (
                        <tr>
                          <td colSpan="4" className="text-right">+ Frete</td>
                          <td className="text-right">{formatStringToCurrency(comodato.valorFrete)}</td>
                        </tr>
                      ) : null}
                      {pedido.tipoPedido.id === 1 && (comodato.valorFrete || comodato.desconto || comodato.acrescimo) ? (
                        <tr>
                          <td colSpan="4" className="text-right">Total</td>
                          <td className="text-right">{formatStringToCurrency(comodato.totalGeral)}</td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                  <ul className="float-right list-group">
                    {pedido.pagamentos.map((pagamento, pIdx) => (
                      <li className="list-group-item" key={pIdx} style={{ minWidth: '550px' }}>
                        {!pagamento.boletoId && pagamento.vencimento === 'RECEBIDO' ? (
                          <p className="badge bg-success float-right" style={badgeStyle}>
                            Recebido
                          </p>
                        ) : (
                          <p className="badge bg-danger float-right" style={badgeStyle}>
                            Pendente
                          </p>
                        )}
                        <span className="float-left">
                          {((pagamento.pagamento || {}).descricao || '').toUpperCase()}{' '}
                          {pagamento.boletoId ? <small>&nbsp;&nbsp;Vencimento: {new Date(pagamento.dataVencimento).toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}</small> : ''}
                        </span>
                        <span className="float-right">{formatStringToCurrency(pagamento.valorPagamento)}</span>
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            ))
          )}
        </Card.Body>
      </Card>

      <FaturamentoLoteModal
        show={showModal}
        onClose={() => setShowModal(false)}
        modalStatus={modalStatus}
        isProcessing={isProcessing}
      />
    </>
  );
}
