import { ApiEnviarNpsService } from './ApiEnviarNps.service';
import { ApiListarFormasPagamentoService } from './ApiListarFormasPagamento.service';
import { ApiListarOrigensAtivasService } from './ApiListarOrigensAtivas.service';
import { ApiListarStatusService } from './ApiListarStatus.service';
import { ApiListarTiposPedidosService } from './ApiListarTiposPedidos.service';
import { ApiRecuperarBandeirasService } from './ApiRecuperarBandeiras.service';
import { ApiRecuperarProdutoRedeService } from './ApiRecuperarProdutoRede.service';
import { ApiRecuperarRedesService } from './ApiRecuperarRedes.service';
import { ApiRelatorioControleAtivosService } from './ApiRelatorioControleAtivos.service';
import { ApiSincronizaTurnoPedidoPagamentoService } from './ApiSincronizaTurnoPedidoPagamento.service';
import { EmitirNFeService } from './EmitirNFe.service';
export class ComodatoService {
  static instance = null;
  static getInstance() {
    if (!ComodatoService.instance) {
      ComodatoService.instance = new ComodatoService();
    }
    return ComodatoService.instance;
  }

  listarOrigens = async () => {
    return await ApiListarOrigensAtivasService.getInstance().execute();
  };
  listarTiposDePedidos = async () => {
    return await ApiListarTiposPedidosService.getInstance().execute();
  };
  listarStatus = async () => {
    return await ApiListarStatusService.getInstance().execute();
  };
  listarFormasPagamento = async () => {
    return await ApiListarFormasPagamentoService.getInstance().execute();
  };
  listarBandeiras = async () => {
    return await ApiRecuperarBandeirasService.getInstance().execute();
  };
  listarRedes = async () => {
    return await ApiRecuperarRedesService.getInstance().execute();
  };
  listarProdutoRede = async redeCod => {
    return await ApiRecuperarProdutoRedeService.getInstance().execute(redeCod, false);
  };
  relatorioControleAtivos = async (prodCod, serie, statusPedido, dtEntrada, dtSaida) => {
    return await ApiRelatorioControleAtivosService.getInstance().execute(prodCod, serie, statusPedido, dtEntrada, dtSaida);
  };
  sincronizarTurnoPedidoPagamento = async pagamentoId => {
    return await ApiSincronizaTurnoPedidoPagamentoService.getInstance().execute(pagamentoId);
  };
  emitirNFe = async (ecfCod, veDoCod, veDoCodIdeMov) => {
    return await EmitirNFeService.getInstance().execute(ecfCod, veDoCod, veDoCodIdeMov);
  };
  enviarNps = async (comodatoId) => {
    return await new ApiEnviarNpsService().execute(comodatoId);
  };
}
