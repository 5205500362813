import axios from 'axios';
import { getToken } from '../../../../authentication/Auth';
import dateTimeBox from '../../../../components/DateTimeBox/DateTimeBox';
import { BASE_URL_GATEWAY } from '../../../../shared/constants/GatewayUrlConstant';
import { maskedCnpjCpf } from '../../../../shared/utils/Utils';

export const DocumentosPagamentosDinamicoService = {
  find,
  GetColumnsByPagamento
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function find(appliedFilters) {
  /*
   * GO HORSE GERADO por conta de ter sido configurado         [JsonConverter(typeof(UtcAsIso8601))] no MovimentoBaseFilter
   * que acaba adicionando 3 hora nos filtros da tela, com isso filtros padrões que devolvem a data de hoje por exemplo, enviam hoje e amanhã
   * já que o horário fim é definido lá como 23:59
   **/
  appliedFilters.dataInicio = appliedFilters.dataInicio.split('T')[0];
  appliedFilters.dataFim = appliedFilters.dataFim.split('T')[0];

  const response = await urlLinxCore.post('/DocumentoFiscal/NotaDetalhada', appliedFilters)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

const formatDocument = data => {
  return maskedCnpjCpf(data.value);
};

function GetColumnsDefault(matriz) {
  let colunas = [
    {
      dataField: 'empresaCnpj',
      caption: 'CNPJ da empresa',
      width: '130',
      visible: true,
    },
    {
      dataField: 'empresaNomeFantasia',
      caption: 'Nome fantasia',
      width: '200',
      visible: true,
    },
    {
      dataField: 'empresaRazaoSocial',
      caption: 'Razão social',
      width: '200',
      visible: false,
    },
    {
      dataField: 'documentoDataEmissao',
      caption: 'Emissão',
      dataType: 'date',
      width: '130',
      visible: true,
    },
    {
      dataField: 'documentoHoraEmissao',
      caption: 'Horário emissão',
      visible: false,
    },
    {
      dataField: 'documentoCodigoDescricaoDiaSemana',
      caption: 'Dia semana emissão',
      visible: false,
    },

    {
      dataField: 'documentoDataMovimento',
      caption: 'Movimento',
      dataType: 'date',
      width: '130',
      visible: false,
    },
    {
      dataField: 'documentoHoraMovimento',
      caption: 'Horário movimento',
      visible: false,
    },
    {
      dataField: 'documentoCodigoDescricaoDiaSemanaMovimento',
      caption: 'Dia semana movimento',
      visible: false,
    },



    {
      dataField: 'operacaoEntradaSaida',
      caption: 'Entrada / Saída',
      width: '100',
      visible: true,
    },
    {
      dataField: 'operacaoDescricao',
      caption: 'Operação',
      visible: false,
    },
    {
      dataField: 'operacaoRetaguarda',
      caption: 'Tipo operação',
      visible: true,
    },
    {
      dataField: 'documentoTipoDescricao',
      caption: 'Tipo documento',
      width: '130',
      visible: true,
    },
    {
      dataField: 'documentoSituacao',
      caption: 'Situação',
      visible: false,
    },
    {
      dataField: 'documentoNumero',
      caption: 'Nº documento',
      width: '130',
      visible: true,
    },
    {
      dataField: 'documentoSerie',
      caption: 'Nº Serie',
      visible: false,
    },
    {
      dataField: 'documentoSubSerie',
      caption: 'Nº SubSerie',
      visible: false,
    },
    {
      dataField: 'documentoValorBruto',
      caption: 'Documento bruto R$',
      format: { type: 'currency', precision: 2 },
      visible: false,
      totalize: false,
    },
    {
      dataField: 'documentoValorFrete',
      caption: 'Documento frete R$',
      format: { type: 'currency', precision: 2 },
      visible: false,
      totalize: false,
    },
    {
      dataField: 'documentoValorOutros',
      caption: 'Documento outros R$',
      format: { type: 'currency', precision: 2 },
      visible: false,
      totalize: false,
    },
    {
      dataField: 'documentoValorDesconto',
      caption: 'Documento desconto R$',
      format: { type: 'currency', precision: 2 },
      visible: false,
      totalize: false,
    },
    {
      dataField: 'documentoValorAcrescimo',
      caption: 'Documento acréscimo R$',
      format: { type: 'currency', precision: 2 },
      visible: false,
      totalize: false,
    },
    {
      dataField: 'documentoValorLiquido',
      caption: 'Documento líquido R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: true,
      totalize: false,
    },
    {
      dataField: 'clienteFornecedorCnpjCpf',
      caption: 'Cliente/Fornecedor CNPJ/CPF',
      visible: false,
    },
    {
      dataField: 'clienteFornecedorNome',
      caption: 'Cliente/Fornecedor',
      visible: true,
    },
    {
      dataField: 'operadorNome',
      caption: 'Operador',
      visible: false,
    },
    {
      dataField: 'origemDescricao',
      caption: 'Origem',
      visible: false,
    },
    {
      dataField: 'pedidoNumero',
      caption: 'Nº pedido',
      visible: false,
      width: '130',
    }
  ];

  if (matriz)
    colunas[0].groupIndex = 1;

  return colunas;
}

function GetColumnsByPagamento(matriz) {

  let colunas = GetColumnsDefault(matriz);

  colunas.push(
    {
      dataField: 'pagamentoRecebimentoDescricao',
      caption: 'Forma de pagamento',
      width: '130',
      visible: true,
    },
    {
      dataField: 'pagamentoRecebimentoParcela',
      caption: 'Nº parcela',
      width: '130',
      visible: false,
    },
    {
      dataField: 'pagamentoCartaoRede',
      caption: 'Rede cartão',
      width: '130',
      visible: false,
    },
    {
      dataField: 'pagamentoCartaoProduto',
      caption: 'Produto cartão',
      width: '130',
      visible: false,
    },
    {
      dataField: 'pagamentoCartaoBandeira',
      caption: 'Bandeira cartão',
      width: '130',
      visible: false,
    },
    {
      dataField: 'pagamentoRecebimentoValor',
      caption: 'Pagamento / Recebimento R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: true,
      totalize: true
    },
    {
      dataField: 'pagamentoDataVencimento',
      caption: 'Data de Vencimento',
      dataType: 'date',
      width: '130',
      visible: false,
    },
  );

  return colunas;
}

export default urlLinxCore;
