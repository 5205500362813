import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { DownloadContratoComodato } from '../../../../../services/Relatorios/DownloadContratoComodato';
import { DownloadPedidoComodato } from '../../../../../services/Relatorios/DownloadPedidoComodato';
import { ImprimirEmLoteComodato } from '../../../../../services/Relatorios/ImprimirEmLoteComodato';
import { DownloadReciboComodato } from '../../../../../services/Relatorios/DownloadReciboComodato';
import { selectNovaOs } from './../../../../../redux/slices/consignadoComodato/novaOsSlice';
import styles from './../components.module.scss';

export function HeaderForm() {
  const { values } = useSelector(selectNovaOs);
  const [faturado, setFaturado] = useState(false);
  const [busyRecibo, setBusyRecibo] = useState(false);
  const [busyImpressaoLote, setBusyImpressaoLote] = useState(false);
  const [busyContrato, setBusyContrato] = useState(false);
  const [busyPedido, setBusyPedido] = useState(false);
  useEffect(
    e => {
      if (!values.pedidos) return;
      if (!values.pedidos.length) return;
      if (!values.pedidos.find(x => x.veDoCod)) return;
      setFaturado(true);
    },
    [values.pedidos],
  );
  const onClickContrato = useCallback(
    async e => {
      e.preventDefault();
      try {
        setBusyContrato(true);
        const download = new DownloadContratoComodato();
        await download.execute(values.osId, values.numeroOs);
        setBusyContrato(false);
      } catch (e) {
        setBusyContrato(false);
        Swal.fire('Erro', e.message, 'error');
      }
    },
    [values.osId, values.numeroOs],
  );
  const onClickPedido = useCallback(
    async e => {
      e.preventDefault();
      try {
        setBusyPedido(true);
        const download = new DownloadPedidoComodato();
        await download.execute(values.osId, values.numeroOs);
        setBusyPedido(false);
      } catch (e) {
        setBusyPedido(false);
        Swal.fire('Erro', e.message, 'error');
      }
    },
    [values.osId, values.numeroOs],
  );
  const onClickRecibo = useCallback(
    async e => {
      e.preventDefault();
      try {
        setBusyRecibo(true);
        const download = new DownloadReciboComodato();
        await download.execute(values.osId, values.numeroOs);
        setBusyRecibo(false);
      } catch (e) {
        setBusyRecibo(false);
        Swal.fire('Erro', e.message, 'error');
      }
    },
    [values.numeroOs, values.osId],
  );
  const onClickImpressaoLote = useCallback(
    async e => {
      e.preventDefault();
      try {
        setBusyImpressaoLote(true);
        const download = new ImprimirEmLoteComodato();
        await download.execute(values.osId, values.numeroOs);
        setBusyImpressaoLote(false);
      } catch (error) {
        setBusyImpressaoLote(false);
        Swal.fire('Erro', error.message, 'error');
      }
    },
    [values.numeroOs, values.osId],
  );
  return (
    <div className={`d-flex row ${styles.header}`}>
      <div className="col col-12">
        <h2 className="float-left ml-3 mt-3 h-100">
          Pedido Consignado/Comodato <small className="muted">{values.id !== -1 && values.id ? values.numeroOs.toString().padStart(10, '0') : 'NOVO'}</small>
        </h2>
        <button
          style={{ width: '200px' }}
          disabled={busyImpressaoLote || values.id === -1 || !values.id}
          type="button"
          className={`btn btn-link float-right h-100`}
          onClick={onClickImpressaoLote}
        >
          <i className="dx-icon-print"></i>&nbsp;&nbsp; {`${busyImpressaoLote ? 'GERANDO...' : 'Imprimir Tudo'}`}
        </button>
        <button
          style={{ width: '200px' }}
          disabled={busyPedido || values.id === -1 || !values.id}
          type="button"
          className={`btn btn-link float-right h-100`}
          onClick={onClickPedido}
        >
          <i className="dx-icon-print"></i>&nbsp;&nbsp; {`${busyPedido ? 'GERANDO...' : 'PEDIDO'}`}
        </button>

        <button
          style={{ width: '200px' }}
          disabled={busyRecibo || !faturado || !((values || {}).pedidos || []).find(x => [2, 3, 8].includes(x.tipoPedido.id))}
          type="button"
          className={`btn btn-link float-right h-100`}
          onClick={onClickRecibo}
        >
          <i className="dx-icon-print"></i>&nbsp;&nbsp; {`${busyRecibo ? 'GERANDO...' : 'RECIBO'}`}
        </button>
        <button
          disabled={busyContrato || !faturado || !((values || {}).pedidos || []).find(x => x.tipoPedido.id === 3)}
          style={{ width: '200px' }}
          className={`float-right btn btn-link h-100`}
          onClick={onClickContrato}
        >
          <i className="dx-icon-print"></i>&nbsp;&nbsp; {`${busyContrato ? 'GERANDO...' : 'CONTRATO'}`}
        </button>
      </div>
    </div>
  );
}
