import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { LoadPanel } from 'devextreme-react';
import { Alert, Card } from 'react-bootstrap';
import moment from 'moment';
import Swal from 'sweetalert2';
import { getDadosLogin } from '../../../../shared/utils/Utils';
import { ApiSalvarSolResService } from "./../usercases/ApiSalvarSolRes.service";
import { ApiBuscarDiaHoraEncerramentoSolicitacaoService } from './../usercases/ApiBuscarDiaHoraEncerramentoSolicitacao.service';
import { ApiBuscarQuantidadeMaximaBarrisService } from './../usercases/ApiBuscarQuantidadeMaximaBarris.service';
import './SolicitacaoReservaForm.css';
 


export function SolicitacaoReservaForm({
    recordOpened, formData, createNewRecord, setFormData, reloadForm, dataMaxima, podeIniciar
}) {
    // States
    const [statusPedido, setStatusPedido] = useState('');
    
    const [previsaoDeEntrega, setPrevisaoDeEntrega] = useState([]);
    const [previsaoDataInicial, setPrevisaoDataInicial] = useState('');
    const [previsaoDataFinal, setPrevisaoDataFinal] = useState('');
    const [previsaoSemana, setPrevisaoSemana] = useState(0);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [displayedItems, setDisplayedItems] = useState([]);
    const [searching, setSearching] = useState(false);
    const [searchingMessage, setSearchingMessage] = useState('Carregando solicitações de reserva...');
    const [quantidadeMaximaBarris, setQuantidadeMaximaBarris] = useState(0);

    // Auxiliary functions
    const getDeliveryDates = useCallback((dataMaxima) => {
        const daysUntilNextMonday = (1 - dataMaxima.getDay() + 7) % 7 || 7;
        const deliveryStartDate = new Date(dataMaxima);
        deliveryStartDate.setDate(dataMaxima.getDate() + daysUntilNextMonday);

        const deliveryDates = [];
        for (let i = 0; i < 6; i++) {
            const date = new Date(deliveryStartDate);
            date.setDate(deliveryStartDate.getDate() + i);
            deliveryDates.push(date);
        }

        return deliveryDates;
    }, []);

    const getWeekNumber = useCallback((date) => {
        const startOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - startOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 8) / 7);
    }, []);



    // Effects
    useEffect(() => {
        if (!recordOpened) return;
        if (formData) {
            if (formData.solicitacaoStatusId === 1) {
                setStatusPedido('ABERTO');
            } else if (formData.solicitacaoStatusId === 2) {
                setStatusPedido('ENVIADO');
            } else if (formData.solicitacaoStatusId === 3) {
                setStatusPedido('CANCELADO');
            } else {
                setStatusPedido('FECHADO');
            }
            if (!formData.itens) {
                setFormData({
                    ...formData,
                    itens: []
                });
            }
        }
    }, [recordOpened, formData, setFormData]);

    useEffect(() => {
        const fetchQuantidadeMaximaBarris = async () => {
            try {
                const ret = await new ApiBuscarQuantidadeMaximaBarrisService().execute({ _timestamp: Date.now() });
                
                if (ret) {
                    setQuantidadeMaximaBarris(ret);
                   
                }
            } catch (error) {
                setError("Erro ao buscar quantidade máxima de barris. Por favor, tente novamente.");
            }
        };
        fetchQuantidadeMaximaBarris();
    }, []);


    

    useEffect(() => {
        if (!dataMaxima) return;

        const deliveryDates = getDeliveryDates(dataMaxima);
        setPrevisaoDeEntrega(deliveryDates);
        setPrevisaoDataInicial(deliveryDates[0].toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' }));
        setPrevisaoDataFinal(deliveryDates[5].toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' }));
        setPrevisaoSemana(getWeekNumber(dataMaxima));
    }, [dataMaxima, getDeliveryDates, getWeekNumber]);

    // Update search results
    useEffect(() => {
        if (searchTerm.length > 0 && formData && formData.itens) {
            const itemsNotInTable = formData.itens.filter(item => !displayedItems.some(i => i.id === item.id));
            const results = itemsNotInTable.filter(item => item.prodDescricao.toLowerCase().includes(searchTerm.toLowerCase()));
            setSearchResults(results);
        } else {
            setSearchResults([]);
        }
    }, [searchTerm, formData, displayedItems]);

    // Determine displayed items
        useEffect(() => {
            if (formData && formData.itens) {
                // Get the top 8 items based on vendas90Dias
                const top8Items = [...formData.itens]
                    .sort((a, b) => b.historicoVendas.vendas90Dias - a.historicoVendas.vendas90Dias)
                    .slice(0, 8);

                // Get items where quantity > 0 and not already in top 8
                const itemsWithQuantity = formData.itens.filter(item => 
                    item.quantidade > 0 && 
                    !top8Items.some(topItem => topItem.id === item.id)
                );

                // Combine the lists
                const combinedItems = [...top8Items, ...itemsWithQuantity];

                // Remove duplicates in case an item is both in top 8 and has quantity > 0
                const uniqueItems = combinedItems.filter((item, index, self) =>
                    index === self.findIndex((i) => i.id === item.id)
                );

                // Sort the combined list by vendas90Dias
                const sortedItems = uniqueItems.sort((a, b) => 
                    b.historicoVendas.vendas90Dias - a.historicoVendas.vendas90Dias
                );

                setDisplayedItems(sortedItems);
            }
        }, [formData]);


    // Handlers
    const doCreateNewRecord = useCallback((ev) => {
        ev.preventDefault();
        if (typeof createNewRecord === 'function') {
            createNewRecord();
        }
    }, [createNewRecord]);

    const confirmarEnviarReserva = useCallback(async () => {
        try {
            setSearching(true);
            setSearchingMessage('Enviando dados da Reserva...');
            let usuario = getDadosLogin();
            let data = {
                ...formData
            };

            if (!formData.itens.some((item) => item.quantidade > 0)) {
                if (formData.solicitacaoStatusId === 1) {
                    return;
                }
            } else {
                for (let i = 0; i < data.itens.length; i++) {
                    if(!data.itens[i].quantidade)
                        data.itens[i].quantidade=0;
                    
                    if (data.itens[i].quantidade > 0) {
                        if (!data.itens[i].criadoPor) {
                            data.itens[i].criadoPor = usuario.nome;
                            data.itens[i].dataInclusao = new Date();
                        } else {
                            data.itens[i].alteradoPor = usuario.nome;
                            data.itens[i].dataUltimaAtualizacao = new Date();
                        }
                    } else {
                        if (formData.solicitacaoStatusId === 3) {
                            data.itens[i].canceladoPor = usuario.nome;
                            data.itens[i].dataCancelamento = new Date();
                        }
                    }
                }
            }

            const response = await new ApiSalvarSolResService().execute(data);

            if (response) {
                setSearching(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Reserva enviada com sucesso',
                    text: 'Sua reserva foi enviada com sucesso!'
                });

                if (typeof reloadForm === 'function') {
                    reloadForm();
                }
            } else {
                throw new Error('Erro ao enviar reserva');
            }
        } catch (e) {
            console.error(e);
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: e.message ? e.message : e
            });
        }
    }, [formData, reloadForm]);

    const enviarReserva = useCallback((ev) => {
       
        const now = new Date().toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }); //TIMEZONE BRASILIA
        const nowbsb = new Date(moment(now,'DD/MM/YYYY, HH:mm:ss'));//CONVERTE EM DATETIME

        if(nowbsb > dataMaxima)
        {
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Não é mais possível enviar Solicitação de Reserva para essa semana. Solicitação NÃO enviada.'
            });
            return;
        }

        try {
            ev.preventDefault();
            if (!formData || !formData.itens || formData.itens.length === 0) {
                throw new Error('Nenhum item para enviar na reserva');
            }
            let itensSemQuantidade = formData.itens.filter((item) => {
                // Verifica se a quantidade é um número e é maior ou igual a zero
                return isNaN(item.quantidade) || item.quantidade === null || item.quantidade === '' || item.quantidade < 0;
            });
            if (itensSemQuantidade.length > 0) {
                throw new Error('Existem itens com quantidade inválida!');
            }
            Swal.fire({
                title: 'Confirmação',
                text: 'Deseja realmente enviar a reserva?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.isConfirmed) {
                    confirmarEnviarReserva();
                }
            });
        } catch (e) {
            console.error(e);
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: e.message ? e.message : e
            });
        }
    }, [formData, confirmarEnviarReserva]);

    const handleQuantityChange = useCallback((e, item) => {
        //verifica se a quantidade informada é maior que a quantidade máxima de barris
        if (e.target.value > quantidadeMaximaBarris) {
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Quantidade informada maior que a quantidade máxima de barris permitida! Máxima Permitida: ' + quantidadeMaximaBarris,
            });
            return;
        }

        const quantity = parseInt(e.target.value, 10);
        const updatedItem = { ...item, quantidade: quantity };
        let updatedItens = formData.itens ? [...formData.itens] : [];
        const itemIndex = updatedItens.findIndex(i => i.id === item.id);
        if (itemIndex >= 0) {
            updatedItens[itemIndex] = updatedItem;
        } else {
            updatedItens.push(updatedItem);
        }
        setFormData({
            ...formData,
            itens: updatedItens
        });
    }, [quantidadeMaximaBarris,formData, setFormData]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleAddItem = (item) => {
        setDisplayedItems([...displayedItems, item]);
        setSearchTerm('');
        setSearchResults([]);
    };

    

    // Render
    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    return (
        <>
    <LoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '#page' }} visible={searching} showIndicator={true} shading={true} message={searchingMessage} showPane={true} />
        {console.log('recordOpened', recordOpened)}
        {console.log('podeIniciar', podeIniciar)}

        {!podeIniciar
            && <div style={{
                backgroundColor: '#f7d7da',
                boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                padding: '5px',
                borderRadius: '5px',
                textAlign: 'center',
                color: '#721c24',
                fontSize: '1.0em',
                marginBottom: '40px'
            }}>
                <p>INÍCIO DE NOVA RESERVA NÃO PERMITIDO</p>
                <p>VOCÊ PODERÁ ENVIAR UMA RESERVA NO PRÓXIMO DIA </p>
            </div>
            }
        
        {!recordOpened && podeIniciar &&
            <div style={
                {
                    backgroundColor: '#f7d7da',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    padding: '5px',
                    borderRadius: '5px',
                    textAlign: 'center',
                    color: '#721c24',
                    fontSize: '1.0em',
                    marginBottom: '40px'
                }
            }>
                <p>NÃO HÁ SOLICITAÇÃO DE RESERVA EM ABERTO OU ENVIADA PARA A PRÓXIMA SEMANA.</p>
                <p>INICIE UMA NOVA RESERVA CLICANDO EM <a href="#" onClick={doCreateNewRecord} style={{
                    color: '#6a6a5d',
                    textDecoration: 'underline'
                }}>"NOVA RESERVA"</a></p>
            </div>
        }


        {recordOpened && <Card>
            <Card.Body style={{
                marginBottom: '20px'
            }}>
                <p style={{
                    textAlign: 'center',
                    fontSize: '1.1em'
                }}>INSIRA A QUANTIDADE DE PRODUTOS QUE DESEJA RESERVAR<br /> DATA MÁXIMA DE ENVIO: {dataMaxima.toLocaleString('pt-BR')} 'HORÁRIO DE BRASÍLIA'</p>

                <div>
                    <span style={{
                        fontSize: '0.9em',
                        fontWeight: 'bold'
                    }}>STATUS: </span>
                    <span style={{
                        fontSize: '1.1em',
                        color: '#28a745',
                    }}>{statusPedido}</span>
                    <br />
                    <span style={{
                        fontSize: '0.9em',
                        fontWeight: 'bold'
                    }}>PREVISÃO DE ENTREGA: </span>
                    <span style={{
                        fontSize: '1.1em',
                        color: '#28a745',
                    }}>Entre {previsaoDataInicial} e {previsaoDataFinal} - Semana {previsaoSemana}</span>
                    {formData && formData.numeroPedido && <>
                        <br />
                        <span style={{
                            fontSize: '0.9em',
                            fontWeight: 'bold'
                        }}>RESERVA NÚMERO: </span>
                        <span style={{
                            fontSize: '1.1em',
                            color: '#28a745',
                        }}>{formData.numeroPedido}</span>
                    </>}
                </div>

                {/* Search Input */}
                <div style={{ margin: '20px 0' }}>
                    <input
                        type="text"
                        placeholder="Digite o nome do produto que queira adicionar e não esteja na tabela abaixo"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        style={{
                            width: '100%',
                            padding: '10px',
                            fontSize: '1em',
                            borderRadius: '5px',
                            border: '1px solid #ccc'
                        }}
                    />
                    {searchResults.length > 0 && (
                        <ul style={{
                            listStyleType: 'none',
                            padding: 0,
                            margin: 0,
                            maxHeight: '200px',
                            overflowY: 'auto',
                            border: '1px solid #ccc',
                            borderRadius: '5px',
                            marginTop: '5px'
                        }}>
                            {searchResults.map((item, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleAddItem(item)}
                                    style={{
                                        padding: '10px',
                                        cursor: 'pointer',
                                        borderBottom: '1px solid #eee'
                                    }}
                                >
                                    {item.prodDescricao}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                <table className="tableSolicitacao">
                    <thead>
                        <tr>
                            <th>Descrição Produto</th>
                            <th>Código EAN</th>
                            <th>Data da Solicitação</th>
                            <th>Unidades Vendidas Últimos 7 Dias</th>
                            <th>Unidades Vendidas Últimos 90 Dias</th>
                            <th>Incluído Por</th>
                            <th>Alterado Por</th>
                            <th>Data Alteração</th>
                            <th>Quantidade Solicitada (BARRIL)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedItems.map((item, index) => {
                            const quantity = item.quantidade || '';
                            return <tr key={index}>
                                <td>{item.prodDescricao}</td>
                                <td>{item.prodEan}</td>
                                <td>{formData.dataCadastro ? moment(formData.dataCadastro).format('DD/MM/YYYY') : ''}</td>
                                <td>{item.historicoVendas.vendas7Dias}</td>
                                <td>{item.historicoVendas.vendas90Dias}</td>
                                <td>{item.criadoPor}</td>
                                <td>{item.alteradoPor}</td>
                                <td>{item.dataUltimaAtualizacao ? moment(item.dataUltimaAtualizacao).format('DD/MM/YYYY') : ''}</td>
                                <td>
                                    <input
                                        type="number"
                                        value={quantity}
                                        style={{
                                            width: '100%',
                                            border: '1px solid #aaa',
                                            paddingLeft: '5px',
                                            paddingRight: '5px',
                                            fontSize: '1.2em'
                                        }}
                                        onChange={(e) => handleQuantityChange(e, item)}
                                    />
                                </td>
                            </tr>
                        })}
                    </tbody>
                    <tfoot>
                        {(statusPedido === 'ABERTO' || statusPedido === 'CANCELADO') && (
                            <tr>
                                <td colSpan="10" style={{ textAlign: 'left' }}>
                                    <b>VOCÊ PRECISA ENVIAR SUA RESERVA ATÉ A DATA MÁXIMA DE ENVIO {dataMaxima.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })} </b>
                                </td>
                            </tr>
                        )}
                    </tfoot>
                </table>
                <div className="d-flex justify-content-center">
                    <button className="btn btn-success" onClick={enviarReserva} style={{
                        width: '200px'
                    }}>ENVIAR RESERVA</button>
                </div>

            </Card.Body>
        </Card>}
    </>
    );
}
