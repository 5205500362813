import _ from 'lodash';
import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

export class ApiListarVendedorComissionamento {
  static instance = null;
  static getInstance() {
    if (!ApiListarVendedorComissionamento.instance) {
      ApiListarVendedorComissionamento.instance = new ApiListarVendedorComissionamento();
    }
    return ApiListarVendedorComissionamento.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  execute = async () => {
    try {
      console.log('listar-vendedor-comissionamento');
      const response = await this.urlLinxCore.get('/cliente/listar-vendedor-comissionamento', );
      console.log(response.data.itens)
      let data = response.data.itens || [];
      return data;
    } catch (er) {
      await new AxiosErrorService().execute(er);
    }
  };
}
