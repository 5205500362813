import React, { useState, useEffect } from 'react';
import styles from './Ajuda.module.scss';
import lxChat from '../../../../assets/images/linx/chat.png';
import lxDoc from '../../../../assets/images/linx/share.png';
import lxSuporte from '../../../../assets/images/linx/portal.png';
import lxChamados from '../../../../assets/images/linx/chamados.png';
import lxWhatsapp from '../../../../assets/images/linx/whatsapp.png';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { AjudaService } from './Ajuda.service';
import { UI_VERSION } from '../../../shared/constants/Const';
import { getDadosLogin } from '../../../shared/utils/Utils';
import { TIPO_SITE } from '../../../shared/constants/TipoSiteConstant';
import { getCnpj } from '../../../shared/utils/Utils';
import Swal from 'sweetalert2';
import { faDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Ajuda() {
  const [uiVersion, setUiVersion] = useState('');
  const [backEndVersion, setBackEndVersion] = useState('');
  const [share, setShare] = useState('');
  const [salesforceUrl, setSalesforceUrl] = useState('');

  useEffect(() => {
    getUiVersion();
    getBackEndVersion();
    getShareUrl();
    getSalesforceUrl();
  }, []);

  const getUiVersion = async () => {
    setUiVersion(UI_VERSION);
  };

  const getBackEndVersion = async () => {
    const response = await AjudaService.getBackEndVersion();
    setBackEndVersion(response);
  };

  const getShareUrl = async () => {
    const dadosLogin = getDadosLogin();
    setShare(dadosLogin.share);
  };

  const getSalesforceUrl = async () => {
    const response = await AjudaService.getBackEndSalesforceUrl(getCnpj());
    setSalesforceUrl(response);
  };

  return (
    <LinxPostos breadcrumb="Ajuda">
      <div className="shadow-sm bg-white mt-3 p-1">
        <section className="mb-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xs-12 col-sm-6">
                <h1 className="text-weight-ligth text-center mt-4 mb-3 text-uppercase text-weight-light help-item__title-primary">
                  Portal de Ajuda ao Cliente
                </h1>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-3 help-item container">
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <div className="shahow-sm shadow-hover card-panel text-center b">
                <figure>
                  <img srcSet={lxChat} alt="illustração" />
                </figure>
                <a
                  className="help-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://suportelinx.my.salesforce-sites.com/externalchat"
                >
                  <h1 className="text-uppercase help-item__title"> Chat </h1>
                </a>
                <p>Fale com nossos especialistas através do chat de suporte</p>
              </div>
            </div>

            <div className="col-sm-12 col-md-4">
              <div className="shahow-sm shadow-hover card-panel text-center">
                <figure>
                  <img srcSet={lxDoc} alt="illustração" />
                </figure>
                <a
                  className="help-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={share}
                >
                  <h1 className="text-uppercase help-item__title">
                    {' '}
                    Linx Share{' '}
                  </h1>
                </a>
                <p>
                  Acesse nosso portal de documentação e artigos sobre nossos
                  produtos
                </p>
              </div>
            </div>

            <div className="col-sm-12 col-md-4">
              <div className="shahow-sm shadow-hover card-panel text-center">
                <figure>
                  <img srcSet={lxSuporte} alt="illustração" />
                </figure>
                <a
                  className="help-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://cliente.linx.com.br"
                >
                  <h1 className="text-uppercase help-item__title">
                    {' '}
                    Portal do Cliente{' '}
                  </h1>
                </a>
                <p>
                  Verifique o status de seus atendimentos, obtenha suporte e
                  acesse a documentação do #DicaLinx
                </p>
              </div>
            </div>

            {(salesforceUrl) && (           
                  <div className="col-sm-12 col-md-6">
                    <div className="shahow-sm shadow-hover card-panel text-center">
                      <figure>
                        <img srcSet={lxChamados} alt="illustração" />
                      </figure>
                      <a
                        className="help-link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${salesforceUrl}`}
                      >
                        <h1 className="text-uppercase help-item__title">
                          {' '}
                          Verifique aqui o status dos seus atendimentos{' '}
                        </h1>
                      </a>
                    </div>
                  </div>
            )}
            <div className="col-sm-12 col-md-6">
              <div className="shahow-sm shadow-hover card-panel text-center">
                <figure>
                  <FontAwesomeIcon icon={faDollar} style={{ fontSize: '4.0em', color: '#2c004b', marginBottom: '15px', marginTop: '10px' }} />
                </figure>
                <a
                  className="help-link"
                  target="_blank"
                  href="#/"
                  onClick={async (e) => {
                    e.preventDefault();
                    try{
                      //debugger;
                      const portalFinanceiroUrl = await AjudaService.getPortalFinanceiroUrl();
                      window.open(portalFinanceiroUrl, '_blank');
                    }catch(ex){
                      Swal.fire({
                        icon: 'warning',
                        title: 'Falha ao acessar o Portal Financeiro',
                        text: ex.message,
                      });
                    }
                  }}
                >
                  <h1 className="text-uppercase help-item__title">
                    Portal Financeiro
                  </h1>
                </a>
              </div>
            </div>
          </div>
        </section>
        <br/>
        <hr></hr>
        <br/>
        <hr className="w-50 d-md-none d-xl-none" />

        <section className="mb-5 help-item container">
          <div className="row">
            <div className="col mb-3">
              <a
                className="help-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="shahow-sm shadow-hover card-panel position-relative">
                  <i className="rounded-circle circle royal-outline icon icon-lx-desktop border border-primary position-absolute"></i>
                  <span className="text-primary text-uppercase help-item__title text-truncate">
                    Acesso Remoto
                    <i className="icon icon-lx-arrow-right"></i>
                  </span>
                  <p className="text-description">
                    Através do acesso remoto, nossos técnicos poderão acessar
                    seu computador e resolver seus problemas de forma rápida e
                    prática.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <a href="https://qsme.tv/linxremotorapido" target="_blank" rel="noopener noreferrer" className="btn btn-primary w-100">
                        Acesso remoto rápido
                      </a>
                    </div>
                    <div className="col-md-6">
                      <a href="https://qsme.tv/linxremotocompleto" target="_blank" rel="noopener noreferrer" className="btn btn-primary w-100">
                        Acesso remoto completo
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-sm-1 d-none d-sm-block text-center">
              <p className="line"> &nbsp; </p>
            </div>

            <div className="col">
              <div className="shahow-sm shadow-hover card-panel position-relative">
                <i className="rounded-circle circle royal-outline icon icon-lx-phone border border-primary position-absolute"></i>
                <span className="text-primary text-uppercase help-item__title text-truncate">
                  Horários de Atendimento
                  <i className="icon icon-lx-arrow-right"></i>
                </span>

                <p className="text-description">
                  - Telefone {TIPO_SITE === 'RetaguardaSellerWeb' ? ' 3003-1401 (opção 1)' : ' 3003-1401 (opção 2)'}{<br />}
                  - Todos os dias das 08h às 19h para Retaguarda {<br />}
                  - Todos os dias 24h para Pdv {<br />}
                </p>
                <div className="container">
                  <div className="row justify-content-center">
                    <a
                      className="help-link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://wa.me/551121034321"
                    >
                      <figure>
                        <img srcSet={lxWhatsapp} alt="illustração" />
                      </figure>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.versions}>
            <span>{`v${uiVersion} / v${backEndVersion}`}</span>
          </div>
        </section>
      </div>
    </LinxPostos>
  );
}

export default Ajuda;
