import { SelectBox } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';
import { EditCliente } from '../edit-cliente/EditCliente';
import { BuscarClienteService } from '../../../../../services/cliente/buscarCliente.service';
import { DropDownImage } from './../drop-down-image';

export const BuscarCliente = ({ title, onSelected, onUpdated }) => {
  const { changeValue, changeBlur } = useNovaOs();
  const { values, touched, errors } = useSelector(state => state.novaOs);
  const dispatch = useDispatch();
  const dataSource = new DataSource({
    key: 'id',
    byKey: key => {
      return [values.cliente || null];
    },
    load: async function(arg) {
      if (arg.searchOperation !== 'contains') {
        return [];
      }
      if (!arg.searchValue) {
        return [];
      }
      const service = new BuscarClienteService();
      const clientes = await service.execute(arg.searchValue);
      return clientes.map(c => {
        if (c.inativo) {
          c.disabled = true;
          c.nome = `${c.nome} (Cliente Inativo)`;
        }
        if (c.bloqueado) {
          c.disabled = true;
          c.nome = `${c.nome} (Cliente Bloqueado)`;
        }
        return c;
      });
    },
  });

  const onSelectionChanged = useCallback(
    e => {
      if (!e.selectedItem || e.selectedItem.id === undefined) {
        if (!values.cliente) return;
        dispatch(changeValue({ fieldName: 'cliente', value: null }));
        dispatch(changeValue({ fieldName: 'enderecoEntrega', value: null }));
        return;
      }
      if ((values.cliente || {}).id === e.selectedItem.id) {
        return;
      }
      const id = e.selectedItem.id;
      console.log('CLIENTE', e.selectedItem);
      dispatch(changeValue({ fieldName: 'cliente', value: e.selectedItem }));
      dispatch(
        changeValue({
          fieldName: 'enderecoEntrega',
          value: e.selectedItem.enderecos[0],
        }),
      );
      if (onSelected) {
        onSelected(id);
      }
    },
    [changeValue, dispatch, values.cliente, onSelected],
  );
  return (
    <div className="form-group">
      <Form.Label htmlFor="cliente" className="text-primary">
        {title}
        <span className="text-danger">*</span>
      </Form.Label>
      <div className="d-flex">
        <SelectBox
          id="cliente"
          name="cliente"
          className="w-100 m-0 p-0"
          //só permite alterar o cliente, se não tiver pagamentos adicionados
          //por exemplo o cliente A pode usar forma do tipo boleto e crédito e adicionou pagamentos
          //o novo cliente pode não ter essas formas de pagamento habilitadas, então não pode ser alterado
          //necessário excluir os pagamentos para alterar o cliente
          //e então adicionar  os pagamentos para o novo cliente, onde a se faz a validação de forma de pagamento novamente
          disabled={(values.status || {}).id !== 1 || (values.pedidos && values.pedidos.pagamentos && values.pedidos.some(pedido => pedido.pagamentos.some(pagamento => !pagamento.DataExclusao)))}
          dropDownButtonRender={DropDownImage}
          searchEnabled={true}
          hint="Digite o nome, nome fantasia ou o CPF/CNPJ do cliente"
          placeholder="Digite o nome, nome fantasia ou o CPF/CNPJ do cliente"
          displayExpr="nome"
          height={40}
          valueExpr="id"
          value={values.cliente || {}}
          acceptCustomValue={false} // Não permite que o valor digitado seja aceito
          dataSource={dataSource}
          selectedItem={values.cliente || {}}
          onSelectionChanged={onSelectionChanged}
          searchTimeoutOption={500}
          onFocusOut={e => dispatch(changeBlur({ fieldName: 'cliente' }))}
        ></SelectBox>
        <EditCliente onUpdated={() => {}}></EditCliente>
      </div>
      {(touched || {}).cliente && (errors || {}).cliente && <div className="text-danger validationError">{errors.cliente}</div>}
    </div>
  );
};
