import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './DocumentosDinamico.module.scss';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { locale, loadMessages } from 'devextreme/localization';
import ptMessages from '../../../../../assets/js/pt.json';
import { menus } from '../../../../shared/constants/MenuConstants';
import {
  getNomePagina,
  getNomePaginaAmigavel
} from '../../../../shared/utils/Utils';
import { DocumentosDinamicoService } from './DocumentosDinamico.service';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import config from 'devextreme/core/config';
import 'jspdf-autotable';
import ResultPanel from '../../../../components/Report/ResultPanel/ResultPanel';
import DateToolTip from '../../../../components/dateTooltip/DateToolTip';
import TextToolTip from '../../../../components/textToolTip/TextToolTip';
import { reportMangerService, renderSummarySumDefault } from '../../../../services/ReportManager.service';
import { NotificationLevel } from '../../../../shared/constants/Const';
import DateTimeBox from '../../../../components/DateTimeBox/DateTimeBox';
import FilterPanel from '../../../../components/Report/FilterPanel/FilterPanel';
import { LoadPanel, SelectBox } from 'devextreme-react';
import { TIPO_SITE } from '../../../../shared/constants/TipoSiteConstant';


export function DocumentosDinamico(props) {
  const PATH_MENU = menus.COD_16365; 
  const CodigoFuncionalidade = 80690;
  const multiEmpresa = false;
  const gridResultRef = useRef(null);
  const [showSearch, setShowSearch] = useState(false);
  const [disabledSearch, setDisabledSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [startDate] = useState(new Date().toISOString());
  const [endDate] = useState(new Date().toISOString());
  const [tipoRelatorioProduto] = useState(1);
  const [tipoDataEmissao] = useState(1);
  const [filters, setFilters] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [serverReports, setServerReports] = useState([]);
  const [serverSubReports, setSubServerReports] = useState([]);
  const [chosenReport, setChosenReport] = useState([]);
  const [chosenSubReport, setChosenSubReport] = useState([]);
  const [columns, setColumns] = useState([]);
  const [title, setTitle] = useState([]);
  let [colunasFinais, setColunasFinais] = useState([]);

  
  const [appliedFilters, setAppliedFilters] = useState({
    dataInicio: startDate,
    dataFim: endDate,
    empresas: [],
    multiEmpresa: multiEmpresa,
    tipoRelatorio: tipoRelatorioProduto,
    tipoData: tipoDataEmissao
  });

  config({
    defaultCurrency: 'BRL'
  });

  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);
  }, [appliedFilters, startDate, endDate, tipoRelatorioProduto, tipoDataEmissao]);

  useEffect(() => {
    // loadColumns(appliedFilters.tipoRelatorio); 
      GetReports();
  }, [appliedFilters.tipoRelatorio]);
  // Agora serve apenas para carregar os botão ao abrir a pagina

  const returnColumnsByTipoRelatorio = (tipoRelatorio) =>
  {
    return DocumentosDinamicoService.GetColumnsByProduto(multiEmpresa)
  }

  const refreshColumns = newColumns => {
    //pega as colunas originais
    let colunas = returnColumnsByTipoRelatorio(appliedFilters.tipoRelatorio);
    //atualiza as colunas
    let colunasAtualizadas = reportMangerService.RefreshColumns(
      colunas,
      newColumns
    );
    //confirma as colunas novas/alteradas
    console.log('refrash columnas', colunas);
    console.log('refrash colunasAtualizadas', colunasAtualizadas);
    console.log('refrash colunasFinais', colunasFinais);
    setColunasFinais([...colunasAtualizadas]);
    setColumns(colunasAtualizadas);
  };

  const GetReports = async () => {
    const result = await reportMangerService.Get(CodigoFuncionalidade);

    if (result.notificationLevel === NotificationLevel.Success) {
      let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
      if (TIPO_SITE === 'RetaguardaEmporioPro')
        reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');

      setServerReports(reports);
    }
  };


  const find = async () => {
    setShowResult(false);
    if (!appliedFilters) {
      notify('Informe ao menos um filtro para pesquisa.', 'error', 2000, 'top');
      return;
    }
    setFilters({
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim,
      multiEmpresa: multiEmpresa,
      tipoRelatorio: appliedFilters.tipoRelatorio,
      tipoData: appliedFilters.tipoData
    });
    await request(appliedFilters);

    updateReportInfo(chosenReport);
  };

  const request = async model => {
    setSearching(true);

    const response = await DocumentosDinamicoService.find(model);

    if (response.result == null && response.notifications == null)
    {
      notify(
        'Falha na requisição.',
        'error',
        2000,
        'top'
      );
      setResultData(null);
    }else
    if(response.notifications.length > 0){
      response.notifications.forEach(notification =>{
        notify(
          notification.message,
          'info',
          2000,
          'top'
        );
      });
      setResultData(null);
    }else 
    if (!response.result.length) {
        notify(
          'Não foram encontrados registros para esta pesquisa.',
          'info',
          2000,
          'top'
        );
      setResultData(null);
      } else setResultData(response.result);

    setShowResult(true);
    setSearching(false);
  };

  const subReportHandle = async report => {
    setSearching(true);

    setChosenSubReport(report);
    let model = reportMangerService.FiltersToModel(report.data.filtros);
    let periodCreated = reportMangerService.CreatePeriod(report.data.periodo);

    //loadColumns(model.tipoRelatorio[0]); 

    model = {
      ...model,
      dataInicio: periodCreated.dataInicio,
      dataFim: periodCreated.dataFim,
      multiEmpresa: multiEmpresa,
    };

    model.tipoRelatorio = model.tipoRelatorio[0];
    model.tipoData = model.tipoData[0];

    setFilters(model);

    await request(model);

    updateReportInfo(report);
  };

  const reportHandle = report => {
    setChosenReport(report);
    let rdata = report.data;
    if (rdata != undefined)
    {
      if (rdata.filtros != undefined)
      {
        let tipoRelatorio = report.data.filtros.find(x => x.campo == 'tipoRelatorio').valor[0];
        setAppliedFilters({ ...appliedFilters, tipoRelatorio: tipoRelatorio });
        refreshColumns(report.data.colunas); 
      }
    }

    if (report.tipo === 0) {
      setShowSearch(true);

    } else {
      setShowSearch(false);
      subReportHandle(report);
    }
  };

  const handleSelectTipoRelatorioChange = (event) => {
    const newValue = parseInt(event + "", 10);
    //se mudou, tira o resultado que ta em tela
    if (newValue !== appliedFilters.tipoRelatorio) {
      setShowResult(false);
    }
    setAppliedFilters({ ...appliedFilters, tipoRelatorio: newValue });
  };

  const handleSelectTipoDataChange = (event) => {
    const newValue = parseInt(event + "", 10);
    //se mudou, tira o resultado que ta em tela
    if (newValue !== appliedFilters.tipoData) {
      setShowResult(false);
    }
    setAppliedFilters({ ...appliedFilters, tipoData: newValue });
  };

  const onValueChanged = event => {
    if (!moment(event.value).isValid()) {
      setDisabledSearch(true);
      notify('Data inválida.', 'error', 2000, 'top');
    } else {
      setAppliedFilters(filters => {
        return {
          ...filters,
          [event.element.id]:
            event.element.id === 'dataFim'
              ? moment(event.value).format('YYYY-MM-DD')
              : moment(event.value).format('YYYY-MM-DD')

        };
      });
      setDisabledSearch(false);
    }
    setShowResult(false);
  };

  const clearFilters = () => {
    setAppliedFilters({
      dataInicio: startDate,
      dataFim: endDate,
      multiEmpresa: multiEmpresa,
      tipoRelatorio: tipoRelatorioProduto,
      tipoData: tipoDataEmissao
    });
    notify('Todos os filtros aplicados foram removidos e os resultados anteriores foram limpos.', 'info', 2000, 'top');
    setShowResult(false);
  };
  
  const updateReportInfo = report => {
    let tipoRelatorio = 1;
    let tipoData = 1;
    let colunasTemp = [];
    if (report.data != undefined && report.data.filtros != undefined) {
      tipoRelatorio = report.data.filtros.find(x => x.campo == 'tipoRelatorio').valor[0];
      appliedFilters.tipoRelatorio = tipoRelatorio;
      tipoData = report.data.filtros.find(x => x.campo == 'tipoData').valor[0];
      appliedFilters.tipoData = tipoData;
      colunasTemp = report.data.colunas;
    } else
    {
      tipoRelatorio = appliedFilters.tipoRelatorio;
      tipoData = appliedFilters.tipoData
      colunasTemp = returnColumnsByTipoRelatorio(tipoRelatorio); 
    }
    refreshColumns(colunasTemp);
    setTitle(`RESULTADO - ${report.titulo}`);

    if (report.tipo === 0) {
      setSubServerReports([]);
      report.data = { periodo: { tipo: 0 } };
      report.publico = false;
      setChosenSubReport(report);
    }
  };

  const refreshReports = async () => {
    const result = await reportMangerService.Get(CodigoFuncionalidade);

    if (result.notificationLevel === NotificationLevel.Success) {
      let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
        if (TIPO_SITE === 'RetaguardaEmporioPro')
          reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');
      setServerReports(reports);

      let report = result.data.find(x => {
        return x.tipo === 1;
      });

      if (report) {
        let sub = report.subReport.find(x => {
          return x.id === chosenReport.id;
        });

        if (sub) {
          setChosenReport(sub);
          subReportHandle(sub);
        } else {
          setShowResult(false);
        }
      }
    }
  };

  const resultGrid = () => {
    return (
      <ResultPanel
        dataSource={resultData}
        columns={columns}
        saveReport={true}
        gridResultRef={gridResultRef}
        codigoRetaguarda={CodigoFuncionalidade}
        fileName="documentos"
        chosenSubReport={chosenSubReport}
        refreshReports={refreshReports}
        changeChosenSubReport={reportHandle}
        filtroOutros={[
          {
            chave: 0,
            valor: [filters.tipoRelatorio],
            campo: 'tipoRelatorio'
          },
          {
            chave: 1,
            valor: [filters.tipoData],
            campo: 'tipoData'
          }
        ]}
        disabled={searching}
        panelTitle={title}
        allowExpand={true}
        reportTitle={getNomePaginaAmigavel(PATH_MENU)}
        filters={true}
        /*Nota: summary customizável tem no relatório de consignados*/
        summary={() => renderSummarySumDefault(columns)}
      >
        <DateToolTip inicial={filters.dataInicio} final={filters.dataFim} />
        <TextToolTip
          text={"Relatório por " + (filters.tipoRelatorio == 1 ? "produto" : "pagamento")}
        />
        <TextToolTip
          text={"Por período de " + (filters.tipoData == 1 ? "emissão" : "movimento") }
        />
      </ResultPanel>
    );
  };

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU)} id="page">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={searching}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
        closeOnOutsideClick={false}
      />
      <FilterPanel
        onSearch={find}
        clearFilters={clearFilters}
        reports={serverReports}
        searching={searching}
        showSearch={showSearch}
        chosenReport={chosenReport}
        reportHandle={reportHandle}
        subReportHandle={subReportHandle}
        disabledSearch={disabledSearch}
        subReports={serverSubReports}
      >
        <div className="row">
          <div className={`col-2`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>De</span>
                <DateTimeBox
                  type="date"
                  id="dataInicio"
                  date={startDate}
                  disabled={searching}
                  value={appliedFilters.dataInicio}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>
          <div className={`col-2`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>Até</span>
                <DateTimeBox
                  type="date"
                  id="dataFim"
                  date={endDate}
                  disabled={searching}
                  value={appliedFilters.dataFim}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>

          <div style={{ display: 'none' }}>
            <div className={styles.selectWrapper}>
              <span>Tipo de relatório</span>
              <SelectBox
                items={[
                  { value: 1, text: 'Por produto' },
                ]}
                value={appliedFilters.tipoRelatorio}
                displayExpr="text"
                valueExpr="value"
                disabled={searching}
                onValueChange={handleSelectTipoRelatorioChange}
              />
            </div>
          </div>

          <div className={`col-2`}>

            <div className={styles.selectWrapper}>
              <span>Tipo de período</span>
              <SelectBox
                items={[
                  { value: 1, text: 'Por data de emissão' },
                  { value: 0, text: 'Por data de movimento' },
                ]}
                value={appliedFilters.tipoData}
                displayExpr="text"
                valueExpr="value"
                disabled={searching}
                onValueChange={handleSelectTipoDataChange}
              />
            </div>
          </div>

        </div>
      </FilterPanel>
      {showResult > 0 ? resultGrid() : <></>}
    </LinxPostos>
  );
}

export function MatrizDocumentosDinamico(props) {
  const PATH_MENU = menus.COD_22660;
  const CodigoFuncionalidade = 80690;
  const multiEmpresa = true;
  const gridResultRef = useRef(null);
  const [showSearch, setShowSearch] = useState(false);
  const [disabledSearch, setDisabledSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [startDate] = useState(new Date().toISOString());
  const [endDate] = useState(new Date().toISOString());
  const [tipoRelatorioProduto] = useState(1);
  const [tipoDataEmissao] = useState(1);
  const [filters, setFilters] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [serverReports, setServerReports] = useState([]);
  const [serverSubReports, setSubServerReports] = useState([]);
  const [chosenReport, setChosenReport] = useState([]);
  const [chosenSubReport, setChosenSubReport] = useState([]);
  const [columns, setColumns] = useState([]);
  const [title, setTitle] = useState([]);
  let [colunasFinais, setColunasFinais] = useState([]);



  const [appliedFilters, setAppliedFilters] = useState({
    dataInicio: startDate,
    dataFim: endDate,
    empresas: [],
    multiEmpresa: multiEmpresa,
    tipoRelatorio: tipoRelatorioProduto,
    tipoData: tipoDataEmissao
  });

  config({
    defaultCurrency: 'BRL'
  });

  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);
  }, [appliedFilters, startDate, endDate, tipoRelatorioProduto, tipoDataEmissao]);

  useEffect(() => {
    // loadColumns(appliedFilters.tipoRelatorio); 
      GetReports();
  }, [appliedFilters.tipoRelatorio]);
  // Agora serve apenas para carregar os botão ao abrir a pagina

  const returnColumnsByTipoRelatorio = (tipoRelatorio) =>
  {
    return DocumentosDinamicoService.GetColumnsByProduto(multiEmpresa)
  }

  const refreshColumns = newColumns => {
    //pega as colunas originais
    let colunas = returnColumnsByTipoRelatorio(appliedFilters.tipoRelatorio);
    //atualiza as colunas
    let colunasAtualizadas = reportMangerService.RefreshColumns(
      colunas,
      newColumns
    );
    //confirma as colunas novas/alteradas
    console.log('refrash columnas', colunas);
    console.log('refrash colunasAtualizadas', colunasAtualizadas);
    console.log('refrash colunasFinais', colunasFinais);
    setColunasFinais([...colunasAtualizadas]);
    setColumns(colunasAtualizadas);
  };

  const GetReports = async () => {
    const result = await reportMangerService.Get(CodigoFuncionalidade);

    if (result.notificationLevel === NotificationLevel.Success) {
      let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
      if (TIPO_SITE === 'RetaguardaEmporioPro')
        reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');

      setServerReports(reports);
    }
  };


  const find = async () => {
    setShowResult(false);
    if (!appliedFilters) {
      notify('Informe ao menos um filtro para pesquisa.', 'error', 2000, 'top');
      return;
    }
    setFilters({
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim,
      multiEmpresa: multiEmpresa,
      tipoRelatorio: appliedFilters.tipoRelatorio,
      tipoData: appliedFilters.tipoData
    });
    await request(appliedFilters);

    updateReportInfo(chosenReport);
  };

  const request = async model => {
    setSearching(true);

    const response = await DocumentosDinamicoService.find(model);

    if (response.result == null && response.notifications == null)
    {
      notify(
        'Falha na requisição.',
        'error',
        2000,
        'top'
      );
      setResultData(null);
    }else
    if(response.notifications.length > 0){
      response.notifications.forEach(notification =>{
        notify(
          notification.message,
          'info',
          2000,
          'top'
        );
      });
      setResultData(null);
    }else 
    if (!response.result.length) {
        notify(
          'Não foram encontrados registros para esta pesquisa.',
          'info',
          2000,
          'top'
        );
      setResultData(null);
      } else setResultData(response.result);

    setShowResult(true);
    setSearching(false);
  };

  const subReportHandle = async report => {
    setSearching(true);

    setChosenSubReport(report);
    let model = reportMangerService.FiltersToModel(report.data.filtros);
    let periodCreated = reportMangerService.CreatePeriod(report.data.periodo);

    //loadColumns(model.tipoRelatorio[0]); 

    model = {
      ...model,
      dataInicio: periodCreated.dataInicio,
      dataFim: periodCreated.dataFim,
      multiEmpresa: multiEmpresa,
    };

    model.tipoRelatorio = model.tipoRelatorio[0];
    model.tipoData = model.tipoData[0];

    setFilters(model);

    await request(model);

    updateReportInfo(report);
  };

  const reportHandle = report => {
    setChosenReport(report);
    let rdata = report.data;
    if (rdata != undefined)
    {
      if (rdata.filtros != undefined)
      {
        let tipoRelatorio = report.data.filtros.find(x => x.campo == 'tipoRelatorio').valor[0];
        setAppliedFilters({ ...appliedFilters, tipoRelatorio: tipoRelatorio });
        refreshColumns(report.data.colunas); 
      }
    }

    if (report.tipo === 0) {
      setShowSearch(true);

    } else {
      setShowSearch(false);
      subReportHandle(report);
    }
  };

  const handleSelectTipoRelatorioChange = (event) => {
    const newValue = parseInt(event + "", 10);
    //se mudou, tira o resultado que ta em tela
    if (newValue !== appliedFilters.tipoRelatorio) {
      setShowResult(false);
    }
    setAppliedFilters({ ...appliedFilters, tipoRelatorio: newValue });
  };

  const handleSelectTipoDataChange = (event) => {
    const newValue = parseInt(event + "", 10);
    //se mudou, tira o resultado que ta em tela
    if (newValue !== appliedFilters.tipoData) {
      setShowResult(false);
    }
    setAppliedFilters({ ...appliedFilters, tipoData: newValue });
  };

  const onValueChanged = event => {
    if (!moment(event.value).isValid()) {
      setDisabledSearch(true);
      notify('Data inválida.', 'error', 2000, 'top');
    } else {
      setAppliedFilters(filters => {
        return {
          ...filters,
          [event.element.id]:
            event.element.id === 'dataFim'
              ? moment(event.value).format('YYYY-MM-DD')
              : moment(event.value).format('YYYY-MM-DD')

        };
      });
      setDisabledSearch(false);
    }
    setShowResult(false);
  };

  const clearFilters = () => {
    setAppliedFilters({
      dataInicio: startDate,
      dataFim: endDate,
      multiEmpresa: multiEmpresa,
      tipoRelatorio: tipoRelatorioProduto,
      tipoData: tipoDataEmissao
    });
    notify('Todos os filtros aplicados foram removidos e os resultados anteriores foram limpos.', 'info', 2000, 'top');
    setShowResult(false);
  };
  
  const updateReportInfo = report => {
    let tipoRelatorio = 1;
    let tipoData = 1;
    let colunasTemp = [];
    if (report.data != undefined && report.data.filtros != undefined) {
      tipoRelatorio = report.data.filtros.find(x => x.campo == 'tipoRelatorio').valor[0];
      appliedFilters.tipoRelatorio = tipoRelatorio;
      tipoData = report.data.filtros.find(x => x.campo == 'tipoData').valor[0];
      appliedFilters.tipoData = tipoData;
      colunasTemp = report.data.colunas;
    } else
    {
      tipoRelatorio = appliedFilters.tipoRelatorio;
      tipoData = appliedFilters.tipoData
      colunasTemp = returnColumnsByTipoRelatorio(tipoRelatorio); 
    }
    refreshColumns(colunasTemp);
    setTitle(`RESULTADO - ${report.titulo}`);

    if (report.tipo === 0) {
      setSubServerReports([]);
      report.data = { periodo: { tipo: 0 } };
      report.publico = false;
      setChosenSubReport(report);
    }
  };

  const refreshReports = async () => {
    const result = await reportMangerService.Get(CodigoFuncionalidade);

    if (result.notificationLevel === NotificationLevel.Success) {
      let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
        if (TIPO_SITE === 'RetaguardaEmporioPro')
          reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');
      setServerReports(reports);

      let report = result.data.find(x => {
        return x.tipo === 1;
      });

      if (report) {
        let sub = report.subReport.find(x => {
          return x.id === chosenReport.id;
        });

        if (sub) {
          setChosenReport(sub);
          subReportHandle(sub);
        } else {
          setShowResult(false);
        }
      }
    }
  };

  const resultGrid = () => {
    return (
      <ResultPanel
        dataSource={resultData}
        columns={columns}
        saveReport={true}
        gridResultRef={gridResultRef}
        codigoRetaguarda={CodigoFuncionalidade}
        fileName="documentos"
        chosenSubReport={chosenSubReport}
        refreshReports={refreshReports}
        changeChosenSubReport={reportHandle}
        filtroOutros={[
          {
            chave: 0,
            valor: [filters.tipoRelatorio],
            campo: 'tipoRelatorio'
          },
          {
            chave: 1,
            valor: [filters.tipoData],
            campo: 'tipoData'
          }
        ]}
        disabled={searching}
        panelTitle={title}
        allowExpand={true}
        reportTitle={getNomePaginaAmigavel(PATH_MENU)}
        filters={true}
        /*Nota: summary customizável tem no relatório de consignados*/
        summary={() => renderSummarySumDefault(columns)}
      >
        <DateToolTip inicial={filters.dataInicio} final={filters.dataFim} />
        <TextToolTip
          text={"Relatório por " + (filters.tipoRelatorio == 1 ? "produto" : "pagamento")}
        />
        <TextToolTip
          text={"Por período de " + (filters.tipoData == 1 ? "emissão" : "movimento") }
        />
      </ResultPanel>
    );
  };

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU)} id="page">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={searching}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
        closeOnOutsideClick={false}
      />
      <FilterPanel
        onSearch={find}
        clearFilters={clearFilters}
        reports={serverReports}
        searching={searching}
        showSearch={showSearch}
        chosenReport={chosenReport}
        reportHandle={reportHandle}
        subReportHandle={subReportHandle}
        disabledSearch={disabledSearch}
        subReports={serverSubReports}
      >
        <div className="row">
          <div className={`col-2`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>De</span>
                <DateTimeBox
                  type="date"
                  id="dataInicio"
                  date={startDate}
                  disabled={searching}
                  value={appliedFilters.dataInicio}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>
          <div className={`col-2`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>Até</span>
                <DateTimeBox
                  type="date"
                  id="dataFim"
                  date={endDate}
                  disabled={searching}
                  value={appliedFilters.dataFim}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>

          <div style={{ display: 'none' }}>
            <div className={styles.selectWrapper}>
              <span>Tipo de relatório</span>
              <SelectBox
                items={[
                  { value: 1, text: 'Por produto' },
                ]}
                value={appliedFilters.tipoRelatorio}
                displayExpr="text"
                valueExpr="value"
                disabled={searching}
                onValueChange={handleSelectTipoRelatorioChange}
              />
            </div>
          </div>

          <div className={`col-2`}>

            <div className={styles.selectWrapper}>
              <span>Tipo de período</span>
              <SelectBox
                items={[
                  { value: 1, text: 'Por data de emissão' },
                  { value: 0, text: 'Por data de movimento' },
                ]}
                value={appliedFilters.tipoData}
                displayExpr="text"
                valueExpr="value"
                disabled={searching}
                onValueChange={handleSelectTipoDataChange}
              />
            </div>
          </div>

        </div>
      </FilterPanel>
      {showResult > 0 ? resultGrid() : <></>}
    </LinxPostos>
  );
}


export default withRouter(DocumentosDinamico);
