import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './PedidoDinamicoHyperlocal.module.scss';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { locale, loadMessages } from 'devextreme/localization';
import { Summary, TotalItem, GroupItem } from 'devextreme-react/data-grid';
import ptMessages from '../../../../../assets/js/pt.json';
import { menus } from '../../../../shared/constants/MenuConstants';
import {
  getNomePagina,
  getNomePaginaAmigavel,
  getDadosLogin
} from '../../../../shared/utils/Utils';
import { pedidoDinamicoService } from './PedidoDinamicoHyperlocal.service';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import config from 'devextreme/core/config';
import 'jspdf-autotable';
import ResultPanel from '../../../../components/Report/ResultPanel/ResultPanel';
import DateToolTip from '../../../../components/dateTooltip/DateToolTip';
import ListToolTip from '../../../../components/listToolTip/ListToolTip';
import { renderSummarySumDefault, renderTotalItemSumDefault, renderGroupItemSumDefault, reportMangerService } from '../../../../services/ReportManager.service';
import { NotificationLevel } from '../../../../shared/constants/Const';
import DateTimeBox from '../../../../components/DateTimeBox/DateTimeBox';
import FilterPanel from '../../../../components/Report/FilterPanel/FilterPanel';
import { LoadPanel } from 'devextreme-react';
import { TIPO_SITE } from '../../../../shared/constants/TipoSiteConstant';

export function PedidoDinamicoHyperlocal(props) {
  const PATH_MENU = menus.COD_16197;
  const CodigoFuncionalidade = 80705;
  const gridResultRef = useRef(null);
  const [showSearch, setShowSearch] = useState(false);
  const [disabledSearch, setDisabledSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [startDate] = useState(new Date().toISOString());
  const [endDate] = useState(new Date().toISOString());
  const [filters, setFilters] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [serverReports, setServerReports] = useState([]);
  const [serverSubReports, setSubServerReports] = useState([]);
  const [chosenReport, setChosenReport] = useState([]);
  const [chosenSubReport, setChosenSubReport] = useState([]);
  const [columns, setColumns] = useState([]);
  const [title, setTitle] = useState([]);

  const [appliedFilters, setAppliedFilters] = useState({
    dataInicio: startDate,
    dataFim: endDate,
    empresas: [],
    multiEmpresa: false,
  });

  config({
    defaultCurrency: 'BRL'
  });

  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);
  }, [appliedFilters, startDate, endDate]);

  useEffect(() => {
    const colunas = pedidoDinamicoService.GetColumns(false);
    setColumns(colunas);

    async function GetReports() {
      const result = await reportMangerService.Get(CodigoFuncionalidade);

      if (result.notificationLevel === NotificationLevel.Success) {
        let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
        if (TIPO_SITE === 'RetaguardaEmporioPro')
          reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');

        setServerReports(reports);
      }
    }

    GetReports();
  }, []);

  const find = async () => {
    if (!appliedFilters) {
      notify('Informe ao menos um filtro para pesquisa.', 'error', 2000, 'top');
      return;
    }

    setFilters({
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim,
      multiEmpresa: false,
    });
    await request({
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim,
      multiEmpresa: false,
    });

    updateReportInfo(chosenReport);
  };

  const request = async model => {
    setSearching(true);

    const response = await pedidoDinamicoService.find(model);

    if (response.result == null && response.notifications == null) {
      debugger;
      notify(
          'Falha na requisição.',
          'error',
          7000,
          'top'
      );
      setResultData(null);
    } 
    else if (response.notifications.length > 0) {
      response.notifications.forEach(notification =>{
        notify(
          notification.message,
          'info',
          7000,
          'top'
        );
      });
    } 
    else if (!response.result.length) {
        notify(
          'Não foram encontrados registros para esta pesquisa.',
          'info',
          7000,
          'top'
        );
    } 
    else setResultData(response.result);

    setShowResult(true);
    setSearching(false);
  };

  const subReportHandle = async report => {
    setSearching(true);

    setChosenSubReport(report);

    let model = reportMangerService.FiltersToModel(report.data.filtros);
    let periodCreated = reportMangerService.CreatePeriod(report.data.periodo);

    refreshColumns(report.data.colunas);

    model = {
      ...model,
      dataInicio: periodCreated.dataInicio,
      dataFim: periodCreated.dataFim,
      multiEmpresa: false,
    };

    setFilters(model);

    await request(model);

    updateReportInfo(report);
  };

  const refreshColumns = newColumns => {
    let refreshedColuns = reportMangerService.RefreshColumns(
      columns,
      newColumns
    );
    setColumns(refreshedColuns);
  };

  const reportHandle = report => {
    setChosenReport(report);

    if (report.tipo === 0) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
      subReportHandle(report);
    }
  };

  const onValueChanged = event => {
    if (!moment(event.value).isValid()) {
      setDisabledSearch(true);
      notify('Data inválida.', 'error', 2000, 'top');
    } else {
      setAppliedFilters(filters => {
        return {
          ...filters,
          [event.element.id]:
            event.element.id === 'dataFim'
              ? moment(event.value).format('YYYY-MM-DD') + 'T23:59:59'
              : moment(event.value).format('YYYY-MM-DD') + 'T00:00:00'
        };
      });
      setDisabledSearch(false);
    }
  };

  const changeSelectedEmpresas = (event) => {
    let value, name;

    if (event){
      value = event.selected;
      name = event.name;

      this.setState({ selectedEmpresas: value });
      this.setState({ [name]: value });
    }
  }

  const clearFilters = () => {
    setAppliedFilters({
      dataInicio: new Date().toISOString(),
      dataFim: new Date().toISOString(),
      multiEmpresa: false,
    });
    notify('Todos os filtros aplicados foram removidos.', 'info', 2000, 'top');
  };

  const updateReportInfo = report => {
    setTitle(`RESULTADO - ${report.titulo}`);

    if (report.tipo === 0) {
      setSubServerReports([]);
      report.data = { periodo: { tipo: 0 } };
      report.publico = false;
      setChosenSubReport(report);
      refreshColumns(pedidoDinamicoService.GetColumns(false));
    }
  };

  const refreshReports = async () => {
    const result = await reportMangerService.Get(CodigoFuncionalidade);

    if (result.notificationLevel === NotificationLevel.Success) {
      let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
        if (TIPO_SITE === 'RetaguardaEmporioPro')
          reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');
      setServerReports(reports);

      let report = result.data.find(x => {
        return x.tipo === 1;
      });

      if (report) {
        let sub = report.subReport.find(x => {
          return x.id === chosenReport.id;
        });

        if (sub) {
          setChosenReport(sub);
          subReportHandle(sub);
        } else {
          setShowResult(false);
        }
      }
    }
  };

  const resultGrid = () => {
    return (
      <ResultPanel
        dataSource={resultData}
        columns={columns}
        saveReport={true}
        gridResultRef={gridResultRef}
        codigoRetaguarda={CodigoFuncionalidade}
        fileName="pedidosHyperlocal"
        chosenSubReport={chosenSubReport}
        refreshReports={refreshReports}
        changeChosenSubReport={reportHandle}
        filtroOutros={[
          {
            chave: 0,
            titulo: 'Nenhum Filtro Adicional',
            valor: [], //filters.deposito || [],
            campo: 'Nenhum Filtro Adicional'
          }
        ]}
        disabled={searching}
        panelTitle={title}
        allowExpand={true}
        reportTitle={getNomePaginaAmigavel(PATH_MENU)}
        filters={true}
        summary={() => renderSummarySumDefault(columns)}
      >
        <DateToolTip inicial={filters.dataInicio} final={filters.dataFim} />
        <ListToolTip
          //data={filters.produtos}
          //message={'Produtos selecionados'}
        />

      </ResultPanel>
    );
  };

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU)} id="page">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={searching}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
        closeOnOutsideClick={false}
      />
      <FilterPanel
        onSearch={find}
        clearFilters={clearFilters}
        reports={serverReports}
        searching={searching}
        showSearch={showSearch}
        chosenReport={chosenReport}
        reportHandle={reportHandle}
        subReportHandle={subReportHandle}
        disabledSearch={disabledSearch}
        subReports={serverSubReports}
      >
        <div className="row">
          <div className={`col-2`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>De</span>
                <DateTimeBox
                  type="date"
                  id="dataInicio"
                  date={startDate}
                  disabled={searching}
                  value={appliedFilters.dataInicio}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>
          <div className={`col-2`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>Até</span>
                <DateTimeBox
                  type="date"
                  id="dataFim"
                  date={endDate}
                  disabled={searching}
                  value={appliedFilters.dataFim}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </FilterPanel>
      {showResult > 0 ? resultGrid() : <></>}
    </LinxPostos>
  );
}


export function MatrizPedidoDinamicoHyperlocal(props) {
  const PATH_MENU = menus.COD_22670;
  const CodigoFuncionalidade = 80705;
  const gridResultRef = useRef(null);
  const [showSearch, setShowSearch] = useState(false);
  const [disabledSearch, setDisabledSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [startDate] = useState(new Date().toISOString());
  const [endDate] = useState(new Date().toISOString());
  const [selectedProdutos, setSelectedProdutos] = useState([]);
  const [listaEmpresas, setListaEmpresas] = useState(getDadosLogin().empresas.map(item => {
    return {value: item.cnpj, label: item.nomeFantasia}
  }));
  const [selectedEmpresas, setSelectedEmpresas] = useState([]);
  const [filters, setFilters] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [serverReports, setServerReports] = useState([]);
  const [serverSubReports, setSubServerReports] = useState([]);
  const [chosenReport, setChosenReport] = useState([]);
  const [chosenSubReport, setChosenSubReport] = useState([]);
  const [columns, setColumns] = useState([]);
  const [title, setTitle] = useState([]);

  const [appliedFilters, setAppliedFilters] = useState({
    dataInicio: startDate,
    dataFim: endDate,
    empresas: [],
    MultiEmpresa: true,
  });

  config({
    defaultCurrency: 'BRL'
  });

  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);
  }, [appliedFilters, startDate, endDate]);

  useEffect(() => {
    const colunas = pedidoDinamicoService.GetColumns(true);
    setColumns(colunas);

    async function GetReports() {
      const result = await reportMangerService.Get(CodigoFuncionalidade);

      if (result.notificationLevel === NotificationLevel.Success) {
        let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
        if (TIPO_SITE === 'RetaguardaEmporioPro')
          reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');

        setServerReports(reports);
      }
    }

    GetReports();
  }, []);

  const find = async () => {
    if (!appliedFilters) {
      notify('Informe ao menos um filtro para pesquisa.', 'error', 2000, 'top');
      return;
    }

    setFilters({
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim,
      multiEmpresa: true,
    });
    await request({
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim,
      multiEmpresa: true,
    });

    updateReportInfo(chosenReport);
  };

  const request = async model => {
    setSearching(true);

    const response = await pedidoDinamicoService.find(model);

    if (response.notifications.length > 0) {
      response.notifications.forEach(notification =>{
        notify(
          notification.message,
          'info',
          7000,
          'top'
        );
      });
    } else 
    if (!response.result.length) {
        notify(
          'Não foram encontrados registros para esta pesquisa.',
          'info',
          7000,
          'top'
        );
    } 
    else setResultData(response.result);

    setShowResult(true);
    setSearching(false);
  };

  const subReportHandle = async report => {
    setSearching(true);

    setChosenSubReport(report);

    let model = reportMangerService.FiltersToModel(report.data.filtros);
    let periodCreated = reportMangerService.CreatePeriod(report.data.periodo);

    refreshColumns(report.data.colunas);

    model = {
      ...model,
      dataInicio: periodCreated.dataInicio,
      dataFim: periodCreated.dataFim,
      multiEmpresa: true,
    };

    setFilters(model);

    await request(model);

    updateReportInfo(report);
  };

  const refreshColumns = newColumns => {
    let refreshedColuns = reportMangerService.RefreshColumns(
      columns,
      newColumns
    );
    setColumns(refreshedColuns);
  };

  const reportHandle = report => {
    setChosenReport(report);

    if (report.tipo === 0) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
      subReportHandle(report);
    }
  };

  const onValueChanged = event => {
    if (!moment(event.value).isValid()) {
      setDisabledSearch(true);
      notify('Data inválida.', 'error', 2000, 'top');
    } else {
      setAppliedFilters(filters => {
        return {
          ...filters,
          [event.element.id]:
            event.element.id === 'dataFim'
              ? moment(event.value).format('YYYY-MM-DD') + 'T23:59:59'
              : moment(event.value).format('YYYY-MM-DD') + 'T00:00:00'
        };
      });
      setDisabledSearch(false);
    }
  };

  const changeSelectedEmpresas = (event) => {
    let value, name;

    if (event){
      value = event.selected;
      name = event.name;

      this.setState({ selectedEmpresas: value });
      this.setState({ [name]: value });
    }
  }

  const clearFilters = () => {
    setAppliedFilters({
      dataInicio: new Date().toString(),
      dataFim: new Date().toString(),
      multiEmpresa: true,
    });
    notify('Todos os filtros aplicados foram removidos.', 'info', 2000, 'top');
  };

  const updateReportInfo = report => {
    setTitle(`RESULTADO - ${report.titulo}`);

    if (report.tipo === 0) {
      setSubServerReports([]);
      report.data = { periodo: { tipo: 0 } };
      report.publico = false;
      setChosenSubReport(report);
      refreshColumns(pedidoDinamicoService.GetColumns(true));
    }
  };

  const refreshReports = async () => {
    const result = await reportMangerService.Get(CodigoFuncionalidade);

    if (result.notificationLevel === NotificationLevel.Success) {
      let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
        if (TIPO_SITE === 'RetaguardaEmporioPro')
          reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');
      setServerReports(reports);

      let report = result.data.find(x => {
        return x.tipo === 1;
      });

      if (report) {
        let sub = report.subReport.find(x => {
          return x.id === chosenReport.id;
        });

        if (sub) {
          setChosenReport(sub);
          subReportHandle(sub);
        } else {
          setShowResult(false);
        }
      }
    }
  };

  const resultGrid = () => {
    return (
      <ResultPanel
        dataSource={resultData}
        columns={columns}
        saveReport={true}
        gridResultRef={gridResultRef}
        codigoRetaguarda={CodigoFuncionalidade}
        fileName="pedidosHyperlocal"
        chosenSubReport={chosenSubReport}
        refreshReports={refreshReports}
        changeChosenSubReport={reportHandle}
        filtroOutros={[
          {
            chave: 0,
            titulo: 'Nenhum Filtro Adicional',
            valor: [], //filters.deposito || [],
            campo: 'Nenhum Filtro Adicional'
          }
        ]}
        disabled={searching}
        panelTitle={title}
        allowExpand={true}
        reportTitle={getNomePaginaAmigavel(PATH_MENU)}
        filters={true}
        summary={() => renderSummarySumDefault(columns)}
      >
        <DateToolTip inicial={filters.dataInicio} final={filters.dataFim} />
        <ListToolTip
          //data={filters.produtos}
          //message={'Produtos selecionados'}
        />

      </ResultPanel>
    );
  };

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU)} id="page">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={searching}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
        closeOnOutsideClick={false}
      />
      <FilterPanel
        onSearch={find}
        clearFilters={clearFilters}
        reports={serverReports}
        searching={searching}
        showSearch={showSearch}
        chosenReport={chosenReport}
        reportHandle={reportHandle}
        subReportHandle={subReportHandle}
        disabledSearch={disabledSearch}
        subReports={serverSubReports}
      >
        <div className="row">
          <div className={`col-2`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>De</span>
                <DateTimeBox
                  type="date"
                  id="dataInicio"
                  date={startDate}
                  disabled={searching}
                  value={appliedFilters.dataInicio}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>
          <div className={`col-2`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>Até</span>
                <DateTimeBox
                  type="date"
                  id="dataFim"
                  date={endDate}
                  disabled={searching}
                  value={appliedFilters.dataFim}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className={`${styles.input} col-12`}>
            <Select multi
              label="Empresas"
              name="empresasSelecionadas"
              options={listaEmpresas}
              sender={this}
              onBlur={setSelectedEmpresas()}
            />
          </div>
        </div> */}
      </FilterPanel>
      {showResult > 0 ? resultGrid() : <></>}
    </LinxPostos>
  );
}

export default withRouter(PedidoDinamicoHyperlocal);
