import 'devextreme/dist/css/dx.light.css';
import { alert } from 'devextreme/ui/dialog';
import 'jspdf-autotable';
import React, { useCallback, useEffect, useRef } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { InputCurrency } from '../../../../components/inputCurrency/inputCurrency';
import { InputText } from '../../../../components/inputText/inputText';
import { selectNovaOs, useNovaOs } from '../../../../redux/slices/consignadoComodato/novaOsSlice';
import { selectConsignadoComodatoSharedData } from '../../../../redux/slices/consignadoComodato/SharedData.slice';
import { normalizeToBoolean } from '../../../../shared/utils/Utils';
import DropBoxBandeiras from '../components/drop-box-bandeiras/dropBoxBandeiras';
import DropBoxCentroMonetarios from '../components/drop-box-centro-monetario/dropBoxCentroMonetarios';
import DropBoxPagamentos from '../components/drop-box-pagamentos/dropBoxPagamentos';
import DropBoxProdutoRede from '../components/drop-box-produto-rede/dropBoxProdutoRede';
import DropBoxRedes from '../components/drop-box-redes/dropBoxRedes';
import DropBoxRegrasParcelamento from '../components/drop-box-regras-parcelamentos/dropBoxRegrasParcelamento';
import { CalcularValorPendentePedidoService } from '../usercases/CalcularValorPendentePedido.service';
import { OsSubmitService } from '../usercases/osSubmit.service';
import { ApiAdicionarPagamentoService } from './../usercases/apiAdicionarPagamento.service';
import styles from './pagamento.module.scss';
import { PagamentosLista } from './pagamentos-lista';
import { PagamentoVencimento } from './pagamentos-vencimento';

import Swal from 'sweetalert2';


export function Pagamento({ pedido, idx, setSearching, setMessageLoadin }) {
  const [newPayment, setNewPayment] = React.useState({});
  const [payment, setPayment] = React.useState({});
  const [currentRedeCod, setCurrentRedeCode] = React.useState(null);
  const [currentBandCod, setCurrentBandCode] = React.useState(null);
  const [currentProdutoRede, setCurrentProdutoRede] = React.useState(null);
  const [aPagar, setAPagar] = React.useState(0);
  const dispatch = useDispatch();
  const { changeValuePedidos, adicionarPagamento, reload } = useNovaOs();
  const { values } = useSelector(selectNovaOs);
  const [today] = React.useState(new Date());
  const [paramUtilizaCreditoParcelado, setParamUtilizaCreditoParcelado] = React.useState(null);
  const refValorPagamento = useRef();
  const refPagamento = useRef();
  const refCentroMonetario = useRef();
  const refBandeiras = useRef();
  const refRedes = useRef();
  const refProdutosRede = useRef();
  const refNsu = useRef();
  const refRegraParcelamento = useRef();
  const [isBoleto, setIsBoleto] = React.useState(false);

  const formatMoneyToString = money => `R$ ${money.toFixed(2).replace('.', ',')}`;
  const parametro604 = useSelector(selectConsignadoComodatoSharedData).parametros604;

  //console.log('pedido', pedido);

  useEffect(() => {
    if (paramUtilizaCreditoParcelado !== null) return;
    if (!parametro604) return;
    // console.log('parametro604', parametro604.valor);
    // console.log('parametro604', normalizeToBoolean(parametro604.valor));
    setParamUtilizaCreditoParcelado(normalizeToBoolean(parametro604.valor));
  }, [paramUtilizaCreditoParcelado, parametro604]);

  var onSelectedPayment = useCallback(

    data => {
      if (!data) {
        setPayment(null);
        return;
      }
      if (!values) return;
      if (!refValorPagamento.current) return;
      const pendente = CalcularValorPendentePedidoService.getInstance().execute(pedido, values);
      setAPagar(pendente);
      refValorPagamento.current.setNewValueAndSelect(pendente);
      refValorPagamento.current.setFocus();
      if (data.tipoFormaPagamento.id === 15) setIsBoleto(true);
      else setIsBoleto(false);

      setPayment(data);
    },
    [setAPagar, pedido, values],
  );

  const submitPayment = useCallback(async () => {
    let regraParcelamento = null;
    let pagamento = refPagamento.current.getPayment() || null;
    var centro_monetario = null;

    const money = refValorPagamento.current.getMoney();
    //venda de retorno vai estar confirmado o pedido, então não se aplica a regra, pois o pagamento será informado 'agora'
    //achar onde tem o status desse pedido para alterar
    if ((values.status || {}).id !== 1 && pedido.tipoPedido.id !== 6) {
      alert('Apenas Pedidos em aberto podem receber pagamentos', 'Ops!');
      return;
    }
    if (!pagamento) {
      alert('Favor selecionar uma Forma de Pagamento', 'Ops!');
      return;
    }
    if (!newPayment.valorPagamento) {
      alert('Favor preencher o valor do pagamento ' + formatMoneyToString(money), 'Ops!').then(() => {
        if (refValorPagamento.current) {
          refValorPagamento.current.setFocus();
        }
      });
      return;
    }
    if(['-4'].includes((pagamento || {}).id) && !values.cliente.utilizaFormaPagamentoCredito){
      Swal.fire({
        title: 'Ops!',
        text: `O cliente [${values.cliente.nome}] não pode utilizar a forma de pagamento Crédito.`,
        icon: 'warning',
        confirmButtonText: 'OK',
      });

      return;
    }
    if(['-15'].includes((pagamento || {}).id) && !values.cliente.utilizaFormaPagamentoBoleto){
      Swal.fire({
        title: 'Ops!',
        text: `O cliente ${values.cliente.nome} não pode utilizar a forma de pagamento Boleto.`,
        icon: 'warning',
        confirmButtonText: 'OK',
      });

      return;
    }
    if (['-4', '-7'].includes((pagamento || {}).id)) {
      if (!refBandeiras.current.getBandeira()) {
        alert('Selecione uma Bandeira.', 'Ops!');
        return;
      } else {
        newPayment.info = { ...newPayment.info, bandCod: refBandeiras.current.getBandeira().id, bandNome: refBandeiras.current.getBandeira().descricao };
      }
      if (!refRedes.current.getRede()) {
        alert('Selecione uma Rede.', 'Ops!');
        return;
      } else newPayment.info = { ...newPayment.info, redeCod: refRedes.current.getRede().id, redeNome: refRedes.current.getRede().descricao };

      if (!refProdutosRede.current.getProdutoRede()) {
        alert('Selecione um Produto da Rede.', 'Ops!');
        return;
      } else {
        newPayment.info = {
          ...newPayment.info,
          prReCod: refProdutosRede.current.getProdutoRede().id,
          prReNome: refProdutosRede.current.getProdutoRede().descricao,
        };
      }

      if (refNsu.current.getValue()) {
        newPayment.info = { ...newPayment.info, nsu: refNsu.current.getValue() || null };
      }
      // se chegou aqui, passou das validações de preenchimento bandeira/rede/produto
      // e é tipo forma crédito

      if (['-4'].includes((pagamento || {}).id) && paramUtilizaCreditoParcelado) {
        regraParcelamento = refRegraParcelamento.current.getRegraParcelamento();
        if ((values.status || {}).id !== -1) {

          newPayment.juros = regraParcelamento.juros
          newPayment.numeroParcelas = regraParcelamento.qtParcelas
          newPayment.regraParcelamento = {
            regraParcelamentoId: regraParcelamento.regraParcelamentoId,
            Parcelas: regraParcelamento.qtParcelas,
          };
          newPayment.valorPagamento = regraParcelamento.valorComJuros;
        }
      }
    }

    if ((pagamento || {}).id === '-15' && !newPayment.dataVencimento) {
      alert('Informe uma data de vencimento.', 'Ops!');
      return;
    }

    if (pagamento.id === '-15' && new Date(newPayment.dataVencimento) <= today) {
      alert('Data de vencimento não pode ser menor ou igual a hoje.', 'Ops!');
      return;
    }

    if (pagamento.id === '-15' || pagamento.tipoFormaPagamento.id === 17) {
      centro_monetario = refCentroMonetario.current.getCentroMonetario() || null;
      if (!centro_monetario) {
        alert('Você precisa selecionar um Banco.', 'Ops!');
        return;
      }
    }
    let pendente = CalcularValorPendentePedidoService.getInstance().execute(pedido, values);
    const valorJuros = (newPayment || {}).juros || 0;
    if ((Math.round((newPayment.valorPagamento - valorJuros) * 100) / 100) > (Math.round(pendente* 100) / 100)) {
      alert('O valor informado excede o valor pendente', 'Ops!').then(() => {
        refValorPagamento.current.setFocus();
      });
      return;
    }

    if ((values || {}).id !== -1) {
      try {
        newPayment.uid = v4();

        const body = {
          pagamentoId: pagamento.id,
          valorPagamento: newPayment.valorPagamento,
          osId: values.osId,
          pedidoId: pedido.pedidoId,
          dataVencimento: newPayment.dataVencimento ? newPayment.dataVencimento.toISOString() : null,
          ceMoCod: (centro_monetario || {}).id || null,
          ceMoValOutrasCobrancas: (centro_monetario || {}).outrasCobrancas || 0.0,
        };
        if (['-4', '-7'].includes((pagamento || {}).id)) {
          body.info = {};
          body.info.bandCod = newPayment.info.bandCod;
          body.info.bandNome = newPayment.info.bandNome;
          body.info.redeCod = newPayment.info.redeCod;
          body.info.redeNome = newPayment.info.redeNome;
          body.info.prReCod = newPayment.info.prReCod;
          body.info.prReNome = newPayment.info.prReNome;
          body.info.nsu = newPayment.info.nsu;
        }
        if (['-4'].includes((pagamento || {}).id) && paramUtilizaCreditoParcelado && regraParcelamento) {

          if(regraParcelamento.juros > 0)
          {
            await Swal.fire({
              title: 'Informação',
              html: "O valor do pedido foi atualizado, para contabilizar o valor dos juros de parcelamento. </br></br>**A primeira parcela terá, o valor em centavos da diferença de arredondamento, quando necessário.",
              icon: 'info',
              confirmButtonText: 'OK',
              timerProgressBar: true,
              timer: 6000,
            });
          }

          body.valorPagamento = regraParcelamento.valorComJuros;
          body.juros = regraParcelamento.juros;
          body.regraParcelamento = {
            regraParcelamentoId: regraParcelamento.regraParcelamentoId,
            Parcelas: regraParcelamento.qtParcelas,
          };
        }
        setMessageLoadin('Adicionando pagamento...');
        setSearching(true);
        const service = new OsSubmitService();
        await service.execute(values);
        const savedOs = await new ApiAdicionarPagamentoService().execute(body);
        dispatch(reload(savedOs));
        setSearching(false);
      } catch (ex) {
        setSearching(false);
        alert(ex.message, 'ERRO!').then(() => {
          if (!refValorPagamento.current) {
            return;
          }
          refValorPagamento.current.setFocus();
        });
        return;
      }
    } else {
      if (['-4'].includes((pagamento || {}).id) && paramUtilizaCreditoParcelado && regraParcelamento) {
        const acrescimoDigitado = (pedido || {}).acrescimo || 0;
        const acrescimo = acrescimoDigitado + regraParcelamento.juros;

        if(regraParcelamento.juros > 0)
          {
            await Swal.fire({
              title: 'Informação',
              html: "O valor do pedido foi atualizado, para contabilizar o valor dos juros de parcelamento. </br></br>**A primeira parcela terá, o valor em centavos da diferença de arredondamento, quando necessário.",
              icon: 'info',
              confirmButtonText: 'OK',
              timerProgressBar: true,
              timer: 5000,
            });
          }
        dispatch(
          changeValuePedidos({
            pedido: { ...pedido, acrescimo: acrescimo },
          }),
        );

        newPayment.parcelas = [{
          numeroParcela: regraParcelamento.qtParcelas,
          valorParcela: regraParcelamento.valorParcela,
          taxa: regraParcelamento.taxa,
        }];
      }
      dispatch(adicionarPagamento({ payment: { ...newPayment, pagamento }, pedidoId: pedido.pedidoId, centro_monetario: centro_monetario }));
    }
    setNewPayment({});
    refPagamento.current.clear();
  }, [dispatch, adicionarPagamento, newPayment, values, setMessageLoadin, setSearching, pedido, today, reload, changeValuePedidos, paramUtilizaCreditoParcelado]);

  return (
    <Card className="mb-3">
      <Card.Header>Formas de Pagamento ({pedido.tipoPedido.nome})</Card.Header>
      <Card.Body>
        <Row className="mb-0">
          <Col lg={6} className="form-group">
            <Form.Label className="text-primary">
              Acréscimo<span className="text-danger">*</span>
            </Form.Label>
            <InputCurrency
              disabled={(pedido.pagamentos && pedido.pagamentos.length > 0) }
              onChange={(_, money) => {
                dispatch(
                  changeValuePedidos({
                    pedido: { ...pedido, acrescimo: money },
                  }),
                );
              }}
              value={pedido.acrescimo || 0}
              className="form-control text-right"
              name="valorFrete"
              placeholder="R$ 0,00"
            />
          </Col>
          <Col lg={6} className="form-group">
            <Form.Label className="text-primary">
              Desconto<span className="text-danger">*</span>
            </Form.Label>
            <InputCurrency
              onChange={(_, money) => {
                dispatch(
                  changeValuePedidos({
                    pedido: { ...pedido, desconto: money },
                  }),
                );
              }}
              value={pedido.desconto || 0}
              className="form-control text-right"
              name="valorFrete"
              disabled={(pedido.pagamentos && pedido.pagamentos.length > 0) || pedido.vtexOrderId}
              placeholder="R$ 0,00"
            />
          </Col>
          <Col lg={6} className="form-group">
            <DropBoxPagamentos ref={refPagamento} onSelected={onSelectedPayment} />
          </Col>
          <Col lg={6} className="form-group mb-3">
            <Form.Label className="text-primary">
              Valor<span className="text-danger">*</span>
            </Form.Label>
            <InputGroup>
              <InputCurrency
                ref={refValorPagamento}
                aPagar={aPagar}
                onChange={(_, money) => {
                  setNewPayment({ ...newPayment, valorPagamento: money });
                }}
                value={newPayment.valorPagamento || 0}
                className="form-control text-right"
                name="valorFrete"
                placeholder="R$ 0,00"
              />
              <Button variant="primary" className={`${styles.buttonReceber}`} onClick={submitPayment}>
                <i className="dx-icon dx-icon-add"></i>
              </Button>
            </InputGroup>
          </Col>
          {payment && payment.id && payment.id !== '-15' && (
            <>
              <Col lg={6} className="form-group mb-3">
                <PagamentoVencimento
                  startWithCurrentDate={true}
                  ignoreParameter={true}
                  allowRetro={true}
                  setNewPayment={setNewPayment}
                  newPayment={newPayment}
                ></PagamentoVencimento>
              </Col>
            </>
          )}
          {((payment || {}).id === '-15' || ((payment || {}).tipoFormaPagamento || {}).id === 17) && (
            <>
              {((payment || {}).tipoFormaPagamento || {}).id !== 17 && (
                <Col lg={6} className="form-group mb-3">
                  <PagamentoVencimento setNewPayment={setNewPayment} newPayment={newPayment} isBoleto={isBoleto}></PagamentoVencimento>
                </Col>
              )}
              <Col lg={6} className="form-group">
                <DropBoxCentroMonetarios ref={refCentroMonetario} isBoleto={isBoleto} />
              </Col>
            </>
          )}
          {['-4', '-7'].includes((payment || {}).id) && (
            <>
              <Col lg={6} className="form-group p-0 pr-3">
                <Form.Label htmlFor="nsu" className="text-primary">
                  NSU
                </Form.Label>
                <InputText ref={refNsu} className="form-control mt-1" name="nsu" placeholder="000" />
              </Col>
              <Col lg={4} className="form-group m-0 pr-0">
                <DropBoxRedes
                  ref={refRedes}
                  onSelected={val => {
                    if (!val && val !== 0) setCurrentRedeCode(null);
                    else setCurrentRedeCode(val.id);
                  }}
                />
              </Col>
              <Col lg={4} className="form-group p-0 p-0">
                <DropBoxBandeiras ref={refBandeiras} setCurrentBandCode={setCurrentBandCode} />
              </Col>
              <Col lg={4} className="form-group p-0 m-0 pr-3">
                <DropBoxProdutoRede
                  ref={refProdutosRede}
                  redeCod={currentRedeCod}
                  onSelected={val => {
                    if (!val && val !== 0) {
                      setCurrentProdutoRede(null);
                    } else {
                      setCurrentProdutoRede(val.id !== undefined ? val.id : val);
                    }
                  }}
                />
              </Col>
              {['-4'].includes((payment || {}).id) && paramUtilizaCreditoParcelado && (
                <Col lg={12} className="form-group p-0 m-0 pl-3 mb-3">
                  <DropBoxRegrasParcelamento
                    ref={refRegraParcelamento}
                    redeCod={currentRedeCod}
                    bandCod={currentBandCod}
                    produtoRede={currentProdutoRede}
                    valorPagamento={newPayment.valorPagamento}
                  />
                </Col>
              )}
            </>
          )}
          {payment && payment.id && (['-15', '-7', '-4'].includes(payment.id) || payment.tipoFormaPagamento.id === 17) && <Col lg={6} className="form-group p-0 p-0"></Col>}
          {payment && payment.id && (
            <Col lg={6} className="form-group" style={{ marginTop: 'auto', flexDirection: 'column' }}>
              <Button variant="primary" className="btn btn-primary w-100" onClick={submitPayment}>
                <i className="dx-icon dx-icon-add"></i> Adicionar
              </Button>
            </Col>
          )}
          <Col lg={12} className="form-group">
            <PagamentosLista pedido={pedido} idx={idx} setSearching={setSearching} setMessageLoadin={setMessageLoadin} />
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <Card.Title>
          <span className="text-primary">Pendente recebimento</span>
          <span className="text-primary float-right">
            {(CalcularValorPendentePedidoService.getInstance().execute(pedido, values) || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
          </span>
        </Card.Title>
      </Card.Footer>
    </Card>
  );
}
