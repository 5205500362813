import React, { useCallback, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectConsignadoComodatoSharedData } from '../../../../../redux/slices/consignadoComodato/SharedData.slice';
import { selectNovaOs, useNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';
import Swal from 'sweetalert2';
import { ApiVincularVendedorComissionamento } from '../../../../../services/con-comodato/ApiVincularVendedorComissionamento.service';

export function DropBoxVendedor() {
  const { changeValue, changeBlur } = useNovaOs();
  const { values } = useSelector(selectNovaOs);
  const vends = useSelector(selectConsignadoComodatoSharedData).vendedores || [];
  //insere a opção default, junto do array de vendedores vindo da lista do services
  const vendedores = [{ id: -1, nome: 'SELECIONE UM VENDEDOR' }, ...vends];

  const dispatch = useDispatch();
  //fica observando para quando mudar o cliente, seta o vendedor do cliente
  //se o cliente não tem vendedor vinculado, posiciona no -1 "Selecione"
  //e habilita o botão de vincular
  useEffect(() => {
    if (((values.cliente || {}).vendedor || {}).id) {
      dispatch(changeValue({ fieldName: 'vendedor', value: { id: ((values.cliente || {}).vendedor || {}).id || -1 } }));
      console.log('values.cliente', (values.cliente || {}).id);
    }
  }, [dispatch, values.cliente, changeValue]);
  
  //sempre que mudar o vendedor pelo combobox
  //seta o vendedor no state do comodato
  const handlerVendedor = useCallback(
    e => {
      const it = vendedores.find(x => x.id === parseInt(e.target.value));
      //ao vincular o vendedor ao cadastro do cliente, então também seta o vendedor no state do comodato
      //como sendo o vendedor vindo do cliente, que na verdade foi atualizado depois
      dispatch(changeValue({ fieldName: 'vendedor', value: { id: it.id, nome: it.nome } }));
    },
    [dispatch, changeValue, vendedores],
  );

  const handleVincular = useCallback(async e => {
    try {
      e.preventDefault();
      const clientId = (values.cliente || {}).id;
      const vendedorId = (values.vendedor || {}).id;
      if (!clientId || !vendedorId) {
        Swal.fire('Erro', 'Selecione um cliente e um vendedor para vincular!', 'error');
        return;
      }
      const result = await Swal.fire({
        title: 'Atenção!',
        text: 'Confirma vincular o vendedor ao cadastro do cliente?',
        icon: 'info',
        timer: 5000,
        confirmButtonText: 'Sim, continuar!',
        cancelButtonText: 'Não, cancelar!',
        showCancelButton: true,
      });

      if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }
      await ApiVincularVendedorComissionamento.getInstance().execute(values.cliente.id, values.vendedor.id);
      //seta o vendedor do cliente no state do comodato
      dispatch(changeValue({ fieldName: 'cliente', value: { ...values.cliente, vendedor: values.vendedor } }));

    } catch (error) {
      Swal.fire('Erro', error.message, 'error');
    }
  }, [dispatch, changeValue, values.cliente, values.vendedor]);

  return (
    //a obrigatoriedade do vendedor, parametro 635, é tratada no servcices inserir/editar do comodato
    <div className="form-group">
      <Form.Label htmlFor="vendedor" className="text-primary">
        {'Vendedor Comissionamento'}
      </Form.Label>
      <div className="d-flex">
        <select
          onBlur={e => dispatch(changeBlur({ fieldName: 'vendedor' }))}
          onChange={handlerVendedor}
          //se o vendedor do cliente é igual ao vendedor selecionado, desabilita o combobox
          //ou quando o Comodato tem algum pedido com ecfCod = -1, seginifica que algum pedido já foi faturado
          //validei id do cliente e vendedor para habilitar o botão de vincular
          //(Cenário novo pedido) precisei validar ide ser diferente de undefined, pois (undefined com undefined dava true) e desabilitava no caso do cliente não ter vendedor e nem o pedido ainda o ter
          disabled={
            (
              (((values.cliente || {}).vendedor || {}).id === (values.vendedor || {}).id) &&
              ((values.cliente || {}).vendedor || {}).id !== undefined && (values.vendedor || {}).id !== undefined
            ) 
            ||
            (values.pedidos || []).find(x => x.ecfCod === -1)
          }
          value={((values || {}).vendedor || {}).id || -1}
          id="vendedor"
          name="vendedor"
          className="form-control"
        >
          {(vendedores || []).map((vendedor, index) => (
            <option key={index} value={vendedor.id}>
              {vendedor.nome}
            </option>
          ))}
        </select>
        {values.cliente && !values.cliente.vendedor && (
          //se o cliente não tem vendedor vinculado, então EXIBE o botão de vincular
          //se o cliente tem vendedor vinculado, então NÃO EXIBE o botão de vincular
          //só habilitar o botão vincular se o cliente e vendedor estiverem selecionados para dar o submit
          //diferentes de undefined e diferente da opção -1 "Selecione"
          <button className="btn btn-primary" onClick={handleVincular} disabled={(((values.cliente || {}).id === undefined || (values.cliente || {}).id ===-1 ) || ((values.vendedor || {}).id === undefined || (values.vendedor || {}).id === -1))} title={'Vincular vendedor ao cadastro do cliente'}>
            <i className="fa fa-link"></i>
          </button>
        )}
      </div>
    </div>
  );
}
