import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { PrivateRoute } from './components/core/PrivateRoute';

import Login from './pages/core/login/Login';
import Loader from './components/core/loader/Loader';
import Pessoas from './pages/cadastro/pessoas/Pessoas';
import Ajuda from './pages/core/ajuda/Ajuda';
import Dashboard from './pages/dashboard/contas-receber/ContasReceber';
import Usuarios from './pages/cadastro/usuarios/Usuarios';
import { Empresas } from './pages/configuracoes/empresas/Empresas';
import { Deposito } from './pages/estoque/depositos/Deposito';
import { Produtos } from './pages/cadastro/produtos/Produtos';
import { Funcionarios } from './pages/cadastro/funcionarios/Funcionarios';
import { GerenciamentoFinanceiro } from './pages/financeiro/gerenciamento-financeiro/GerenciamentoFinanceiro';
import { RelatorioFaturamentoVendaPrazo } from './pages/financeiro/faturamento-vendas-prazo/RelatorioFaturamentoVendaPrazo';
import { Cashflow } from './pages/financeiro/fluxo-caixa/Cashflow';
import { ConsultaFinanceiro } from './pages/relatorios/financeiros/consulta-financeira/ConsultaFinanceiro';
import { ConsultaVendas } from './pages/relatorios/financeiros/consulta-vendas/ConsultaVendas';
import { CargaInicialImportacao } from './pages/configuracoes/carga-inicial/CargaInicialImportacao';
import { CargaInicialHub, Modo } from './pages/configuracoes/carga-inicial/CargaInicialHub';
import ConferenciaCaixaFiltros from './pages/financeiro/conferencia-caixa/conferencia-caixa-filtros/ConferenciaCaixaFiltros';
import { CSTCFOP } from './pages/fiscal/cst-cfop/CSTCFOP';
import DadosAdicionaisDocumentoFiscalCadastro from './pages/fiscal/dados-adicionais-documento-fiscal/dados-adicionais-cadastro/DadosAdicionaisCadastro';
import DadosAdicionaisDocumentoFiscalResultado from './pages/fiscal/dados-adicionais-documento-fiscal/dados-adicionais-result/DadosAdicionaisResult';
import DadosAdicionaisDocumentoFiscalFiltros from './pages/fiscal/dados-adicionais-documento-fiscal/dados-adicionais-filtros/DadosAdicionaisDocumentoFiscalFiltros';
import { Bicos } from './pages/cadastro/tanques-bombas-bicos/bicos/Bicos';
import { Bombas } from './pages/cadastro/tanques-bombas-bicos/bombas/Bombas';
import { Tanques } from './pages/cadastro/tanques-bombas-bicos/tanques/Tanques';
import { MapaDePista } from './pages/cadastro/tanques-bombas-bicos/MapaDePista';
import { Maquinas } from './pages/cadastro/maquinas-perifericos/Maquinas';
import { ConfiguracaoCFOP } from './pages/configuracoes/cfop-pis-cofins/ConfiguracaoCFOP.jsx';
import { CFOPPorOperacao } from './pages/configuracoes/cfop-operacao/CFOPPorOperacao.jsx';
import { ListaPreco } from './pages/cadastro/lista-preco/ListaPreco';
import { Tributacao } from './pages/fiscal/tributacao/Tributacao.jsx';
import { SpedContribuicoes } from './pages/fiscal/sped-contribuicoes/SpedContribuicoes';
import { SpedFiscal } from './pages/fiscal/sped-fiscal/SpedFiscal';
import { ApuracaoICMSST } from './pages/fiscal/apuracao-icms-st/ApuracaoICMSST';
import { ApuracaoICMS } from './pages/fiscal/apuracao-icms/ApuracaoICMS';
import { NotaFiscal } from './pages/fiscal/nota-fiscal/NotaFiscal';
import { MonitoramentoNfe } from './pages/fiscal/monitoramento-nfe/MonitoramentoNfe';
import { ManifestacaoDestNfe } from './pages/fiscal/manifestacao-destinatario/ManifestacaoDestNfe';
import { InutilizacaoNfe } from './pages/fiscal/inutilizacao-nfe/InutilizacaoNfe';
import { ImportacaoIbpt } from './pages/fiscal/importacao-ibpt/importacaoibpt';
import MedicaoTanques from './pages/estoque/medicao-tanque/MedicaoTanques';
import { ConfiguracoesGerais } from './pages/configuracoes/configuracoes-gerais/ConfiguracoesGerais';
import { LMC } from './pages/fiscal/LMC/LMC.jsx';
import { Impostos } from './pages/fiscal/impostos/Impostos';
import { DashboardPayHub } from './pages/dashboard/cartoes/DashboardPayHub.jsx';
import { AjusteEstoque } from './pages/estoque/ajuste-estoque/AjusteEstoque';
import { Contagem } from './pages/estoque/contagem/Contagem.jsx';
import GerenciamentoFinanceiroReport from './pages/relatorios/financeiros/gerenciamento-financeiro/GerenciamentoFinanceiro';
import NotasFiscaisReport from './pages/relatorios/fiscal/notas-fiscais/NotasFiscais';
import { EstruturaMercadologica } from './pages/cadastro/estrutura-mercadologica/EstruturaMercadologica';
import { FormasPagamento } from './pages/cadastro/formas-pagamento/FormasPagamento';
import { GrupoPessoas } from './pages/cadastro/grupo-pessoas/GrupoPessoas';
import { RegrasTroco } from './pages/cadastro/regras-troco/RegrasTroco';
import { BoasVindas } from './pages/core/boas-vindas/BoasVindas';
import { EsqueceuSenha } from './pages/core/esqueceu-senha/EsqueceuSenha';
import { PerfilAcesso } from './pages/configuracoes/perfil-acesso/PerfilAcesso.jsx';
import { CentrosMonetarios } from './pages/configuracoes/centros-monetarios/CentrosMonetarios';
import { EstruturaCartoes } from './pages/seller/EstruturaCartoes';
import POSMFeDetails from './pages/configuracoes/pos-mfe/pos-mfe-details/POSMFeDetails';
import POSMFe from './pages/configuracoes/pos-mfe/POSMFe';
import MovimentacaoEstoqueReport from './pages/relatorios/estoque/movimentacao-estoque/MovimentacaoEstoque';
import { FortesFiscal } from './pages/exportacao/fortes-fiscal/FortesFiscal';
import { MargemMarkup } from './pages/relatorios/financeiros/margem-markup/MargemMarkup';
import { MovimentacaoFinanceiraEstoque } from './pages/relatorios/financeiros/movimentacao-financeira-estoque/MovimentacaoFinanceiraEstoque';
import { ResultadoOperacional } from './pages/relatorios/financeiros/Resultado-operacional/ResultadoOperacional';
import { CurvaABC } from './pages/relatorios/vendas/curva-abc/CurvaABC';
import { ComprasDocumentoFiscal } from './pages/relatorios/auditoria/compras-documento-fiscal/ComprasDocumentoFiscal';
import { RaioX } from './pages/relatorios/raio-x/RaioX';
import { FechamentoCaixa } from './pages/relatorios/financeiros/fechamento-caixa/FechamentoCaixa';
import RequisicaoLGPD from './pages/cadastro/requisicao-lgpd/RequisicaoLGPD';
import RequisicaoLGPDDetails from './pages/cadastro/requisicao-lgpd/RequisicaoLGPD-details/RequisicaoLGPDDetails';

import { ManterAlmoxarifado } from './pages/seller/ManterAlmoxarifado';
import { ManterProdProduto } from './pages/seller/ManterProdProduto';
import { ManterGerArquivoRemessa } from './pages/seller/ManterGerArquivoRemessa';
import { ManterGerArquivoRetorno } from './pages/seller/ManterGerArquivoRetorno';
import { GeraArqSpedF } from './pages/seller/GeraArqSpedF';
import { Geracao_EFD } from './pages/seller/Geracao_EFD';
import { ManterConfiguracoesGerais } from './pages/seller/ManterConfiguracoesGerais';
import { FeriadosLocais } from './pages/seller/FeriadosLocais';
import { FeriadosNacionais } from './pages/seller/FeriadosNacionais';
import { ManterFunFuncionario } from './pages/seller/ManterFunFuncionario';
import { ManterPerfis } from './pages/seller/ManterPerfis';
import { Seller8_Feed } from './pages/seller/Seller8_Feed';
import { ManterVisoes } from './pages/seller/ManterVisoes';
import { ManterCliCliente } from './pages/seller/ManterCliCliente';
import { ManterAgrupadorCliente } from './pages/seller/ManterAgrupadorCliente';
import { ManterEstabelecimento } from './pages/seller/ManterEstabelecimento';
import { ManterConfSistema } from './pages/seller/ManterConfSistema';
import { ManterFabFabricantes } from './pages/seller/ManterFabFabricantes';
import { ManterFaixaComissionamento } from './pages/seller/ManterFaixaComissionamento';
import { ManterForFornecedor } from './pages/seller/ManterForFornecedor';
import { ManterCadJustificativas } from './pages/seller/ManterCadJustificativas';
import { ManterMaquina } from './pages/seller/ManterMaquina';
import { ManterPeriferico } from './pages/seller/ManterPeriferico';
import { ManterTransportadora } from './pages/seller/ManterTransportadora';
import { ManterTurnos } from './pages/seller/ManterTurnos';
import { ManterCadCartaoComanda } from './pages/seller/ManterCadCartaoComanda';
import { LoteCartaoComanda } from './pages/seller/LoteCartaoComanda';
import { ManterRegrasImpressao } from './pages/seller/ManterRegrasImpressao';
import { ManterBancos } from './pages/seller/ManterBancos';
import { ManterCentroMonetario } from './pages/seller/ManterCentroMonetario';
import { ManterConta } from './pages/seller/ManterConta';
import { ManterFormaPagamento } from './pages/seller/ManterFormaPagamento';
import { ManterFormaPagamentoTroco } from './pages/seller/ManterFormaPagamentoTroco';
import { ManterEstruturaMercadologica } from './pages/seller/ManterEstruturaMercadologica';
import { ManterListaPreco } from './pages/seller/ManterListaPreco';
import { ManterKitComposto } from './pages/seller/ManterKitComposto';
import { ManterCadNCM } from './pages/seller/ManterCadNCM';
import { Seller8_GestaoPendencias } from './pages/seller/Seller8_GestaoPendencias';
import { ManterAgrupador } from './pages/seller/ManterAgrupador';
import { ManterAidf } from './pages/seller/ManterAidf';
import { ConfiguracaoCFOPVendas } from './pages/seller/ConfiguracaoCFOPVendas';
import { ManterCFOPxTrib } from './pages/seller/ManterCFOPxTrib';
import { ManterCSTxCFOP } from './pages/seller/ManterCSTxCFOP';
import { ManterConfDeclarante } from './pages/seller/ManterConfDeclarante';
import { ManterDadosAdicNF } from './pages/seller/ManterDadosAdicNF';
import { ManterConfiguracoesPisCofins } from './pages/seller/ManterConfiguracoesPisCofins';
import { ManterCadGrupoImpostos } from './pages/seller/ManterCadGrupoImpostos';
import { ManterCadAliquotas } from './pages/seller/ManterCadAliquotas';
import { ManterCadObservacoes } from './pages/seller/ManterCadObservacoes';
import { ConsultaVendasApuracaoImpostos } from './pages/seller/ConsultaVendasApuracaoImpostos';
import { ConsultaHistAtendimentos } from './pages/seller/ConsultaHistAtendimentos';
import { ConsultaVendasComissao } from './pages/seller/ConsultaVendasComissao';
import { ConsultaVendasCurvaABC } from './pages/seller/ConsultaVendasCurvaABC';
import { Seller8_VendasCurvaABC, Seller8_MatrizVendasCurvaABC } from './pages/seller/Seller8_VendasCurvaABC';
import { ConsultaVendasDiarias } from './pages/seller/ConsultaVendasDiarias';
import { ConsultaEvolucaoMensalVendas } from './pages/seller/ConsultaEvolucaoMensalVendas';
import { ConsultaVendasKit } from './pages/seller/ConsultaVendasKit';
import { ConsultaVendasPorAliquota } from './pages/seller/ConsultaVendasPorAliquota';
import { ConsultaVendasClientePlaca } from './pages/seller/ConsultaVendasClientePlaca';
import { ConsultaVendasOperador } from './pages/seller/ConsultaVendasOperador';
import { VendasRealizadasConsulta, MatrizVendasRealizadasConsulta } from './pages/seller/VendasRealizadasConsulta';
import { ConsultaVendasPorHoraDia } from './pages/seller/ConsultaVendasPorHoraDia';
import { ConsultaDocFiscFaixaVal, MatrizConsultaDocFiscFaixaVal } from './pages/seller/ConsultaDocFiscFaixaVal';
import { MatrizProdutosAjustePreco } from './pages/seller/Seller8_ProdutosAjustePreco';
import { ConsultaMapaResumoECF } from './pages/seller/ConsultaMapaResumoECF';
import { LivrosFiscais } from './pages/seller/LivrosFiscais';
import { ConsultaAlteracaoPrecoVenda } from './pages/seller/ConsultaAlteracaoPrecoVenda';
import { ConsultaAlteracaoPrecoCompra } from './pages/seller/ConsultaAlteracaoPrecoCompra';
import { ConsultaHistoricoCompras } from './pages/seller/ConsultaHistoricoCompras';
import { ConsultaHistoricoPrecoCusto } from './pages/seller/ConsultaHistoricoPrecoCusto';
import { ConsultaHistoricoVendas } from './pages/seller/ConsultaHistoricoVendas';
import { ConsultaAuditoriaOperacoes } from './pages/seller/ConsultaAuditoriaOperacoes';
import { ConsultaTipoFormaPagtos } from './pages/seller/ConsultaTipoFormaPagtos';
import { ConsultaMarkup } from './pages/seller/ConsultaMarkup';
import { ConsultaMovtoFinanceiroEstoque } from './pages/seller/ConsultaMovtoFinanceiroEstoque';
import { Lista_Debito } from './pages/seller/Lista_Debito';
import { ConsultaAlteracaoFormaPagto } from './pages/seller/ConsultaAlteracaoFormaPagto';
import { ConsultaMovimentoTurno } from './pages/seller/ConsultaMovimentoTurno';
import { ConsultaSangriaSuprimento } from './pages/seller/ConsultaSangriaSuprimento';
import { ConsultaGiroEstoque } from './pages/seller/ConsultaGiroEstoque';
import { ConsultaInventario } from './pages/seller/ConsultaInventario';
import { ConsultaEstoqueNaoVendidos } from './pages/seller/ConsultaEstoqueNaoVendidos';
import { Seller8_MovimentacaoEstoque } from './pages/seller/Seller8_MovimentacaoEstoque';
import { Seller8_PontoEquilibrio } from './pages/seller/Seller8_PontoEquilibrio';
import { ConsultaPosicaoEstoque, MatrizConsultaPosicaoEstoque } from './pages/seller/ConsultaPosicaoEstoque';
import { ConsultaPrevisaoDuracaoEstoque } from './pages/seller/ConsultaPrevisaoDuracaoEstoque';
import { ConsultaValidadeProduto } from './pages/seller/ConsultaValidadeProduto';
import { ConsultaMapaPista } from './pages/seller/ConsultaMapaPista';
import { ConsultaVendasFrentista } from './pages/seller/ConsultaVendasFrentista';
import { ConsultaComandasProdutividadeAtendente } from './pages/seller/ConsultaComandasProdutividadeAtendente';
import { ManterCadReducaoZ } from './pages/seller/ManterCadReducaoZ';
import { ManterFisFechamento } from './pages/seller/ManterFisFechamento';
import { ManterContagem } from './pages/seller/ManterContagem';
import { ManterMovimentoEstoque_Ajuste } from './pages/seller/ManterMovimentoEstoque_Ajuste';
import { ManterNotaFiscal } from './pages/seller/ManterNotaFiscal';
import { TransferenciaAlmoxarifado } from './pages/seller/TransferenciaAlmoxarifado';
import { ManterAlteraFormaPagto } from './pages/seller/ManterAlteraFormaPagto';
import { ManterCadernoPagamento } from './pages/seller/ManterCadernoPagamento';
import { ManterConferenciaCaixa } from './pages/seller/ManterConferenciaCaixa';
import { ManterEtiquetas } from './pages/seller/ManterEtiquetas';
import { ManterMontagemListaPrecos } from './pages/seller/ManterMontagemListaPrecos';
import { ManterReajustePreco_Programacao } from './pages/seller/ManterReajustePreco_Programacao';
import { ManterReajustePreco_Reajuste } from './pages/seller/ManterReajustePreco_Reajuste';
import { ManterConfiguracoesProdutos } from './pages/seller/ManterConfiguracoesProdutos';
import { ManterExportacaoArquivos } from './pages/seller/ManterExportacaoArquivos';
import { ImportacaoTabelaIBPT } from './pages/seller/ImportacaoTabelaIBPT';

import { ManterTituloPesquisa } from './pages/seller/ManterTituloPesquisa';
import { ManterLoteTitulos_Pagar_Lote } from './pages/seller/ManterLoteTitulos_Pagar_Lote';
import { ManterTitulo_Pagar_Gerenciamento } from './pages/seller/ManterTitulo_Pagar_Gerenciamento';
import { consultadiarioauxiliar_Pagar } from './pages/seller/consultadiarioauxiliar_Pagar';
import { ConsultaExtrato_Pagar } from './pages/seller/ConsultaExtrato_Pagar';
import { ManterTitulo_Pagar_Consulta } from './pages/seller/ManterTitulo_Pagar_Consulta';
import { ManterTitulo_Pagar_Consulta_Aberto } from './pages/seller/ManterTitulo_Pagar_Consulta_Aberto';
import { ManterLoteTitulos_Receber_Lote } from './pages/seller/ManterLoteTitulos_Receber_Lote';
import { ManterTitulo_Receber_Gerenciamento } from './pages/seller/ManterTitulo_Receber_Gerenciamento';
import { AgingList } from './pages/seller/AgingList';
import { ComposicaoCarteira } from './pages/seller/ComposicaoCarteira';
import { consultadiarioauxiliar_Receber } from './pages/seller/consultadiarioauxiliar_Receber';
import { ConsultaExtrato_Receber } from './pages/seller/ConsultaExtrato_Receber';
import { ManterTitulo_Receber_Consulta } from './pages/seller/ManterTitulo_Receber_Consulta';
import { ManterTitulo_Receber_Consulta_Aberto } from './pages/seller/ManterTitulo_Receber_Consulta_Aberto';
import { ConciliaContasReceber } from './pages/seller/ConciliaContasReceber';
import { ManterLancamentos } from './pages/seller/ManterLancamentos';
import { TesourariaPagamentos_Pagamento_Parcial } from './pages/seller/TesourariaPagamentos_Pagamento_Parcial';
import { TesourariaPagamentos_Pagamento } from './pages/seller/TesourariaPagamentos_Pagamento';
import { TesourariaRecebimentos_Recebimento_Parcial } from './pages/seller/TesourariaRecebimentos_Recebimento_Parcial';
import { TesourariaRecebimentos_Recebimento } from './pages/seller/TesourariaRecebimentos_Recebimento';
import { ManterTituloPesquisa_Deposito_Cartoes } from './pages/seller/ManterTituloPesquisa_Deposito_Cartoes';
import { ManterTransferencia_Deposito_Dinheiro } from './pages/seller/ManterTransferencia_Deposito_Dinheiro';
import { ManterTransferencia } from './pages/seller/ManterTransferencia';
import { consultadiarioauxiliar } from './pages/seller/consultadiarioauxiliar';
import { ConsultaExtrato_Tesouraria } from './pages/seller/ConsultaExtrato_Tesouraria';
import { ConsultaExtrato_Tesouraria_Conciliado } from './pages/seller/ConsultaExtrato_Tesouraria_Conciliado';
import { PesquisaLancamentos } from './pages/seller/PesquisaLancamentos';
import { ConsultaExtrato_Movimentacao } from './pages/seller/ConsultaExtrato_Movimentacao';
import { ConsultaDepositosEstruturaCartao } from './pages/seller/ConsultaDepositosEstruturaCartao';
import { ConsultaDepositosCheque } from './pages/seller/ConsultaDepositosCheque';
import { FluxoCaixa } from './pages/seller/FluxoCaixa';
import { ConsultaSaldo } from './pages/seller/ConsultaSaldo';
import { GerenciamentoFinanceiro_Fluxo } from './pages/seller/GerenciamentoFinanceiro_Fluxo';
import { Planejamento_Financeiro } from './pages/seller/Planejamento_Financeiro';
import { ConsultaExtrato_Conciliacao } from './pages/seller/ConsultaExtrato_Conciliacao';
import { Seller8_ConciliacaoCartao } from './pages/seller/Seller8_ConciliacaoCartao';
import { ConciliacaoDiferenca } from './pages/seller/ConciliacaoDiferenca';
import { ConsultaExtrato_Conciliacao_Extrato } from './pages/seller/ConsultaExtrato_Conciliacao_Extrato';
import { Bordero_Conciliacao } from './pages/seller/Bordero_Conciliacao';
import { Bordero_Cheques } from './pages/seller/Bordero_Cheques';
import { ManterLivrosFiscais } from './pages/seller/ManterLivrosFiscais';
import { GeraArquivoSPED } from './pages/seller/GeraArquivoSPED';
import { Consulta_Utiliza_SPED } from './pages/seller/Consulta_Utiliza_SPED';
import { DownloadArqSpedF } from './pages/seller/DownloadArqSpedF';
import { SpedFisC590 } from './pages/seller/SpedFisC590';
import { SpedFisD590 } from './pages/seller/SpedFisD590';
import { SpedFisInfoNota } from './pages/seller/SpedFisInfoNota';
import { SpedFisInfoNotaTransporte } from './pages/seller/SpedFisInfoNotaTransporte';
import { SpedFisResNota } from './pages/seller/SpedFisResNota';
import { SpedFisAnalDocs } from './pages/seller/SpedFisAnalDocs';
import { SpedFisMovDia } from './pages/seller/SpedFisMovDia';
import { SpedFisApurIcms } from './pages/seller/SpedFisApurIcms';
import { SpedFisApurIcmsSt } from './pages/seller/SpedFisApurIcmsSt';
import { SpedFisApurIpi } from './pages/seller/SpedFisApurIpi';
import { SpedUnificacaoPisCofins } from './pages/seller/SpedUnificacaoPisCofins';
import { ManifestoNFe } from './pages/seller/ManifestoNFe';
import { MonitorNfe } from './pages/seller/MonitorNfe';
import { ManterCadCargos } from './pages/seller/ManterCadCargos';
import { ManterCadIntervencaoTecnica } from './pages/seller/ManterCadIntervencaoTecnica';
import { ManterCadTanquesBombasBicos } from './pages/seller/ManterCadTanquesBombasBicos';
import { ManterRedeAutorizadoraEstabelecimento } from './pages/seller/ManterRedeAutorizadoraEstabelecimento';
import { ManterRedeAutorizadora } from './pages/seller/ManterRedeAutorizadora';
import { EsCaBandeira } from './pages/seller/EsCaBandeira';
import { EsCaProdutoRede } from './pages/seller/EsCaProdutoRede';
import { EsCaRede } from './pages/seller/EsCaRede';
import { ManterCodigoANP } from './pages/seller/ManterCodigoANP';
import { ManterDelivery } from './pages/seller/ManterDelivery';
import { ManterObservacoesPreparo } from './pages/seller/ManterObservacoesPreparo';
import { Seller8_CardapioDigital } from './pages/seller/Seller8_CardapioDigital';
import { Seller8_ConfiguracoesCardapio } from './pages/seller/Seller8_ConfiguracoesCardapio';
import { ManterGruposIngredientes } from './pages/seller/ManterGruposIngredientes';
import { ManterIngredientes } from './pages/seller/ManterIngredientes';
import { ConsultaResgatesPremmia } from './pages/seller/ConsultaResgatesPremmia';
import { VendasRealizadasConsulta_VendasBR } from './pages/seller/VendasRealizadasConsulta_VendasBR';
import { ConsultaAlteracaoIngredientes } from './pages/seller/ConsultaAlteracaoIngredientes';
import { ConsultaCustosProdutosBR } from './pages/seller/ConsultaCustosProdutosBR';
import { Seller8_ComandasDigitais } from './pages/seller/Seller8_ComandasDigitais';

import { Seller8_PedidosKDS } from './pages/seller/Seller8_PedidosKDS';
import { AnexosLairConsulta } from './pages/seller/AnexosLairConsulta';
import { MargemBRConsulta } from './pages/seller/MargemBRConsulta';
import { ConsultaPromocoesBRMania } from './pages/seller/ConsultaPromocoesBRMania';
import { ConsultaPromocoesPremmia } from './pages/seller/ConsultaPromocoesPremmia';
import { ManterInutilizacaoNFe } from './pages/seller/ManterInutilizacaoNFe';
import { ManterMedicao } from './pages/seller/ManterMedicao';
import { ManterLMCeMedicao } from './pages/seller/ManterLMCeMedicao';
import { ManterNotaFiscaleFornecedoreProduto } from './pages/seller/ManterNotaFiscaleFornecedoreProduto';
import { CadastroEstabelecimento } from './pages/configuracoes/empresas/CadastroEstabelecimento';
import { TIPO_SITE } from './shared/constants/TipoSiteConstant';
import { DetalhadoProduto, MatrizDetalhadoProduto } from './pages/relatorios/vendas/detalhado-produto/DetalhadoProduto';
import { VendasCombustivelPremmia } from './pages/relatorios/vendas/combustivel-premmia/VendasCombustivelPremmia';
import { PromocaoDinamico, MatrizPromocaoDinamico } from './pages/relatorios/vendas/promocao-dinamico/PromocaoDinamico';
import { ConsignadoDinamico, MatrizConsignadoDinamico } from './pages/relatorios/consignado-dinamico/ConsignadoDinamico';
import { DocumentosDinamico, MatrizDocumentosDinamico } from './pages/relatorios/auditoria/documentos-dinamico/DocumentosDinamico';
import { DocumentosPagamentosDinamico, MatrizDocumentosPagamentosDinamico } from './pages/relatorios/auditoria/documentos-pagamentos-dinamico/DocumentosPagamentosDinamico';
import { PedidoDinamicoHyperlocal, MatrizPedidoDinamicoHyperlocal } from './pages/relatorios/vendas/pedido-dinamico-hyperlocal/PedidoDinamicoHyperlocal';
import { ComprasDinamico } from './pages/relatorios/auditoria/compras-dinamico/ComprasDinamico';
import { OSsConComodato } from './pages/operacoes/consignado-comodato-lista/OSsConComodato';
import { NovaOS } from './pages/operacoes/consignado-comodato-nova-os/NovaOS';
import SetupFacil from './pages/configuracoes/setup-facil/SetupFacil';

import { RelatorioControleDeAtivos } from './pages/relatorios/auditoria/ControleDeAtivos/RelatorioControleDeAtivos';
import { ConferenciaTurnoPedidos } from './pages/operacoes/tesouraria/conferencia-turno-pedidos/ConferenciaTurnoPedidos';
import { MovimentacaoTurnoPedidoDinamico } from './pages/relatorios/tesouraria/movimentacao-turno-pedido-dinamico/MovimentacaoTurnoPedidoDinamico';
import { MatrizMovimentacaoTurnoDinamico } from './pages/relatorios/tesouraria/movimentacao-turno-pedido-dinamico/MatrizMovimentacaoTurnoPedidoDinamico';
import { HorusBi } from './pages/dashboard/horus-bi/HorusBi.jsx';
import { DashboardMain } from './pages/dashboard/DashboardMain.jsx';
import { DashVazio } from './pages/dashboard/dash-vazio/DashVazio.jsx';

import { ContaDigitalIntegrada } from './pages/financeiro/conta-digital-integrada/ContaDigitalIntegrada';
import { SolicitacaoReserva } from './pages/operacoes/solicitacao-reserva/SolicitacaoReserva.jsx';

const Routes = () => (
  <Router>
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/cadastro-estabelecimento" component={CadastroEstabelecimento} />
        <Route path="/login" component={Login} />
        <Route path="/login/:urlOrigem/:tipoSite" component={Login} />
        <PrivateRoute path="/Ajuda" component={Ajuda} />
        <Route path="/boas-vindas" exact component={BoasVindas} />
        <Route path="/boas-vindas/:chave/:email/:cnpj" exact component={BoasVindas} />
        <Route path="/esqueceu-senha/:chave/:email" exact component={EsqueceuSenha} />
        <Route path="/recuperacao-senha/" exact component={EsqueceuSenha} />

        <PrivateRoute exact path="/" component={DashboardMain} />
        <PrivateRoute path="/dashboard/gerencial" component={Dashboard} />
        <PrivateRoute path="/dashboard/horusbi" component={HorusBi} />
        <PrivateRoute path="/dashboard/vazio" component={DashVazio} />
        <PrivateRoute path="/dashboard/payhub" component={DashboardPayHub} />

        <PrivateRoute path="/setup-facil" component={SetupFacil} />
        <PrivateRoute path="/cadastro/estrutura-mercadologica/new" component={EstruturaMercadologica} edit />
        <PrivateRoute path="/cadastro/estrutura-mercadologica" component={EstruturaMercadologica} />
        <PrivateRoute path="/cadastro/pessoas/new" component={Pessoas} edit />
        <PrivateRoute path="/cadastro/pessoas" component={Pessoas} />
        <PrivateRoute path="/cadastro/produtos/new" component={Produtos} edit />
        <PrivateRoute path="/cadastro/produtos" component={Produtos} />
        <PrivateRoute path="/cadastro/maquinas-perifericos/new" component={Maquinas} edit />
        <PrivateRoute path="/cadastro/maquinas-perifericos" component={Maquinas} />
        <PrivateRoute path="/cadastro/tanques-bombas-bicos/bico/new" component={Bicos} edit />
        <PrivateRoute path="/cadastro/tanques-bombas-bicos/bico" component={Bicos} />
        <PrivateRoute path="/cadastro/tanques-bombas-bicos/bomba/new" component={Bombas} edit />
        <PrivateRoute path="/cadastro/tanques-bombas-bicos/bomba" component={Bombas} />
        <PrivateRoute path="/cadastro/tanques-bombas-bicos/tanque/new" component={Tanques} edit />
        <PrivateRoute path="/cadastro/tanques-bombas-bicos/tanque" component={Tanques} />
        <PrivateRoute path="/cadastro/tanques-bombas-bicos" component={MapaDePista} />
        <PrivateRoute path="/cadastro/funcionarios/new" component={Funcionarios} edit />
        <PrivateRoute path="/cadastro/funcionarios" component={Funcionarios} />
        <PrivateRoute path="/cadastro/lista-preco" component={ListaPreco} />
        <PrivateRoute path="/cadastro/formas-pagamento/new" component={FormasPagamento} edit />
        <PrivateRoute path="/cadastro/formas-pagamento" component={FormasPagamento} />
        <PrivateRoute path="/cadastro/grupo-pessoas/new" component={GrupoPessoas} edit />
        <PrivateRoute path="/cadastro/grupo-pessoas" component={GrupoPessoas} />
        <PrivateRoute path="/cadastro/regra-troco" component={RegrasTroco} />

        <PrivateRoute path="/estoque/deposito/new" component={Deposito} edit />
        <PrivateRoute path="/estoque/depositos" component={Deposito} />
        <PrivateRoute path="/estoque/medicao-tanque" component={MedicaoTanques} />
        <PrivateRoute path="/estoque/ajuste-estoque" component={AjusteEstoque} />
        <PrivateRoute path="/estoque/inventario" component={Contagem} />

        <PrivateRoute path="/financeiro/gerenciamento-financeiro/quita" component={GerenciamentoFinanceiro} quita />
        <PrivateRoute path="/financeiro/gerenciamento-financeiro/quitaLote" component={GerenciamentoFinanceiro} quitaLote />
        <PrivateRoute path="/financeiro/gerenciamento-financeiro/new" component={GerenciamentoFinanceiro} edit />
        <PrivateRoute path="/financeiro/gerenciamento-financeiro" component={GerenciamentoFinanceiro} />
        <PrivateRoute path="/financeiro/fluxo-caixa" component={Cashflow} />
        <PrivateRoute path="/financeiro/faturamento-vendas-prazo/historico" component={RelatorioFaturamentoVendaPrazo} historico />
        <PrivateRoute path="/financeiro/faturamento-vendas-prazo" component={RelatorioFaturamentoVendaPrazo} />
        <PrivateRoute path="/financeiro/conferencia-caixa" component={ConferenciaCaixaFiltros} />

        <PrivateRoute path="/fiscal/cst-cfop" component={CSTCFOP} />
        <PrivateRoute path="/fiscal/dados-adicionais-nf/cadastro" component={DadosAdicionaisDocumentoFiscalCadastro} />
        <PrivateRoute path="/fiscal/dados-adicionais-nf/resultado" component={DadosAdicionaisDocumentoFiscalResultado} />
        <PrivateRoute path="/fiscal/dados-adicionais-nf" component={DadosAdicionaisDocumentoFiscalFiltros} />
        <PrivateRoute path="/fiscal/LMC" component={LMC} />
        <PrivateRoute path="/fiscal/impostos/new" component={Impostos} edit />
        <PrivateRoute path="/fiscal/impostos" component={Impostos} />
        <PrivateRoute path="/fiscal/tributacao" component={Tributacao} />
        <PrivateRoute path="/fiscal/sped-fiscal" component={SpedFiscal} />
        <PrivateRoute path="/fiscal/sped-contribuicoes" component={SpedContribuicoes} />
        <PrivateRoute path="/fiscal/apuracao-icms-st" component={ApuracaoICMSST} />
        <PrivateRoute path="/fiscal/apuracao-icms" component={ApuracaoICMS} />
        <PrivateRoute path="/fiscal/nota-fiscal" component={NotaFiscal} />
        <PrivateRoute path="/fiscal/monitoramento-nfe" component={MonitoramentoNfe} />
        <PrivateRoute path="/fiscal/manifestacao-destinatario" component={ManifestacaoDestNfe} />
        <PrivateRoute path="/fiscal/inutilizacao-nfe" component={InutilizacaoNfe} />
        <PrivateRoute path="/fiscal/importacao-ibpt" component={ImportacaoIbpt} />

        <PrivateRoute path="/configuracoes/cfop-pis-cofins" component={ConfiguracaoCFOP} />
        <PrivateRoute path="/configuracoes/cfop-operacao" component={CFOPPorOperacao} />
        <PrivateRoute path="/configuracoes/configuracoes-gerais" component={ConfiguracoesGerais} />
        <PrivateRoute path="/configuracoes/carga-inicial/produtos" component={() => <CargaInicialImportacao modo={Modo.Produtos} />} />
        <PrivateRoute path="/configuracoes/carga-inicial/pessoas" component={() => <CargaInicialImportacao modo={Modo.Pessoas} />} />
        <PrivateRoute path="/configuracoes/carga-inicial" component={CargaInicialHub} />

        {/*
        //A tela nova de Contas deverá entrar em outro momento
        <PrivateRoute path="/cadastros/financeiro/contas/subconta" component={Contas} editSub />
        <PrivateRoute path="/cadastros/financeiro/contas/conta" component={Contas} edit />
        <PrivateRoute path="/cadastros/financeiro/contas/" component={Contas} />
        */}
        <PrivateRoute path="/cadastros/financeiro/contas" component={ManterConta} />

        <PrivateRoute path="/configuracoes/empresa/new" component={Empresas} edit />
        <PrivateRoute path="/configuracoes/perfil-acesso/new" component={PerfilAcesso} edit />
        <PrivateRoute path="/configuracoes/perfil-acesso" component={PerfilAcesso} />
        <PrivateRoute path="/configuracoes/empresa" component={Empresas} />
        <PrivateRoute path="/configuracoes/centro-monetarios/new" component={CentrosMonetarios} edit />
        <PrivateRoute path="/configuracoes/centro-monetarios" component={CentrosMonetarios} />
        <PrivateRoute path="/configuracoes/estrutura-cartoes/new" component={EstruturaCartoes} edit />
        <PrivateRoute path="/configuracoes/estrutura-cartoes" component={EstruturaCartoes} />
        <PrivateRoute path="/relatorios/compras-documento-fiscal" component={ComprasDocumentoFiscal} />
        <PrivateRoute path="/relatorios/curva-abc" component={CurvaABC} />
        <PrivateRoute path="/relatorios/resultado-operacional" component={ResultadoOperacional} />
        <PrivateRoute path="/relatorios/fechamento-caixa" component={FechamentoCaixa} />
        <PrivateRoute path="/relatorios/margem-markup" component={MargemMarkup} />
        <PrivateRoute path="/relatorios/movimentacao-financeira-estoque" component={MovimentacaoFinanceiraEstoque} edit />
        <PrivateRoute path="/relatorios/financeiro/relatorio" component={ConsultaFinanceiro} edit />
        {/* <PrivateRoute path="/relatorios/financeiro" component={ConsultaFinanceiro} /> */}
        <PrivateRoute path="/relatorios/financeiro/consulta-vendas/relatorio" component={ConsultaVendas} edit />
        <PrivateRoute path="/relatorios/financeiro/consulta-vendas" component={ConsultaVendas} />
        <PrivateRoute path="/relatorios/movimentacao-estoque" component={MovimentacaoEstoqueReport} />
        {/* <PrivateRoute path="/relatorios/estoque" component={ConsultaEstoque} /> */}
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/consulta-vendas`} component={DetalhadoProduto} />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/promocao-dinamico`} component={PromocaoDinamico} />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/pedidos-dinamico`} component={ConsignadoDinamico} />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/auditoria/documentos-dinamico`} component={DocumentosDinamico} />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/auditoria/documentos-pagamentos-dinamico`} component={DocumentosPagamentosDinamico} />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/tesouraria/movimentacao-turno-pedido-dinamico`} component={MovimentacaoTurnoPedidoDinamico} />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/pedido-dinamico-hyperlocal`} component={PedidoDinamicoHyperlocal} />

        <PrivateRoute path="/relatorios/gerenciamento-financeiro" component={GerenciamentoFinanceiroReport} />
        <PrivateRoute path="/relatorios/notas-fiscais" component={NotasFiscaisReport} />

        <PrivateRoute path="/configuracoes/pos-mfe/detalhes" component={POSMFeDetails} />
        <PrivateRoute path="/configuracoes/pos-mfe" component={POSMFe} />

        <PrivateRoute path="/exportacoes/fortes-fiscal" component={FortesFiscal} />

        <PrivateRoute path="/cadastro/usuarios" component={Usuarios} />

        <PrivateRoute path="/cadastro/requisicao-LGPD/detalhes" component={RequisicaoLGPDDetails} />
        <PrivateRoute path="/cadastro/requisicao-LGPD" component={RequisicaoLGPD} />

        <PrivateRoute path="/cadastros/produtos/produtos" component={ManterProdProduto} />
        <PrivateRoute path="/financeiro/contas-a-receber/gerenciamento-de-arquivos/remessa" component={ManterGerArquivoRemessa} />
        <PrivateRoute path="/financeiro/contas-a-receber/gerenciamento-de-arquivos/retorno" component={ManterGerArquivoRetorno} />
        <PrivateRoute path="/informacoes-fiscais/sped-fiscal/geracao-arquivo" component={GeraArqSpedF} />
        <PrivateRoute path="/informacoes-fiscais/sped-pis/cofins/geracao" component={Geracao_EFD} />
        <PrivateRoute path="/cadastros/sistema/configuracoes-gerais" component={ManterConfiguracoesGerais} />
        <PrivateRoute path="/cadastros/sistema/feriados-locais" component={FeriadosLocais} />
        <PrivateRoute path="/cadastros/sistema/feriados-nacionais" component={FeriadosNacionais} />
        <PrivateRoute path="/cadastros/sistema/funcionarios" component={ManterFunFuncionario} />
        <PrivateRoute path="/cadastros/sistema/perfis-de-acesso" component={ManterPerfis} />
        <PrivateRoute path="/cadastros/sistema/versao" component={Seller8_Feed} />
        <PrivateRoute path="/cadastros/produtos/carga-produtos" component={SetupFacil} />
        <PrivateRoute path="/cadastros/sistema/visoes-de-estabelecimentos" component={ManterVisoes} />
        <PrivateRoute path="/cadastros/sistema/config-sistema" component={ManterConfSistema} />
        <PrivateRoute path="/cadastros/estabelecimento/almoxarifados" component={ManterAlmoxarifado} />
        <PrivateRoute path="/cadastros/estabelecimento/clientes" component={ManterCliCliente} />
        <PrivateRoute path="/cadastros/estabelecimento/agrupadores-de-clientes" component={ManterAgrupadorCliente} />
        <PrivateRoute path="/cadastros/estabelecimento/estabelecimento" component={ManterEstabelecimento} />
        <PrivateRoute path="/cadastros/estabelecimento/fabricantes" component={ManterFabFabricantes} />
        <PrivateRoute path="/cadastros/estabelecimento/faixas-de-comissionamento" component={ManterFaixaComissionamento} />
        <PrivateRoute path="/cadastros/estabelecimento/fornecedores" component={ManterForFornecedor} />
        <PrivateRoute path="/cadastros/estabelecimento/justificativas" component={ManterCadJustificativas} />
        <PrivateRoute path="/cadastros/estabelecimento/maquinas" component={ManterMaquina} />
        <PrivateRoute path="/cadastros/estabelecimento/perifericos" component={ManterPeriferico} />
        <PrivateRoute path="/cadastros/estabelecimento/transportadoras" component={ManterTransportadora} />
        <PrivateRoute path="/cadastros/estabelecimento/turnos" component={ManterTurnos} />
        <PrivateRoute path="/cadastros/estabelecimento/comandas/cartoes-de-comand" component={ManterCadCartaoComanda} />
        <PrivateRoute path="/cadastros/estabelecimento/comandas/lote-de-cartoe" component={LoteCartaoComanda} />
        <PrivateRoute path="/cadastros/estabelecimento/regras-de-impressao" component={ManterRegrasImpressao}/>
        <PrivateRoute path="/financeiro/cadastros/bancos" component={ManterBancos} />
        <PrivateRoute path="/financeiro/cadastros/centros-monetarios" component={ManterCentroMonetario} />
        <PrivateRoute path="/cadastros/financeiro/formas-de-pagamento" component={ManterFormaPagamento} />
        <PrivateRoute path="/cadastros/financeiro/troco-e-devolucao" component={ManterFormaPagamentoTroco} />
        <PrivateRoute path="/cadastros/produtos/estrutura-mercadologica" component={ManterEstruturaMercadologica} />
        <PrivateRoute path="/cadastros/produtos/listas-de-precos" component={ManterListaPreco} />
        <PrivateRoute path="/cadastros/produtos/montagem-de-kit/composto" component={ManterKitComposto} />
        <PrivateRoute path="/cadastros/produtos/ncm" component={ManterCadNCM} />
        <PrivateRoute
          path={`/cadastros/produtos${TIPO_SITE === 'RetaguardaEmporioPro' ? '/gestao-de-pendencias' : '/pendencias-cadastro-padrao'}`}
          component={Seller8_GestaoPendencias}
        />
        <PrivateRoute path="/cadastros/produtos/agrupadores-de-produtos/para-consulta" component={ManterAgrupador} />
        <PrivateRoute path="/cadastros/fiscal/aidf" component={ManterAidf} />
        <PrivateRoute path="/cadastros/fiscal/cfops-por-operacao" component={ConfiguracaoCFOPVendas} />
        <PrivateRoute path="/cadastros/fiscal/configuracao-cfop-de-pis-cofins" component={ManterCFOPxTrib} />
        <PrivateRoute path="/cadastros/fiscal/cst-x-cfop" component={ManterCSTxCFOP} />
        <PrivateRoute path="/cadastros/fiscal/regras-fiscais" component={ManterConfDeclarante} />
        <PrivateRoute path="/cadastros/fiscal/dados-adicionais-para-notas-fiscais" component={ManterDadosAdicNF} />
        <PrivateRoute path="/cadastros/fiscal/impostos/configuracao-pis_cofin" component={ManterConfiguracoesPisCofins} />
        <PrivateRoute path="/cadastros/fiscal/impostos/grupo-de-imposto" component={ManterCadGrupoImpostos} />
        <PrivateRoute path="/cadastros/fiscal/impostos/imposto" component={ManterCadAliquotas} />
        <PrivateRoute path="/cadastros/fiscal/impostos/observacoes-de-imposto" component={ManterCadObservacoes} />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/apuracao-de-impostos`}
          component={ConsultaVendasApuracaoImpostos}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/atendimentos`}
          component={ConsultaHistAtendimentos}
        />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/comissao`} component={ConsultaVendasComissao} />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/curva-abc`} component={ConsultaVendasCurvaABC} />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/curva-abc-comparativo`}
          component={Seller8_VendasCurvaABC}
        />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/diarias`} component={ConsultaVendasDiarias} />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/evolucao`}
          component={ConsultaEvolucaoMensalVendas}
        />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/kits-vendidos`} component={ConsultaVendasKit} />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/por-alíquota`}
          component={ConsultaVendasPorAliquota}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/por-cliente/placa`}
          component={ConsultaVendasClientePlaca}
        />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/por-operador`} component={ConsultaVendasOperador} />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/realizadas`} component={VendasRealizadasConsulta} />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/resumo-setor-dia-hora`}
          component={ConsultaVendasPorHoraDia}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/documentos-fiscais-por-faixa-de-valor`}
          component={ConsultaDocFiscFaixaVal}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/fiscal/mapa-resumo-do-ecf`}
          component={ConsultaMapaResumoECF}
        />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/fiscal/livros-fiscais`} component={LivrosFiscais} />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/auditoria/alteracao-do-preco-de-venda`}
          component={ConsultaAlteracaoPrecoVenda}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/auditoria/alteracao-do-preco-de-compra`}
          component={ConsultaAlteracaoPrecoCompra}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/auditoria/compras-por-documento-fiscal`}
          component={ConsultaHistoricoCompras}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/auditoria/custos`}
          component={ConsultaHistoricoPrecoCusto}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/auditoria/vendas-por-documento-fiscal`}
          component={ConsultaHistoricoVendas}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/auditoria/auditoria-de-operacoes`}
          component={ConsultaAuditoriaOperacoes}
        />

        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/auditoria/comprasdinamico`} component={ComprasDinamico} />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/auditoria/controle-de-ativos`}
          component={RelatorioControleDeAtivos}
        />

        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/financeiro/formas-de-pagamento`}
          component={ConsultaTipoFormaPagtos}
        />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/financeiro/margem-e-mark-up`} component={ConsultaMarkup} />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/financeiro/movimentacao-financeira-do-estoque`}
          component={ConsultaMovtoFinanceiroEstoque}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/financeiro/resultado-operacional`}
          component={ResultadoOperacional}
        />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/financeiro/lista-de-debito`} component={Lista_Debito} />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/tesouraria/alteracoes-de-formas-de-pagamento`}
          component={ConsultaAlteracaoFormaPagto}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/tesouraria/movimentacao-do-turno`}
          component={ConsultaMovimentoTurno}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/tesouraria/sangrias/suprimentos`}
          component={ConsultaSangriaSuprimento}
        />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/estoque/giro`} component={ConsultaGiroEstoque} />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/estoque/inventario`} component={ConsultaInventario} />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/estoque/itens-nao-vendidos-desde...`}
          component={ConsultaEstoqueNaoVendidos}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/estoque/movimentacao-gerencial`}
          component={Seller8_MovimentacaoEstoque}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/financeiro/ponto-equilibrio`}
          component={Seller8_PontoEquilibrio}
        />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/estoque/posicao`} component={ConsultaPosicaoEstoque} />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/estoque/prazo-medio`}
          component={ConsultaPrevisaoDuracaoEstoque}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/estoque/validade-de-produtos`}
          component={ConsultaValidadeProduto}
        />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/pista/mapa-de-pista`} component={ConsultaMapaPista} />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/pista/vendas-por-frentista`}
          component={ConsultaVendasFrentista}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/comandas/produtividade-de-atendente`}
          component={ConsultaComandasProdutividadeAtendente}
        />

        <PrivateRoute path="/matriz/estoque-posicao" component={MatrizConsultaPosicaoEstoque} />
        <PrivateRoute path="/matriz/vendas-realizadas" component={MatrizVendasRealizadasConsulta} />
        <PrivateRoute path="/matriz/vendas-documentos-fiscais-por-faixa-de-valor" component={MatrizConsultaDocFiscFaixaVal} />
        <PrivateRoute path="/matriz/vendas-curva-abc-comparativo" component={Seller8_MatrizVendasCurvaABC} />
        <PrivateRoute path="/matriz/vendas-gerais" component={MatrizDetalhadoProduto} />
        <PrivateRoute path="/consultas/vendas/vendas-premmia" component={VendasCombustivelPremmia} />


        <PrivateRoute path="/matriz/produtos-ajuste-de-preco" component={MatrizProdutosAjustePreco} />
        <PrivateRoute path="/matriz/promocao-dinamico" component={MatrizPromocaoDinamico} />
        <PrivateRoute path="/matriz/pedidos-dinamico" component={MatrizConsignadoDinamico} />
        <PrivateRoute path="/matriz/movimentacao-turno-pedido-dinamico" component={MatrizMovimentacaoTurnoDinamico} />
        <PrivateRoute path="/matriz/documentos-dinamico" component={MatrizDocumentosDinamico} />
        <PrivateRoute path="/matriz/documentos-pagamentos-dinamico" component={MatrizDocumentosPagamentosDinamico} />
        <PrivateRoute path="/matriz/pedido-dinamico-hyperlocal" component={MatrizPedidoDinamicoHyperlocal} />

        <PrivateRoute path="/operacoes/fiscal/entrada-movimento-reducao-z" component={ManterCadReducaoZ} />
        <PrivateRoute path="/operacoes/fiscal/abertura/fechamento-mes" component={ManterFisFechamento} />
        <PrivateRoute path="/operacoes/fiscal/notas-fiscais" component={ManterNotaFiscaleFornecedoreProduto} NotaFiscal />
        <PrivateRoute path="/operacoes/estoque/contagem" component={ManterContagem} />
        <PrivateRoute path="/operacoes/estoque/movimentacao/ajuste-de-estoqu" component={ManterMovimentoEstoque_Ajuste} />
        <PrivateRoute path="/operacoes/estoque/movimentacao/transferencia-entre-almoxarifado" component={TransferenciaAlmoxarifado} />
        <PrivateRoute path="/operacoes/tesouraria/alteracao-de-forma-de-pagamento" component={ManterAlteraFormaPagto} />
        <PrivateRoute path="/operacoes/tesouraria/caderno-de-pagamento" component={ManterCadernoPagamento} />
        <PrivateRoute path="/operacoes/tesouraria/conferencia-de-turno" component={ManterConferenciaCaixa} />
        <PrivateRoute path="/operacoes/tesouraria/conferencia-de-turno-de-pedidos" component={ConferenciaTurnoPedidos} />
        <PrivateRoute path="/operacoes/produtos/etiquetas" component={ManterEtiquetas} />
        <PrivateRoute path="/operacoes/produtos/montagem-de-lista-de-precos" component={ManterMontagemListaPrecos} />
        <PrivateRoute path="/operacoes/produtos/programacao-de-precos" component={ManterReajustePreco_Programacao} />
        <PrivateRoute path="/operacoes/produtos/reajuste-de-precos" component={ManterReajustePreco_Reajuste} />
        <PrivateRoute path="/operacoes/produtos/ajuste-em-lote" component={ManterConfiguracoesProdutos} />
        <PrivateRoute path="/operacoes/ferramentas/exportacao-de-arquivos" component={ManterExportacaoArquivos} />
        <PrivateRoute path="/operacoes/ferramentas/importacao-tabela-ibpt" component={ImportacaoTabelaIBPT} />
        <PrivateRoute path="/operacoes/pedidos/novo" component={NovaOS} />
        <PrivateRoute path="/operacoes/pedidos" component={OSsConComodato} />
        
        <PrivateRoute path="/operacoes/solicitacaoreserva" component={SolicitacaoReserva} />

        <PrivateRoute path="/financeiro/contas-a-pagar/aceite" component={ManterTituloPesquisa} />
        <PrivateRoute path="/financeiro/contas-a-pagar/altera-lote" component={ManterLoteTitulos_Pagar_Lote} />
        <PrivateRoute path="/financeiro/contas-a-pagar/gerenciamento-titulos" component={ManterTitulo_Pagar_Gerenciamento} />
        <PrivateRoute path="/financeiro/contas-a-pagar/consultas/diario-auxiliar" component={consultadiarioauxiliar_Pagar} />
        <PrivateRoute path="/financeiro/contas-a-pagar/consultas/extrato" component={ConsultaExtrato_Pagar} />
        <PrivateRoute path="/financeiro/contas-a-pagar/consultas/pagamentos" component={ManterTitulo_Pagar_Consulta} />
        <PrivateRoute path="/financeiro/contas-a-pagar/consultas/titulos-em-aberto" component={ManterTitulo_Pagar_Consulta_Aberto} />
        <PrivateRoute path="/financeiro/contas-a-receber/altera-lote" component={ManterLoteTitulos_Receber_Lote} />
        <PrivateRoute path="/financeiro/contas-a-receber/gerenciamento-titulos" component={ManterTitulo_Receber_Gerenciamento} />
        <PrivateRoute path="/financeiro/contas-a-receber/consultas/aging-list" component={AgingList} />
        <PrivateRoute path="/financeiro/contas-a-receber/consultas/composicao-da-carteira" component={ComposicaoCarteira} />
        <PrivateRoute path="/financeiro/contas-a-receber/consultas/diario-auxiliar" component={consultadiarioauxiliar_Receber} />
        <PrivateRoute path="/financeiro/contas-a-receber/consultas/extrato" component={ConsultaExtrato_Receber} />
        <PrivateRoute path="/financeiro/contas-a-receber/consultas/recebimentos" component={ManterTitulo_Receber_Consulta} />
        <PrivateRoute path="/financeiro/contas-a-receber/consultas/titulos-em-aberto" component={ManterTitulo_Receber_Consulta_Aberto} />
        <PrivateRoute path="/financeiro/tesouraria/fechamento-caixa" component={ConciliaContasReceber} />
        <PrivateRoute path="/financeiro/tesouraria/lancamentos" component={ManterLancamentos} />
        <PrivateRoute path="/financeiro/tesouraria/pagamentos/liquidacao-parcial" component={TesourariaPagamentos_Pagamento_Parcial} />
        <PrivateRoute path="/financeiro/tesouraria/pagamentos/liquidacao" component={TesourariaPagamentos_Pagamento} />
        <PrivateRoute path="/financeiro/tesouraria/recebimentos/liquidacao-parcial" component={TesourariaRecebimentos_Recebimento_Parcial} />
        <PrivateRoute path="/financeiro/tesouraria/recebimentos/liquidacao" component={TesourariaRecebimentos_Recebimento} />
        <PrivateRoute path="/financeiro/tesouraria/depositos/cartoes-bancos" component={ManterTituloPesquisa_Deposito_Cartoes} />
        <PrivateRoute path="/financeiro/tesouraria/depositos/dinheiro-bancos" component={ManterTransferencia_Deposito_Dinheiro} />
        <PrivateRoute path="/financeiro/tesouraria/transferencias/bancos" component={ManterTransferencia} />
        <PrivateRoute path="/financeiro/tesouraria/consulta/diario-auxiliar" component={consultadiarioauxiliar} />
        <PrivateRoute path="/financeiro/tesouraria/consulta/extrato" component={ConsultaExtrato_Tesouraria} />
        <PrivateRoute path="/financeiro/tesouraria/consulta/extrato-conciliado" component={ConsultaExtrato_Tesouraria_Conciliado} />
        <PrivateRoute path="/financeiro/tesouraria/consulta/lancamentos" component={PesquisaLancamentos} />
        <PrivateRoute path="/financeiro/tesouraria/consulta/movimentacao" component={ConsultaExtrato_Movimentacao} />
        <PrivateRoute path="/financeiro/tesouraria/consulta/depositos" component={ConsultaDepositosEstruturaCartao} />
        <PrivateRoute path="/financeiro/tesouraria/depositos/consultas" component={ConsultaDepositosCheque} />
        <PrivateRoute path="/financeiro/fluxo-de-caixa/cash-flow" component={FluxoCaixa} />
        <PrivateRoute path="/financeiro/fluxo-de-caixa/consulta-saldos" component={ConsultaSaldo} />
        <PrivateRoute path="/financeiro/fluxo-de-caixa/gerenciamento-financeiro" component={GerenciamentoFinanceiro_Fluxo} />
        <PrivateRoute path="/financeiro/fluxo-de-caixa/planejamento-financeiro" component={Planejamento_Financeiro} />
        <PrivateRoute path="/financeiro/conciliacao/bancos" component={ConsultaExtrato_Conciliacao} />
        <PrivateRoute path="/financeiro/conciliacao/cartoes" component={Seller8_ConciliacaoCartao} />
        <PrivateRoute path="/financeiro/conciliacao/consultas/diferencas" component={ConciliacaoDiferenca} />
        <PrivateRoute path="/financeiro/conciliacao/consultas/extrato" component={ConsultaExtrato_Conciliacao_Extrato} />
        <PrivateRoute path="/financeiro/conciliacao/bordero/cheques" component={Bordero_Conciliacao} />
        <PrivateRoute path="/financeiro/tesouraria/bordero/cheques" component={Bordero_Cheques} />
        <PrivateRoute path="/financeiro/conta-digital-integrada" component={ContaDigitalIntegrada} />

        <PrivateRoute path="/informacoes-fiscais/sped-contabil/contabilizacao-de-impostos" component={ManterLivrosFiscais} />
        <PrivateRoute path="/informacoes-fiscais/sped-contabil/geracao-de-arquivo" component={GeraArquivoSPED} />
        <PrivateRoute path="/informacoes-fiscais/sped-fiscal/consulta-fila" component={Consulta_Utiliza_SPED} />
        <PrivateRoute path="/informacoes-fiscais/sped-fiscal/download-arquivos-recebidos" component={DownloadArqSpedF} />
        <PrivateRoute path="/informacoes-fiscais/sped-fiscal/nota-fiscal/analitico-nf-energia-eletrica" component={SpedFisC590} />
        <PrivateRoute path="/informacoes-fiscais/sped-fiscal/nota-fiscal/analitico-nf-servicos-de-comunicacao" component={SpedFisD590} />
        <PrivateRoute path="/informacoes-fiscais/sped-fiscal/nota-fiscal/obs-lancamento-fiscal" component={SpedFisInfoNota} />
        <PrivateRoute path="/informacoes-fiscais/sped-fiscal/nota-fiscal/obs-lancamento-fiscal-transporte" component={SpedFisInfoNotaTransporte} />
        <PrivateRoute path="/informacoes-fiscais/sped-fiscal/nota-fiscal/registro-analitico-do-resumo-diario-nfs" component={SpedFisResNota} />
        <PrivateRoute path="/informacoes-fiscais/sped-fiscal/nota-fiscal/registro-analitico-dos-documentos" component={SpedFisAnalDocs} />
        <PrivateRoute path="/informacoes-fiscais/sped-fiscal/equipamento-ecf" component={SpedFisMovDia} />
        <PrivateRoute path="/informacoes-fiscais/sped-fiscal/apuracao-do-icms-e-ipi/apuracao-icms-st" component={SpedFisApurIcmsSt} />
        <PrivateRoute path="/informacoes-fiscais/sped-fiscal/apuracao-do-icms-e-ipi/apuracao-icms" component={SpedFisApurIcms} />
        <PrivateRoute path="/informacoes-fiscais/sped-fiscal/apuracao-do-icms-e-ipi/apuracao-ipi" component={SpedFisApurIpi} />
        <PrivateRoute path="/informacoes-fiscais/sped-pis/cofins/" component={SpedUnificacaoPisCofins} />
        <PrivateRoute path="/nf-e/manifestacao/" component={ManifestoNFe} />
        <PrivateRoute path="/nf-e/monitoramento/" component={MonitorNfe} />
        <PrivateRoute path="/cadastros/estabelecimento/cargos" component={ManterCadCargos} />
        <PrivateRoute path="/cadastros/estabelecimento/intervencoes-tecnicas" component={ManterCadIntervencaoTecnica} />
        <PrivateRoute path="/cadastros/estabelecimento/tanques-bombas-bicos" component={ManterCadTanquesBombasBicos} />
        <PrivateRoute path="/parametros-cartoes/parametros-do-estabelecimento/" component={ManterRedeAutorizadoraEstabelecimento} />
        <PrivateRoute path="/parametros-cartoes/redes-autorizadoras/" component={ManterRedeAutorizadora} />
        <PrivateRoute path="/cadastros/financeiro/estrutura-de-cartoes/bandeira" component={EsCaBandeira} />
        <PrivateRoute path="/cadastros/financeiro/estrutura-de-cartoes/estrutura-cartoe" component={EstruturaCartoes} />
        <PrivateRoute path="/cadastros/financeiro/estrutura-de-cartoes/produto" component={EsCaProdutoRede} />
        <PrivateRoute path="/cadastros/financeiro/estrutura-de-cartoes/rede" component={EsCaRede} />
        <PrivateRoute path="/cadastros/produtos/codigos-anp" component={ManterCodigoANP} />
        <PrivateRoute path="/cadastros/produtos/delivery" component={ManterDelivery} />
        <PrivateRoute path="/cadastros/produtos/observacoes" component={ManterObservacoesPreparo} />
        <PrivateRoute path="/produtos/cardapio-digital/cadastros/cardapi" component={Seller8_CardapioDigital} />
        <PrivateRoute path="/produtos/cadastros/cardapio-digital" component={Seller8_ConfiguracoesCardapio} />
        <PrivateRoute path="/cadastros/produtos/ingredientes/grupo" component={ManterGruposIngredientes} />
        <PrivateRoute path="/cadastros/produtos/ingredientes/ingrediente" component={ManterIngredientes} />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/promocoes-br`} component={ConsultaResgatesPremmia} />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/vendas/vendas-br`}
          component={VendasRealizadasConsulta_VendasBR}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/auditoria/alteracao-de-ingredientes`}
          component={ConsultaAlteracaoIngredientes}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/auditoria/custos-de-ingredientes`}
          component={ConsultaCustosProdutosBR}
        />
        <PrivateRoute
          path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/comandas/comandas-digitais`}
          component={Seller8_ComandasDigitais}
        />
        <PrivateRoute path="/comandas/consultas/" component={Seller8_PedidosKDS} />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/br/anexos-e-lair`} component={AnexosLairConsulta} />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/br/margem-br`} component={MargemBRConsulta} />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/promocoes/br-mania`} component={ConsultaPromocoesBRMania} />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/promocoes/premmia`} component={ConsultaPromocoesPremmia} />
        <PrivateRoute path="/operacoes/fiscal/inutilizacao-de-sequencia-de-nf-e" component={ManterInutilizacaoNFe} />
        <PrivateRoute path="/operacoes/fiscal/lmc" component={ManterLMCeMedicao} lmc />
        <PrivateRoute path="/operacoes/estoque/medicao-de-tanques" component={ManterMedicao} />
        <PrivateRoute path={`${TIPO_SITE === 'RetaguardaEmporioPro' ? '/relatorios' : '/consultas'}/raio-x`} component={RaioX} />
      </Switch>
    </Suspense>
  </Router>
);

export default Routes;
