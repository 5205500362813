import moment from 'moment';
import { passarStatusSelecionadosParaTexto } from './PassarStatusSelecionadosParaTexto';



export function fabricarFiltroString(values) {
  let filter = '';
  if ((values || {}).numeroOs) {
    filter += `${filter.length ? ', ' : ''}Nº Reserva: ${values.numeroOs.toString().replace(/\D/g, '')}`;
  }


  if ((values || {}).operador) {
    filter += `${filter.length ? ', ' : ''}Operador: ${values.operador}`;
  }
  if ((values || {}).status && (values || {}).status.length) {
    filter += `${filter.length ? ', ' : ''}Status: ${passarStatusSelecionadosParaTexto(values.status)}`;
  }
  
  if ((values || {}).dataPedidoDe && !(values || {}).dataPedidoAte) {
    filter += `${filter.length ? ', ' : ''}Data da Reserva entre ${moment((values || {}).dataPedidoDe).format('DD-MM-YYYY')} até ${moment(
      (values || {}).dataPedidoDe,
    ).format('DD-MM-YYYY')}`;
  }
  if ((values || {}).dataPedidoDe && (values || {}).dataPedidoAte) {
    filter += `${filter.length ? ', ' : ''}Data da Reserva entre ${moment((values || {}).dataPedidoDe).format('DD-MM-YYYY')} até ${moment(
      (values || {}).dataPedidoAte,
    ).format('DD-MM-YYYY')}`;
  }

  if ((values || {}).dataEntregaRetiradaDe && !(values || {}).dataEntregaRetiradaAte) {
    filter += `${filter.length ? ', ' : ''}Data da Entrega/Retirada ${moment((values || {}).dataEntregaRetiradaDe).format('DD-MM-YYYY')} até ${moment(
      (values || {}).dataEntregaRetiradaDe,
    ).format('DD-MM-YYYY')}`;
  }
  if ((values || {}).dataEntregaRetiradaDe && (values || {}).dataEntregaRetiradaAte) {
    filter += `${filter.length ? ', ' : ''}Data da Entrega/Retirada ${moment((values || {}).dataEntregaRetiradaDe).format('DD-MM-YYYY')} até ${moment(
      (values || {}).dataEntregaRetiradaAte,
    ).format('DD-MM-YYYY')}`;
  }

  

  if (!filter.length) {
    const ninetyDaysAgo = moment().subtract(90, 'days').format('DD-MM-YYYY');
    const today = moment().format('DD-MM-YYYY');
    filter += `Data da Reserva entre ${ninetyDaysAgo} e ${today}`;
  }
  return `Filtrado por ${filter}`;
}
