import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import moment from 'moment';
import saveAs from 'file-saver';
import { formatStringToCurrency, getDadosLogin, maskedCnpj } from '../../../../shared/utils/Utils';

export class ExportarListaOsParaExcelService {
  async execute(e, title, filters, fileName, configure = null) {
    const dadosLogin = getDadosLogin();
    const empresa = dadosLogin.empresas.find(x => x.cnpj === dadosLogin.cnpj);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(title);

    if (configure) configure(worksheet);

    return exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: {
        row: 4,
        column: 1,
      },
    })
      .then(async cellRange => {
        // Procurar a coluna "Bairro" para encontrar o índice
        let bairroColIndex;
        const headerDataRow = worksheet.getRow(4); // A linha 4 contém os cabeçalhos das colunas
        headerDataRow.eachCell((cell, colNumber) => {
          if (cell.value === 'Bairro') {
            // Encontrar a célula com o cabeçalho "Bairro"
            bairroColIndex = colNumber;
          }
        });

        if (bairroColIndex) {
          const enderecoEntregaMunColIndex = bairroColIndex + 1;
          const enderecoEntregaUfColIndex = enderecoEntregaMunColIndex + 1;
          const enderecoEntregaCepColIndex = enderecoEntregaUfColIndex + 1;
          const enderecoRecolhaColIndex = enderecoEntregaCepColIndex + 1;
          const enderecoRecolhaBaiColIndex = enderecoRecolhaColIndex + 1;
          const enderecoRecolhaMunColIndex = enderecoRecolhaBaiColIndex + 1;
          const enderecoRecolhaUfColIndex = enderecoRecolhaMunColIndex + 1;
          const enderecoRecolhaCepColIndex = enderecoRecolhaUfColIndex + 1;
          const dataPedidoColIndex = enderecoRecolhaCepColIndex + 1;
          const dataEventoColIndex = dataPedidoColIndex + 1;
          const dataEntregaRetiradaColIndex = dataEventoColIndex + 1;
          const entregaRetiradaHoraDeColIndex = dataEntregaRetiradaColIndex + 1;
          const entregaRetiradaHoraAteColIndex = entregaRetiradaHoraDeColIndex + 1;
          const dataRecolhaDevolucaoColIndex = entregaRetiradaHoraAteColIndex + 1;
          const recolhaDevolucaoHoraDeColIndex = dataRecolhaDevolucaoColIndex + 1;
          const recolhaDevolucaoHoraAteColIndex = recolhaDevolucaoHoraDeColIndex + 1;
          const saldoColIndex = recolhaDevolucaoHoraAteColIndex + 1;
          const totalColIndex = saldoColIndex + 1;

          // Inserir a nova coluna manualmente
          worksheet.getRow(4).getCell(enderecoEntregaMunColIndex).value = 'Município';
          worksheet.getRow(4).getCell(enderecoEntregaUfColIndex).value = 'UF';
          worksheet.getRow(4).getCell(enderecoEntregaCepColIndex).value = 'CEP de Entrega';
          worksheet.getRow(4).getCell(enderecoRecolhaColIndex).value = 'Endereço de Recolha';
          worksheet.getRow(4).getCell(enderecoRecolhaBaiColIndex).value = 'Bairro de Recolha';
          worksheet.getRow(4).getCell(enderecoRecolhaMunColIndex).value = 'Município de Recolha';
          worksheet.getRow(4).getCell(enderecoRecolhaUfColIndex).value = 'UF de Recolha';
          worksheet.getRow(4).getCell(enderecoRecolhaCepColIndex).value = 'Cep de Recolha';
          worksheet.getRow(4).getCell(dataPedidoColIndex).value = 'Data do Pedido';
          worksheet.getRow(4).getCell(dataEventoColIndex).value = 'Data do Evento';
          worksheet.getRow(4).getCell(dataEntregaRetiradaColIndex).value = 'Ent./Ret.';
          worksheet.getRow(4).getCell(entregaRetiradaHoraDeColIndex).value = 'de';
          worksheet.getRow(4).getCell(entregaRetiradaHoraAteColIndex).value = 'até';
          worksheet.getRow(4).getCell(dataRecolhaDevolucaoColIndex).value = 'Rec./Dev.';
          worksheet.getRow(4).getCell(recolhaDevolucaoHoraDeColIndex).value = 'de';
          worksheet.getRow(4).getCell(recolhaDevolucaoHoraAteColIndex).value = 'até';
          worksheet.getRow(4).getCell(saldoColIndex).value = 'Saldo';
          worksheet.getRow(4).getCell(totalColIndex).value = 'Total Geral';

          // Ajustar a largura da coluna "Endereço de Entrega"
          worksheet.getColumn(enderecoEntregaMunColIndex).width = 15;
          worksheet.getColumn(enderecoEntregaUfColIndex).width = 8;
          worksheet.getColumn(enderecoEntregaCepColIndex).width = 10;
          worksheet.getColumn(enderecoRecolhaColIndex).width = 40;
          worksheet.getColumn(enderecoRecolhaBaiColIndex).width = 20;
          worksheet.getColumn(enderecoRecolhaMunColIndex).width = 15;
          worksheet.getColumn(enderecoRecolhaUfColIndex).width = 8;
          worksheet.getColumn(enderecoRecolhaCepColIndex).width = 10;
          worksheet.getColumn(dataPedidoColIndex).width = 15;
          worksheet.getColumn(dataEventoColIndex).width = 15;
          worksheet.getColumn(dataEntregaRetiradaColIndex).width = 15;
          worksheet.getColumn(entregaRetiradaHoraDeColIndex).width = 10;
          worksheet.getColumn(entregaRetiradaHoraAteColIndex).width = 10;
          worksheet.getColumn(dataRecolhaDevolucaoColIndex).width = 15;
          worksheet.getColumn(recolhaDevolucaoHoraDeColIndex).width = 10;
          worksheet.getColumn(recolhaDevolucaoHoraAteColIndex).width = 10;
          worksheet.getColumn(saldoColIndex).width = 15;
          worksheet.getColumn(totalColIndex).width = 15;

          // Copiar o estilo da coluna "Bairro" e aplicar à coluna "Endereço de Entrega"
          const bairroHeaderCellStyle = worksheet.getRow(4).getCell(bairroColIndex).style;

          // Aplicando o estilo à nova coluna "Endereço de Entrega"
          worksheet.getRow(4).getCell(enderecoEntregaMunColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(enderecoEntregaUfColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(enderecoEntregaCepColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(enderecoRecolhaColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(enderecoRecolhaBaiColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(enderecoRecolhaMunColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(enderecoRecolhaUfColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(enderecoRecolhaCepColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(dataPedidoColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(dataEventoColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(dataEntregaRetiradaColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(entregaRetiradaHoraDeColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(entregaRetiradaHoraAteColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(dataRecolhaDevolucaoColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(recolhaDevolucaoHoraDeColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(recolhaDevolucaoHoraAteColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(saldoColIndex).style = bairroHeaderCellStyle;
          worksheet.getRow(4).getCell(totalColIndex).style = bairroHeaderCellStyle;

          // const data = e.component.getDataSource().items(); // Obter os dados da fonte diretamente
          const dataSource = e.component.getDataSource();
          const data = await dataSource.store().load();
          data.forEach((item, index) => {
            const rowIndex = index + 5;
            const enderecoEntMunCell = worksheet.getRow(rowIndex).getCell(enderecoEntregaMunColIndex);
            const enderecoEntUfCell = worksheet.getRow(rowIndex).getCell(enderecoEntregaUfColIndex);
            const enderecoEntCepCell = worksheet.getRow(rowIndex).getCell(enderecoEntregaCepColIndex);
            const enderecoRecCell = worksheet.getRow(rowIndex).getCell(enderecoRecolhaColIndex);
            const enderecoBaiCell = worksheet.getRow(rowIndex).getCell(enderecoRecolhaBaiColIndex);
            const enderecoMunCell = worksheet.getRow(rowIndex).getCell(enderecoRecolhaMunColIndex);
            const enderecoUfCell = worksheet.getRow(rowIndex).getCell(enderecoRecolhaUfColIndex);
            const enderecoCepCell = worksheet.getRow(rowIndex).getCell(enderecoRecolhaCepColIndex);
            const dataPedidoCell = worksheet.getRow(rowIndex).getCell(dataPedidoColIndex);
            const dataEventoCell = worksheet.getRow(rowIndex).getCell(dataEventoColIndex);
            const dataEntregaRetiradaCell = worksheet.getRow(rowIndex).getCell(dataEntregaRetiradaColIndex);
            const entregaRetiradaHoraDeCell = worksheet.getRow(rowIndex).getCell(entregaRetiradaHoraDeColIndex);
            const entregaRetiradaHoraAteCell = worksheet.getRow(rowIndex).getCell(entregaRetiradaHoraAteColIndex);
            const dataRecolhaDevolucaoCell = worksheet.getRow(rowIndex).getCell(dataRecolhaDevolucaoColIndex);
            const recolhaDevolucaoHoraDeCell = worksheet.getRow(rowIndex).getCell(recolhaDevolucaoHoraDeColIndex);
            const recolhaDevolucaoHoraAteCell = worksheet.getRow(rowIndex).getCell(recolhaDevolucaoHoraAteColIndex);
            const saldoCell = worksheet.getRow(rowIndex).getCell(saldoColIndex);
            const totalCell = worksheet.getRow(rowIndex).getCell(totalColIndex);

            // Preencher a célula com o valor de "Endereço de Entrega"
            enderecoEntMunCell.value = item.municipio || '';
            enderecoEntUfCell.value = item.uf || '';
            enderecoEntCepCell.value = item.cep || '';
            enderecoRecCell.value = item.enderecoRecolha || item.logradouro;
            enderecoBaiCell.value = item.enderecoRecolhaBairro || item.bairro;
            enderecoMunCell.value = item.enderecoRecolhaMunicipio || item.municipio;
            enderecoUfCell.value = item.enderecoRecolhaUf || item.uf;
            enderecoCepCell.value = item.enderecoRecolhaCep || item.cep;
            dataPedidoCell.value = moment(new Date(item.dataPedido || null)).format('DD/MM/YYYY HH:mm');
            dataEventoCell.value = moment(new Date(item.dataEvento || null)).format('DD/MM/YYYY HH:mm');
            dataEntregaRetiradaCell.value = moment(new Date(item.dataEntregaRetirada || null)).format('DD/MM/YYYY');
            entregaRetiradaHoraDeCell.value = item.entregaRetiradaHoraDe || '00:00';
            entregaRetiradaHoraAteCell.value = item.entregaRetiradaHoraAte || '00:00';
            dataRecolhaDevolucaoCell.value = moment(new Date(item.dataRecolhaDevolucao || null)).format('DD/MM/YYYY');
            recolhaDevolucaoHoraDeCell.value = item.recolhaDevolucaoHoraDe || '00:00';
            recolhaDevolucaoHoraAteCell.value = item.recolhaDevolucaoHoraAte || '00:00';
            saldoCell.value = formatStringToCurrency(item.saldo) || '0,00';
            totalCell.value = formatStringToCurrency(item.totalGeral) || '0,00';

            // Copiar o estilo da célula "Bairro" para a célula "Endereço de Entrega"
            const bairroCellStyle = worksheet.getRow(rowIndex).getCell(bairroColIndex).style;
            enderecoEntMunCell.style = bairroCellStyle;
            enderecoEntUfCell.style = bairroCellStyle;
            enderecoRecCell.style = bairroCellStyle;
            enderecoBaiCell.style = bairroCellStyle;
            enderecoMunCell.style = bairroCellStyle;
            enderecoUfCell.style = bairroCellStyle;
            enderecoCepCell.style = bairroCellStyle;
          });
        }

        worksheet.mergeCells(1, 1, 1, worksheet.columnCount);
        worksheet.mergeCells(2, 1, 2, worksheet.columnCount);
        worksheet.mergeCells(3, 1, 3, worksheet.columnCount);

        //Header
        const headerRow = worksheet.getRow(1, 2);
        headerRow.getCell(2).value = title;
        headerRow.height = 25;
        headerRow.getCell(2).font = {
          size: 18,
        };
        headerRow.getCell(2).alignment = {
          horizontal: 'center',
        };

        //Subtitle
        const headerSubRow = worksheet.getRow(2, 2);
        headerSubRow.height = 25;
        headerSubRow.getCell(2).value = `CNPJ: ${maskedCnpj(empresa.cnpj)} - ${empresa.nomeFantasia}`;
        headerSubRow.getCell(2).font = {
          size: 14,
        };
        headerSubRow.getCell(2).alignment = {
          horizontal: 'center',
        };

        //Filters
        const filtersRow = worksheet.getRow(3, 2);
        filtersRow.height = 25;
        filtersRow.getCell(2).value = filters;
        filtersRow.getCell(2).font = {
          size: 14,
        };
        filtersRow.getCell(2).alignment = {
          horizontal: 'center',
        };

        // footer Time
        const footerRow = cellRange.to.row + 2;

        const footerTimeRow = worksheet.getRow(footerRow, 1);
        worksheet.mergeCells(footerRow, 1, footerRow, 3); // Mescla da coluna 1 até a coluna 3 na linha `footerRow`
        footerTimeRow.getCell(1).value = moment(new Date()).format('DD/MM/YYYY HH:mm');
        footerTimeRow.getCell(1).font = {
          italic: true,
        };

        // footer URL
        const footerUrlRow = worksheet.getRow(footerRow, 1);
        worksheet.mergeCells(footerRow, 4, footerRow, worksheet.columnCount - 1);
        footerUrlRow.getCell(4).value = dadosLogin.tipoSiteUrl;
        footerUrlRow.getCell(4).font = {
          italic: true,
        };
        footerUrlRow.getCell(4).alignment = {
          horizontal: 'center',
        };

        // footer User
        const footerUserRow = worksheet.getRow(footerRow, 1);
        footerUserRow.getCell(worksheet.columnCount).value = dadosLogin.nome;
        footerUserRow.getCell(worksheet.columnCount).font = {
          italic: true,
        };

        worksheet.autoFilter = {
          from: {
            row: 4,
            column: 1,
          },
          to: {
            row: cellRange.to.row,
            column: worksheet.columnCount,
          },
        };
      })
      .then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
        });
      });
  }
}
