import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

export class ApiEnviarNpsService {
  execute = async comodatoId => {
    try {
      const urlLinxCore = new UrlLinxCoreService().urlBase();
      const url = `/VTex/enviar-nps/${comodatoId}`;
      const response = await urlLinxCore.get(url);
      const data = response.data.itens || [];
      return data;
    } catch (er) {
      await new AxiosErrorService().execute(er);
    }
  };
}
