import 'devextreme/dist/css/dx.light.css';
import 'jspdf-autotable';
import './pedido.scss';

import { loadMessages, locale } from 'devextreme/localization';
import { Badge, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import ptMessages from 'devextreme/localization/messages/pt.json';
import { alert } from 'devextreme/ui/dialog';
import moment from 'moment';
import React, { useCallback, useRef, useEffect } from 'react';
import { InputCurrency } from '../../../../components/inputCurrency/inputCurrency';
import { useNovaOs } from '../../../../redux/slices/consignadoComodato/novaOsSlice';
import { maskedCnpjCpf } from '../../../../shared/utils/Utils';
import { DropBoxStatus } from '../components/DropBoxStatus/DropBoxStatus';
import { DropBoxVendedor } from '../components/drop-box-vendedor/drop-box-vendedor';
import { BuscarCliente } from '../components/busca-cliente/busca-cliente';
import DataEvento from '../components/data-do-evento/data-evento';
import DataEntregaRetirada from '../components/data-entrega-retirada/data-entrega-retirada';
import DataRecolhaDevolucao from '../components/data-recolha-devolucao/data-recolha-devolucao';
import DropBoxEntrega from '../components/drop-box-endereco-entrega/drop-box-endereco-entrega';
import DropBoxOrigens from '../components/drop-box-origens/drop-box-origens';
import { ApiHistoricoDePedidosService } from '../usercases/apiHistoricoDePedidos.service';
import { BuscarTransportadora } from './../components/busca-transportadora/buscaTransportadora';
import { Modal2Clientes } from './modals/Modal2Clientes';
import styles from './pedido.module.scss';
import DropBoxRecolha from '../components/drop-box-endereco-recolha/drop-box-endereco-recolha';
import { normalizeToBoolean } from '../../../../shared/utils/Utils';
import { selectConsignadoComodatoSharedData } from '../../../../redux/slices/consignadoComodato/SharedData.slice';

export function Pedido({ save }) {
  loadMessages(ptMessages);
  locale(navigator.language);
  const { changeValue, changeBlur } = useNovaOs();
  const { values, touched, errors } = useSelector(state => state.novaOs);
  const txtObservacao = useRef(null);
  const dispatch = useDispatch();
  const [paramUtilizaComissionamentoPedido, setParamUtilizaComissionamentoPedido] = React.useState(null);
  const parametro634 = useSelector(selectConsignadoComodatoSharedData).parametros634;
  
  useEffect(() => {
    if (paramUtilizaComissionamentoPedido !== null) return;
    if (!parametro634) return;
     console.log('parametro634', parametro634.valor);
     console.log('parametro634', normalizeToBoolean(parametro634.valor));
    setParamUtilizaComissionamentoPedido(normalizeToBoolean(parametro634.valor));
  }, [paramUtilizaComissionamentoPedido, parametro634]);

  const onClienteSelected = useCallback(
    async clienteId => {
      try {
        const hist = await new ApiHistoricoDePedidosService().execute(clienteId);
        dispatch(changeValue({ fieldName: 'historicoDePedidos', value: hist }));
        if (new Date(hist.ultimoPedido).toDateString() === new Date().toDateString()) {
          await alert('O cliente já fez um pedido hoje.', 'Atenção!', ['ESTOU CIENTE']);
        }
      } catch (error) {}
    },
    [changeValue, dispatch],
  );
  const onClienteUpdated = useCallback(
    async clienteId => {
      try {
        if (values.id !== -1 && values.pedidos.find(x => x.ecfCode !== null || x.ecfCode !== undefined)) {
          save();
        }
      } catch (error) {}
    },
    [save, values.id, values.pedidos],
  );

  const calcTotalPedidos = total => {
    total = total || 0;
    if (values.id !== -1) {
      return total - 1;
    }
    return total;
  };

  return (
    <>
      <Row className={styles.mainRow}>
        <Col lg={6}>
          <BuscarCliente onUpdated={onClienteUpdated} onSelected={onClienteSelected} title={`Cliente`}></BuscarCliente>
          {values.cliente && (
            <>
              {values.historicoDePedidos && (
                <>
                  {calcTotalPedidos(values.historicoDePedidos.totalPedidos) > 0 ? (
                    <>
                      <div style={{ position: 'absolute', top: '2px', right: '15px' }}>
                        <Badge className="p-2" style={{ color: 'white', fontSize: '9pt' }} bg="success">
                          Já comprou {values.historicoDePedidos.totalPedidos} vezes nesse loja
                        </Badge>
                        <Badge className="p-2" style={{ color: 'white', fontSize: '9pt' }} bg="secondary">
                          Último pedido {moment(values.historicoDePedidos.ultimoPedido).fromNow()}
                        </Badge>
                      </div>
                    </>
                  ) : (
                    <Badge style={{ position: 'absolute', top: '0px', right: '15px', color: 'white' }} bg="danger">
                      Cliente Novo
                    </Badge>
                  )}
                </>
              )}
              <span style={{ position: 'absolute', top: '75px', left: '25px', fontSize: '9pt' }}>
                {values.cliente.cnpjCpf.length <= 12 ? 'CPF' : 'CNPJ'}: {maskedCnpjCpf(values.cliente.cnpjCpf)}
                {' - '}
                {values.cliente.cnpjCpf.length > 11 ? `IE: ${values.cliente.inscricaoEstadual || 'ISENTO'} ` : ''}
                {'FONE: '}
                {values.cliente.contato.ddd || '00'} {values.cliente.contato.telefone || 'SEM TELEFONE'}
              </span>
            </>
          )}
          <DropBoxEntrega title="Endereço de Entrega" />
          <DropBoxRecolha title="Endereço de Recolha" required={false}/>
          <Row>
            {paramUtilizaComissionamentoPedido && (
              <Col lg={12}>
                <DropBoxVendedor title={'Vendedor'} />
              </Col>
            )}
          </Row>
          <Row className="group-box">
            <Col lg={12}>
              <Form.Label className="text-primary">Observações do cliente</Form.Label>
              <Form.Control
                style={{ height: '70px' }}
                as="textarea"
                maxLength={400}
                value={((values || {}).cliente || {}).observacao}
                disabled={true}
              />
            </Col>
          </Row>
          <DataEntregaRetirada />
          <DataEvento />
          <DataRecolhaDevolucao />
        </Col>
        <Col className={`col-lg-6 ${styles.leftVBar}`}>
          <Row className="form-group">
            <Col lg={4}>
              <DropBoxOrigens isVTex={(values.pedidos || []).find(x => x.vtexOrderId ? true : false)} title={'Origem do Pedido'} />
            </Col>
            <Col lg={4}>
              <DropBoxStatus title={'Status'} />
            </Col>
            <Col lg={4}>
              <Form.Label className="text-primary">Cadastrado Por</Form.Label>
              <Form.Control height={40} readOnly={true} value={(values || {}).representante || ''}></Form.Control>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Form.Label className="text-primary" htmlFor="tipoFreteId">
                Tipo Frete<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                disabled={(values.status || {}).id !== 1}
                className="form-control"
                name="tipoFreteId"
                id="tipoFreteId"
                type="text"
                onChange={e => {
                  const value = parseInt(e.target.value, 10);

                  dispatch(
                    changeValue({
                      fieldName: 'tipoFreteId',
                      value,
                    }),
                  );
                  if (value !== 2 && value !== 3) {
                    dispatch(
                      changeValue({
                        fieldName: 'valorFrete',
                        value: 0,
                      }),
                    );
                    dispatch(
                      changeValue({
                        fieldName: 'transportadora',
                        value: null,
                      }),
                    );
                  }
                }}
                onBlur={e => dispatch(changeBlur({ fieldName: 'tipoFreteId' }))}
                value={(values.tipoFreteId || -1).toString()}
              >
                <option value="-1">SELECIONE</option>
                <option value="2">ENTREGA TERCEIROS</option>
                <option value="3">ENTREGA DA LOJA</option>
                <option value="4">CLIENTE RETIRA</option>
                <option value="9">SEM TRANSPORTE</option>
              </Form.Select>
              {(touched || {}).tipoFreteId && (errors || {}).tipoFreteId && <div className="text-danger validationError">{(errors || {}).tipoFreteId || ''}</div>}
            </Col>
            <Col lg={6} className="">
              <Form.Label className="text-primary">
                Valor do Frete<span className="text-danger">*</span>
              </Form.Label>
              <InputCurrency
                onChange={(e, money) => {
                  dispatch(changeValue({ fieldName: 'valorFrete', value: parseFloat(money || '0') }));
                }}
                value={(values || {}).valorFrete || 0}
                className="form-control text-right"
                name="valorFrete"
                disabled={(values.tipoFreteId !== 2 && values.tipoFreteId !== 3) || (values.status || {}).id !== 1}
                placeholder="R$ 0,00"
              />
            </Col>
          </Row>
          <BuscarTransportadora />
          <Row className="group-box">
            <Col lg={12}>
              <Form.Label className="text-primary">Informações Adicionais [{`${((values || {}).informacaoAdicional || '').length || 0}/400`}]</Form.Label>
              <Form.Control
                ref={txtObservacao}
                style={{ height: '140px', fontSize: '9pt' }}
                as="textarea"
                placeholder="Informe as observações aqui"
                maxLength={400}
                value={(values || {}).informacaoAdicional}
                onChange={e => {
                  dispatch(
                    changeValue({
                      fieldName: 'informacaoAdicional',
                      value: e.target.value,
                    }),
                  );
                }}
              />
            </Col>
          </Row>
          <Row className="group-box">
            <Col lg={12}>
              <Form.Label className="text-primary">Observações para Notas Fiscais [{`${((values || {}).observacoes || '').length || 0}/400`}]</Form.Label>
              <Form.Control
                ref={txtObservacao}
                style={{ height: '140px' }}
                as="textarea"
                placeholder="Informe as observações para notas fiscais aqui"
                maxLength={400}
                value={(values || {}).observacoes}
                onChange={e => {
                  dispatch(
                    changeValue({
                      fieldName: 'observacoes',
                      value: e.target.value,
                    }),
                  );
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal2Clientes clienteAtual={values.cliente} clienteAlternativo={values.clienteAlternativo}></Modal2Clientes>
    </>
  );
}
