import React, { useCallback, useState, useEffect } from 'react';
import * as _ from 'lodash';

import { useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button, Col, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { NumberBox, LoadPanel } from 'devextreme-react';
import Popup from 'devextreme-react/popup';
import { alert } from 'devextreme/ui/dialog';
import { formatStringToCurrency } from '../../../../shared/utils/Utils';
import { ApiRetornarPedidoConsignado } from '../usercases/apiRetornarPedidoConsignado.service';
import { useNovaOs } from '../../../../redux/slices/consignadoComodato/novaOsSlice';
import DropBoxPagamentos from '../components/drop-box-pagamentos/dropBoxPagamentos';

import styles from '../pagamento/pagamento.module.scss';

export function RetornoConsignado({ pedido }) {

  const [popupVisible, setPopupVisible] = useState(false);
  const [messageLoadin, setMessageLoadin] = useState('');
  const [searching, setSearching] = useState(false);
  const [newPayment, setNewPayment] = useState({});
  const [payments, setPayments] = useState([]);
  const [total, setTotal] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [restante, setRestante] = useState(0);
  const [showFormPayments, setShowFormPayments] = useState(false);
  const dispatch = useDispatch();
  const { changeValue } = useNovaOs();
  const [itens, setItens] = useState(
    pedido.itens.map(item => ({
      id: item.id,
      produto: item.produto,
      codigoProduto: `${item.produto.codigo} - ${item.produto.descricao}`,
      valorTotal: item.valorProduto * item.quantidade,
      valorTotalQuantidadeVendida: Math.round(item.valorProduto * item.quantidade * 100) / 100,
      quantidadeConsignada: item.quantidade,
      quantidadeVendida: 0,
      quantidadeRetornada: 0,
      totalRetorno: item.totalProdutos + item.totalAtivos,
      valorProduto: item.valorProduto,
    })),
  );

  useEffect(() => {
    const data = [...itens];
    const totalDosItens = _.sumBy(data, x => Math.round(x.quantidadeVendida * x.valorProduto * 100) / 100);
    const totalDosPagamentos = _.sumBy(payments, x => x.valorPagamento);

    if (payments.length > 0) {
      setTotal(totalDosItens);
      setRestante(Math.round((totalDosItens - totalDosPagamentos) * 100) / 100);
      setDisabled(true);
    } else {
      setDisabled(false);
      setTotal(totalDosItens);
      setRestante(Math.round((totalDosItens - totalDosPagamentos) * 100) / 100);
    }
  }, [pedido, itens, payments]);

  useEffect(() => {
    setNewPayment({});
  }, []);

  const [focusedElement, setFocusedElement] = useState(pedido.itens.map(item => ({ quantidade: item.quantidade })));

  const showFormPayment = useCallback(
    e => {
      if (showFormPayments) return;
      setShowFormPayments(true);
    },
    [showFormPayments],
  );
  const hideFormPayment = useCallback(
    e => {
      if (!showFormPayments) return;
      setShowFormPayments(false);
    },
    [showFormPayments],
  );

  const show = useCallback(
    e => {
      if (popupVisible) return;
      setPopupVisible(true);
    },
    [popupVisible],
  );
  const hide = useCallback(
    e => {
      if (!popupVisible) return;
      setPopupVisible(false);
    },
    [popupVisible],
  );

  const setTotalPayments = useCallback(
    e => {
      const data = [...itens];
      const totalDosItens = _.sumBy(data, x => Math.round(x.quantidadeVendida * x.valorProduto * 100) / 100);
      setTotal(totalDosItens);
      setNewPayment({ ...newPayment, valorPagamento: totalDosItens });
    },
    [setNewPayment, newPayment, itens],
  );

  const onFocusIn = useCallback(e => {
    setFocusedElement(e);
  }, []);

  const onInputChange = useCallback(
    e => {
      const idx = focusedElement.element.id.split('_')[1];
      const value = parseFloat(e || '0', 10);
      const data = [...itens];

      if (value > data[idx]['quantidadeConsignada']) return alert('Valor informado excede o valor do pedido original', 'Ops!');

      if (value < 0) return alert('Valor informado não pode ser negativo');

      data[idx]['quantidadeVendida'] = value;
      data[idx]['valorTotalQuantidadeVendida'] = Math.round(data[idx]['quantidadeVendida'] * pedido.itens[idx].valorProduto * 100) / 100;
      data[idx]['quantidadeRetornada'] = data[idx]['quantidadeConsignada'] - data[idx]['quantidadeVendida'];
      data[idx]['totalPendente'] = data[idx]['quantidadeRetorno'] * pedido.itens[idx].valorProduto;

      const somaQuantidadeVendida = _.sumBy(data, x => x.quantidadeVendida);

      //if (somaQuantidadeVendida === 0 && value === 0) {
      //  hideFormPayment();
      //} else {
      //  setTotalPayments(e);
      //  showFormPayment();
      //}
      hideFormPayment();

      setItens(data);
    },
    [itens, focusedElement, pedido, showFormPayment, hideFormPayment, setTotalPayments],
  );

  const retornarPedido = useCallback(
    async e => {
      try {
        setSearching(true);
        const dataItens = [...itens];
        const quantidadeTotalVendida = _.sumBy(dataItens, x => x.quantidadeVendida);
        //const totalDosPagamentos = _.sumBy(payments, x => x.valorPagamento);

        //if (quantidadeTotalVendida > 0 && !payments.length) {
        //  setSearching(false);
        //  return alert('Não é possível retornar o pedido sem as informações de pagamento', 'Ops!');
        //}
        //
        //if (totalDosPagamentos < total) {
        //  setSearching(false);
        //  return alert('O valor total dos pagamentos não pode ser menor que o valor total do pedido', 'Ops!');
        //}

        const envioNps =  sessionStorage.getItem('envioNps') == 'true' ? true : false;

        setMessageLoadin('Realizando o retorno do pedido...');
        var data = {
          itens: itens.map((it, idx) => ({
            id: it.id,
            pedidoId: pedido.pedidoId,
            numeroItem: idx + 1,
            quantidade: it.quantidadeVendida,
            quantidadeRetornada: it.quantidadeRetornada,
            valorAtivo: it.valorAtivo,
            valorProduto: it.valorProduto,
            totalAtivos: Math.round(it.produto.precoVenda * it.quantidadeVendida * 100) / 100,
            totalProdutos: it.valorTotalQuantidadeVendida,
            acrescimo: 0,
            desconto: 0,
            produto: it.produto,
            cancelado: false,
            quantidadeCancelada: 0,
            quantidadeTotal: it.quantidadeVendida,
            dataCadastro: new Date().toISOString(),
            dataUltimaAtualizacao: new Date().toISOString(),
          })),
          tipoPedido: {
            id: 4,
          },
          totalProdutos: _.sumBy(itens, x => x.totalAtivos),
          pedidoId: pedido.pedidoId,
          pagamentos: payments,
          nps: envioNps,
        };

        const response = await new ApiRetornarPedidoConsignado().execute(data);
        if (response) {
          dispatch(changeValue({ fieldName: 'pedidos', value: response.pedidos }));
        }
        setSearching(false);
        if (quantidadeTotalVendida > 0)
          alert('Retorno de consignado realizado com venda em consignação!\n\n' + 
            ' Acesse a aba de pagamentos para informar os dados de pagamentos da venda em consignação e,' +
            ' posteriormente,' +
            ' acesse a aba de faturamento para gerar a NF - e!', 'Retorno de Consignado');
        else 
          alert('Retorno de consignado realizado com sucesso!', 'Retorno de Consignado');
        setPopupVisible(false);
      } catch (error) {
        alert(error.message, 'Falha no retorno do pedido', 'Ops!');
        setSearching(false);
      }
    },
    [itens, pedido, payments, changeValue, dispatch, total],
  );

  const onRemover = useCallback(
    e => {
      const itens = [...payments];
      itens.splice(e, 1);
      setPayments(itens);
    },
    [payments],
  );

  const onReceber = useCallback(
    e => {
      const total = Math.round(_.sumBy(itens, x => x.valorTotalQuantidadeVendida) * 100) / 100;
      console.log('total => ', total);
      const totalPago = Math.round(_.sumBy(payments, x => x.valorPagamento) * 100) / 100;
      console.log('totalPago => ', totalPago);
      const faltaPagar = Math.round((total - totalPago) * 100) / 100;
      console.log('faltaPagar => ', faltaPagar);
      console.log('valorPagamento => ', newPayment.valorPagamento);

      if (!newPayment.pagamento === undefined) return alert('Informe a forma de pagamento', 'Ops!');

      if (Math.round(newPayment.valorPagamento * 100) / 100 > faltaPagar) return alert('Informe um valor menor que o total da venda');

      if (Math.round(newPayment.valorPagamento * 100) / 100 === undefined) return alert('Informe um valor maior que zero');

      if (Math.round(newPayment.valorPagamento * 100) / 100 && Math.round(newPayment.valorPagamento * 100) / 100 <= 0)
        return alert('Informe um valor maior que zero');

      newPayment.vencimento = 'RECEBIDO';
      newPayment.fPagCod = newPayment.pagamento.id;

      setPayments([...payments, newPayment]);
      setNewPayment({});
    },
    [newPayment, payments, itens],
  );

  const formatQuantidade = produto => {
    if (!produto || !produto.estoque || !produto.estoque.unidadeMedidaVenda) {
      return '0';
    }
    // Verificar se a propriedade unidadeMedidaVenda está presente
    if (produto.estoque.unidadeMedidaVenda && produto.estoque.unidadeMedidaVenda.casasDecimais) {
      return `#,##0.${'0'.repeat(produto.estoque.unidadeMedidaVenda.casasDecimais)} ${(produto.estoque.unidadeMedidaVenda.sigla || 'UN').trim().toUpperCase()}`;
    }
    return `#,##0 ${(produto.estoque.unidadeMedidaVenda.sigla || 'UN').trim().toUpperCase()}`;
  };

  return (
    <>
      <button className="btn btn-primary ml-3" onClick={show}>
        Retornar Consignado
      </button>
      <Popup
        width="80vw"
        height={showFormPayments ? '95vh' : '70vh'}
        onHiding={hide}
        visible={popupVisible}
        title={`RETORNO ${pedido.tipoPedido.nome}`}
        showTitle={true}
        hideOnOutsideClick={false}
      >
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: '#page' }}
          visible={searching}
          showIndicator={true}
          shading={true}
          message={messageLoadin}
          showPane={true}
        />
        <PerfectScrollbar>
          <table className="table">
            <thead>
              <tr>
                <th>CODIGO/PRODUTO</th>
                <th>VALOR TOTAL</th>
                <th>QUANT. CONSIGNADA</th>
                <th>QUANT. VENDIDA</th>
                <th>VALOR TOTAL QUANT VENDIDA</th>
                <th>QUANT. RETORNADA</th>
              </tr>
            </thead>
            <tbody>
              {itens.map((item, idx) => (
                <tr key={idx}>
                  <td>{item.codigoProduto}</td>
                  <td>{formatStringToCurrency(item.valorTotal)}</td>
                  <td>{item.quantidadeConsignada}</td>
                  <td>
                    <NumberBox
                      className="curreny"
                      format={formatQuantidade(pedido.itens[idx].produto)}
                      height={70}
                      valueChangeEvent="keyup"
                      onValueChange={onInputChange}
                      onFocusIn={onFocusIn}
                      disabled={disabled}
                      defaultValue={0}
                      id={`quantidade_${idx}`}
                      value={item.quantidadeVendida}
                    ></NumberBox>
                  </td>
                  <td>{formatStringToCurrency(item.valorTotalQuantidadeVendida)}</td>
                  <td>{item.quantidadeRetornada}</td>
                </tr>
              ))}
              <td colSpan="12">
                <Row>
                  <Col lg={8} className="form-group"></Col>
                  <Col lg={2} className="form-group">
                    <NumberBox label="Total" className="curreny" format="R$ #,##0.00" readOnly={true} height={70} defaultValue={0} value={total}></NumberBox>
                  </Col>
                  <Col lg={2} className="form-group">
                    <NumberBox
                      label="Restante a Pagar"
                      className="curreny"
                      format="R$ #,##0.00"
                      height={70}
                      defaultValue={0}
                      value={restante}
                      readOnly={true}
                    ></NumberBox>
                  </Col>
                </Row>
              </td>
            </tbody>
            <tfoot>
              {showFormPayments && (
                <tr>
                  <td colSpan="6">
                    <Row>
                      <Col lg={6} className="form-group">
                        <DropBoxPagamentos
                          onSelected={e => {
                            setNewPayment({ ...newPayment, pagamento: e });
                          }}
                        />
                      </Col>
                      <Col lg={6} className="form-group mb-3">
                        <Form.Label className="text-primary" htmlFor="valorPagamento">
                          Valor do Pagamento<span className="text-danger">*</span>
                        </Form.Label>
                        <InputGroup>
                          <NumberBox
                            name="valorPagamento"
                            id="valorPagamento"
                            className="curreny"
                            format="R$ #,##0.00"
                            height={40}
                            width="100%"
                            valueChangeEvent="keyup"
                            onValueChange={e => {
                              setNewPayment({ ...newPayment, valorPagamento: Math.round(e * 100) / 100 });
                            }}
                            defaultValue={0}
                            value={newPayment.valorPagamento || 0}
                          ></NumberBox>
                          <Button variant="primary" className={`${styles.buttonReceber}`} onClick={onReceber}>
                            <i className="dx-icon dx-icon-add"></i>
                          </Button>
                        </InputGroup>
                      </Col>
                      <Col lg={12} className="form-group">
                        <Table hover className={`${styles.table} mb-0`}>
                          <thead>
                            <tr>
                              <th className="text-left">Forma de Pagamento</th>
                              <th className="text-center">Vencimento</th>
                              <th className="text-right">Valor</th>
                              <th className="text-center">Açoes</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(payments || []).map((payment, i) => (
                              <tr key={i}>
                                <td className="text-left">{(payment.pagamento || {}).descricao}</td>
                                <td className="text-center"> {payment.vencimento}</td>
                                <td className="text-right">{formatStringToCurrency(payment.valorPagamento)}</td>
                                <td className="text-right">
                                  <Button hint="Excluir Pagamento" icon="remove" type="primary" onClick={() => onRemover(i)} stylingMode="outlined">
                                    x
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </td>
                </tr>
              )}
              <tr colSpan="12">
                <td colSpan="12" className="text-right">
                  <Button onClick={retornarPedido}>Retornar</Button>
                </td>
              </tr>
            </tfoot>
          </table>
        </PerfectScrollbar>
      </Popup>
    </>
  );
}
