import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

export class DownloadReciboLocacaoComodato {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(uid, numeroPedido) {
    try {
      const response = await this.urlLinxCore.get(`/Relatorios/recibo-locacao/${uid}`, {
        responseType: 'blob', // importante para manipular binários
      });

      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');

      const link = document.createElement('a');
      link.href = fileURL;
      link.setAttribute('download', `ReciboLocacao${numeroPedido}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      await new AxiosErrorService().execute(error);
    }
  }
}
