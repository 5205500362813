import React from 'react';
import { Summary, TotalItem, GroupItem } from 'devextreme-react/data-grid';
import axios from 'axios';
import moment from 'moment';
import { getToken } from '../authentication/Auth';
import { BASE_URL_GATEWAY } from '../shared/constants/GatewayUrlConstant';

export const reportMangerService = {
  Add,
  Update,
  Delete,
  Get,
  RefreshColumns,
  FiltersToModel,
  CreatePeriod
};

const urlLinxUsuarios = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/usuarios/api`
});
//Nota: o calculateCustomSummary aparentemente não funciona corretamente devido a forma como o devextreme trabalha com a grid
//pois quando o Summary ou o TotalItem ou o GroupItem vem de uma 'função' os dados do objeto 'options' que o devextreme utiliza
//fica capenga, não tendo os dados necessários para que o método calculateCustom tenha referência do que deve ser feito
//tentei colocar junto as linhas para que a soma de rodapé funcione, porém a soma agrupada fica impossível
//por enquanto, caso precise utilizar o calculateCustomSummary, use que nem o vendas dinâmico (DetalhadoProduto.jsx e DetalhadoProduto.service.js)
//(note que os campos do custom devem ter totalize: false, e devem ser informados manualmente no summary do próprio jsx)
export const renderSummarySumDefault = (columns, calculateCustom, rows) => {
  const colunsToSum = columns
    .filter(column => column.totalize);

  const totI = renderTotalItemSumDefault(colunsToSum);
  const groI = renderGroupItemSumDefault(colunsToSum);

  return (
    //<Summary calculateCustomSummary={(options) => rows.forEach(row => calculateCustom(row, options))}>
    //<Summary calculateCustomSummary={(options) => calculateCustom(options)}>
    //<Summary calculateCustomSummary={calculateCustom}>
    <Summary>
      {totI}
      {groI}
    </Summary>
  );
};

export const renderTotalItemSumDefault = (columns) => {
  const colunsToSum = columns
    .filter(column => column.totalize);

  const totI = colunsToSum.map((column) => (
    <TotalItem
      key={`total_${column.dataField}`}
      column={column.dataField}
      summaryType={column.summaryType ? column.summaryType : "sum"}
      valueFormat={(column.format ? column.format : { precision: 2 })}
      name={column.nameItem ? column.nameItem : column.dataField}
    />
  ));

  return totI;
};

export const renderGroupItemSumDefault = (columns) => {
  const colunsToSum = columns
    .filter(column => column.totalize);

  const groI = colunsToSum.map((column) => (
    <GroupItem
      key={`group_${column.dataField}`}
      column={column.dataField}
      summaryType={column.summaryType ? column.summaryType : "sum"}
      valueFormat={(column.format ? column.format : { precision: 2 })}
      showInGroupFooter={false}
      alignByColumn={true}
      name={column.nameGroup ? column.nameGroup : column.dataField}
    />
  ));

  return groI;
};





urlLinxUsuarios.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const Tipo = {
  Periodo: 0,
  Data: 1
};

const Periodo = {
  DiaAtual: 0,
  UltimosXDias: 1,
  MesAtual: 2,
  MesAnterior: 3,
  SemanaAtual: 4,
  SemanaAnterior: 5,
};

const Data = {
  DiaAtual: 0,
  DiaAnterior: 1,
  PrimeiroDiaMes: 2,
  UltimoDiaMes: 3
};

async function Add(model) {
  debugger;
  const response = await urlLinxUsuarios
    .post('/relatorio/AddPorEmpresa', model)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function Update(model) {
  const response = await urlLinxUsuarios
    .put('/relatorio/UpdatePorEmpresa', model)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function Delete(id) {
  const response = await urlLinxUsuarios
    .delete(`/relatorio/DeletePorEmpresa?id=${id}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function Get(codigo) {
  const response = await urlLinxUsuarios
    .get(`/relatorio/ListPorFuncionalidade?codigo=${codigo}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

function RefreshColumns(old, updated) {
  let refreshedColuns = [...old];
  //let result = [...updated];
/*quando o relatório possui dois tipos de consulta com colunas diferentes,
 e é feito filtro nas colunas de uma das consultas, ao fazer o refrash na consulta do outro tipo de relatório
 como o dado não existe em refreshedColuns, ele é desconsiderado, e com isso o filtro aplicado fica incorreto (entre outros itens)
 como a lista que vem em updated já é completa, em pricipio não é necessário de fato um update na lista antiga.
se atualizar, para cada coluna velha, acha o seu par na nova, e atualiza a velha com o valor da nova
se não achou o que está na velha, mas não está na nova, é considerado vazio
porém se está na nova, e não está na velha, tem que adicionar, ou seja, é a nova! (mas tem algo estranho ainda)
*/
refreshedColuns.forEach(c => {
   var col = updated.find(x => {
     return x.dataField === c.dataField;
   });
   if (col) {
     c.groupIndex = col.groupIndex;
     c.filterValue = col.filterValue;
     c.sortIndex = col.sortIndex;
     c.sortOrder = col.sortOrder;
     c.visible = col.visible;
     c.visibleIndex = col.visibleIndex;
   } else {
     c.visible = false;
     c.groupIndex = undefined;
     c.filterValue = undefined;
   }
});
  return refreshedColuns;
//var combined = [...refreshedColuns, ...updated];
//var unique = combined.filter((c, index, self) =>
//  index === self.findIndex((t) => (
//    t.dataField === c.dataField
//  ))
//);
//  unique.forEach(c => {
//    var col = updated.find(x => x.dataField === c.dataField);
//    if (col) {
//      c.groupIndex = col.groupIndex;
//      c.filterValue = col.filterValue;
//      c.sortIndex = col.sortIndex;
//      c.sortOrder = col.sortOrder;
//      c.visible = col.visible;
//      c.visibleIndex = col.visibleIndex;
//    } else {
//      c.visible = false;
//      c.groupIndex = undefined;
//      c.filterValue = undefined;
//    }
//  });
//  return unique;
}

function FiltersToModel(filters) {
  let model = {};
  filters.forEach(f => {
    model = {
      ...model,
      [f.campo]: f.valor
    };
  });

  return model;
}

function CreatePeriod(periodo) {
  let today = moment();

  if (periodo.tipo === Tipo.Periodo) {
    switch (periodo.periodo) {
      case Periodo.UltimosXDias:
        return {
          dataInicio:
            today
              .clone()
              .add(-(periodo.dias - 1), 'days')
              .format('YYYY-MM-DD') + 'T00:00:00',
          dataFim: today.clone().format('YYYY-MM-DD') + 'T23:59:59'
        };
      case Periodo.MesAtual:
        return {
          dataInicio:
            today
              .clone()
              .startOf('month')
              .format('YYYY-MM-DD') + 'T00:00:00',
          dataFim:
            today
              .clone()
              .endOf('month')
              .format('YYYY-MM-DD') + 'T23:59:59'
        };
      case Periodo.MesAnterior:
        return {
          dataInicio:
            today
              .clone()
              .add(-1, 'month')
              .startOf('month')
              .format('YYYY-MM-DD') + 'T00:00:00',
          dataFim:
            today
              .clone()
              .add(-1, 'month')
              .endOf('month')
              .format('YYYY-MM-DD') + 'T23:59:59'
        };
      case Periodo.DiaAtual:
      default:
        return {
          dataInicio: today.clone().format('YYYY-MM-DD') + 'T00:00:00',
          dataFim: today.clone().format('YYYY-MM-DD') + 'T23:59:59'
        };
        case Periodo.SemanaAtual:
          return {
            dataInicio:
              today
                .clone()
                .startOf('week')
                .format('YYYY-MM-DD') + 'T00:00:00',
            dataFim:
              today
                .clone()
                .endOf('week')
                .format('YYYY-MM-DD') + 'T23:59:59'
          };
          case Periodo.SemanaAnterior:
            return {
              dataInicio:
                today
                  .clone()
                  .add(-1, 'week')
                  .startOf('week')
                  .format('YYYY-MM-DD') + 'T00:00:00',
              dataFim:
                today
                  .clone()
                  .add(-1, 'week')
                  .endOf('week')
                  .format('YYYY-MM-DD') + 'T23:59:59'
            };
    }
  } else {
    switch (periodo.periodo) {
      case Data.DiaAnterior:
        return {
          dataInicio:
            today
              .clone()
              .add(-1, 'days')
              .format('YYYY-MM-DD') + 'T00:00:00',
          dataFim:
            today
              .clone()
              .add(-1, 'days')
              .format('YYYY-MM-DD') + 'T23:59:59'
        };
      case Data.PrimeiroDiaMes:
        return {
          dataInicio:
            today
              .clone()
              .startOf('month')
              .format('YYYY-MM-DD') + 'T00:00:00',
          dataFim:
            today
              .clone()
              .startOf('month')
              .format('YYYY-MM-DD') + 'T23:59:59'
        };
      case Data.UltimoDiaMes:
        return {
          dataInicio:
            today
              .clone()
              .endOf('month')
              .format('YYYY-MM-DD') + 'T00:00:00',
          dataFim:
            today
              .clone()
              .endOf('month')
              .format('YYYY-MM-DD') + 'T23:59:59'
        };
      case Data.DiaAtual:
      default:
        return {
          dataInicio: today.clone().format('YYYY-MM-DD') + 'T00:00:00',
          dataFim: today.clone().format('YYYY-MM-DD') + 'T23:59:59'
        };
    }
  }
}
